import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import axios from 'axios'

import Box from '../../../components/box'
import Context from '../../../Context'
import SuccessImage from '../../../assets/img/success.png'

const useStyles = makeStyles((theme) => ({
  container: {
    borderTopStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    padding: theme.spacing(1.5, 2),
    backgroundColor: '#FFF',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderTopWidth: 4,
      padding: theme.spacing(3, 4),
    },
  },
  icon: {
    backgroundColor: '#76af3c',
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: '#FFF',
    margin: '0 auto',
  },
  button: {
    cursor: 'text',
  },
  buttonGroup: {
    marginRight: theme.spacing(1),
  },
  buttonCenter: {
    textAlign: 'center',
  },
  successImage: {
    backgroundImage: `url(${SuccessImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90%',
    backgroundPositionY: '20px',
    textAlign: 'center',
    height: '72vh',
    [theme.breakpoints.down('md')]: {
      height: '28vh',
      display: 'none',
    },
  },
}))

const Program = () => {
  const classes = useStyles()
  const {
    dashboardSession,
    setDashboardSession,
    setUserAuth,
    userAuth,
  } = useContext(Context)
  const history = useHistory()
  const [survey, setSurvey] = useState([])

  const getSurvey = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/find-survey`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      setSurvey(data.data)
    } catch (err) {
      console.log(err)
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      }
    }
  }, [setUserAuth, userAuth])

  useEffect(() => {
    getSurvey()
  }, [getSurvey])

  const handleSuccess = (event) => {
    event.preventDefault()
    setDashboardSession(null)
    history.push({ pathname: '/', state: { isSuccess: true } })
  }

  if (dashboardSession?.membership_success?.success === false) {
    return (
      <Container maxWidth="md">
        <Box
          border="2px #F3F1F18C solid"
          borderTop="4px #2306DE solid"
          borderRadius={10}
          borderColor="primary"
          mt={4}
          px={6}
          py={4}>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justify="center"
            spacing={2}>
            {/*<Grid item xs={12}>
              <Box className={classes.icon}>
                <Icon fontSize="large" color="inherit">
                  check
                </Icon>
              </Box>
            </Grid>*/}
            <Grid item xs={12}>
              <Box my={4}>
                <Typography variant="body1" align="center">
                  Por el momento no puedes afiliarte a la Vacuna COVID-19, por
                  favor vuelve a intentarlo más tarde.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonCenter}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSuccess}>
                  Ir al inicio
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }

  if (!survey?.phase) {
    return (
      <Box my={4} style={{ textAlign: 'center' }}>
        <CircularProgress size={24} color="primary" />
      </Box>
    )
  }

  return (
    <Container maxWidth="md">
      <Box className={classes.container} mt={2}>
        <Box mb={0}>
          <Grid container>
            <Grid lg={6}>
              <Box px={4} py={4}>
                <Grid
                  container
                  alignItems="center"
                  alignContent="center"
                  justify="center"
                  spacing={2}>
                  <Grid item xs={4}>
                    <Box className={classes.icon}>
                      <Icon fontSize="large" color="inherit">
                        check
                      </Icon>
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h5" component="h3" align="center">
                      ¡Registro Exitoso!
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="justify" variant="body1" gutterBottom>
                      <b>Bien hecho {userAuth?.name}</b>, tu registro para
                      recibir la vacuna COVID-19 ha sido exitoso.
                    </Typography>
                    {/*<Box py={2} mx={2}>
                      <Grid container alignItems="center">
                        <Box width={150}>
                          <Typography variant="body1">Fase asignada</Typography>
                        </Box>
                        <ButtonGroup
                          className={classes.buttonGroup}
                          disableElevation
                          disableRipple
                          disableFocusRipple>
                          <Button
                            className={classes.button}
                            variant={
                              survey.phase === 'fase1' ? 'contained' : null
                            }
                            color={survey.phase === 'fase1' ? 'primary' : null}>
                            1
                          </Button>
                          <Button
                            className={classes.button}
                            variant={
                              survey.phase === 'fase2' ? 'contained' : null
                            }
                            color={survey.phase === 'fase2' ? 'primary' : null}>
                            2
                          </Button>
                          <Button
                            className={classes.button}
                            variant={
                              survey.phase === 'fase3' ? 'contained' : null
                            }
                            color={survey.phase === 'fase3' ? 'primary' : null}>
                            3
                          </Button>
                        </ButtonGroup>
                      </Grid>
                      <Box mt={2}>
                        <Grid container alignItems="center">
                          <Box width={150}>
                            <Typography variant="body1">Grupo:</Typography>
                          </Box>
                          <Typography variant="body1">
                            {survey.group}
                          </Typography>
                        </Grid>
                      </Box>
                    </Box>*/}
                    <Typography align="justify" variant="body1" gutterBottom>
                      Te enviaremos un mensaje de texto (SMS) confirmando tu cita de vacunación.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.buttonCenter}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSuccess}>
                        Ir al inicio
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box color="white" className={classes.successImage}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default Program
