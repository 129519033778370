import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useRouteMatch, Route, Switch } from 'react-router-dom'

import AppointmentPage from './appointment/Appointment'
import Box from '../../components/box'
import SchedulePage from './appointment/Schedule'

const Appointments = () => {
  const { path } = useRouteMatch()

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" component="h2" className="title-head" gutterBottom>
          Citas de Vacunación COVID-19
        </Typography>
        <Typography variant="body1" component="h2" className="title-head">
          Aquí encontrarás toda la información que necesitas para tus próximas vacunas.
        </Typography>
        <Switch>
          <Route path={`${path}/:date`}>
            <AppointmentPage />
          </Route>
          <Route exact path={path}>
            <SchedulePage />
          </Route>
        </Switch>
      </Box>
    </Box>
  )
}

export default Appointments
