import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import axios from 'axios'
import dayjs from 'dayjs'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Box from '../../../components/box'
import { useFormErrors, TextField } from '../../../components/input'
import * as Schema from '../../../utils/validation'
import Context from '../../../Context'
import EffectsImage from '../../../assets/img/effects.png'

const useStyles = makeStyles((theme) => ({
  container: {
    borderTopStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    padding: theme.spacing(1.5, 2),
    backgroundColor: '#FFF',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderTopWidth: 4,
      padding: theme.spacing(3, 4),
    },
    '& .MuiFormControl-root': {
      display: 'block',
      width: '100%!important',
    },
  },
  button: {
    margin: theme.spacing(0, 2, 1),
  },
  selectVaccine: {
    '& > div': {
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(0.5),
      color: '#FFF',
      '& > div:first-child': {
        padding: theme.spacing(1),
        paddingRight: theme.spacing(6),
      },
      '& > svg': {
        color: '#FFF',
      },
    },
  },
  optionVaccine: {
    display: 'block',
  },
  effectsImage: {
    backgroundImage: `url(${EffectsImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90%',
    backgroundPositionY: '50px',
    backgroundPositionX: '35px',
    textAlign: 'center',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      height: '28vh',
      display: 'none',
    },
  },
}))

const FormSchema = Schema.object().shape({
  vaccineCode: Schema.string().required(),
  symptom: Schema.string().required(),
  symptomText: Schema.string().required(),
  comment: Schema.string().required(),
})

const FormPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { dashboardConfig, userAuth, setUserAuth, setActiveMenu } = useContext(
    Context,
  )

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: FormSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [vaccines, setVaccines] = useState()
  const [valueComment, setValueComment] = useState('')
  const [valueText, setValueText] = useState('')

  const handleFormSubmit = async (data) => {
    try {
      if (data.symptom === '') {
        data.symptom = 'other'
      }
      setIsSubmitted(true)
      await FormSchema.validate(data)
      if (data.symptom === 'other') {
        data.symptom = ''
      }
      console.log(data)
      //return true
      const headers = { Authorization: `Bearer ${userAuth.token}` }
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/register-adverse-reaction`,
        data,
        {
          headers,
        },
      )
      if (!response.success) {
        throw new Error(
          'No se pudo registrar la reacción adversa. Inténtalo más tarde',
        )
      }
      history.push({ pathname: '/reacciones-adversas' })
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        toast(err.message, {
          type: 'error',
        })
      }
      setIsSubmitted(false)
    }
  }

  const defaultVaccine = useMemo(() => {
    const [first] = dashboardConfig?.vaccinations || []
    return first ? first.code : ''
  }, [dashboardConfig?.vaccinations])

  const [vaccine, setVaccine] = useState('010802001')
  const [vaccineDate, setVaccineDate] = useState()

  const getVaccines = useCallback(async () => {

    //console.log(dashboardConfig.symptoms);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/citizen-vaccination`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      console.log(data);
      setVaccines(data)
      
      data.map((v) => {
        if (v.vaccine_code === defaultVaccine) {
          setVaccineDate(v.created_at)
        }
      })
    } catch (err) {
      console.log(err)
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      }
    }
  }, [defaultVaccine, setUserAuth, userAuth])

  const handleChangeVaccine = (event) => {
    var { value } = event.target
    vaccines.map((v) => {
      if (v.vaccine_code === value) {
        setVaccineDate(v.created_at)
        setVaccine(value)
      }
    })
  }

  const [symptoms, setSymptoms] = useState({})
  const handleChangeSymptoms = (event) => {
    const { name } = event.target
    setSymptoms((prevSymptoms) => ({
      ...prevSymptoms,
      [name]: !prevSymptoms[name],
    }))
  }

  const symptomsCode = useMemo(() => {
    const keys = Object.keys(symptoms)
    var index = keys.indexOf('other')
    if (index > -1) {
      keys.splice(index, 1)
    }
    const activeKeys = keys.filter((k) => symptoms[k])
    return activeKeys.join(',')
  }, [symptoms])

  useEffect(() => {
    setActiveMenu('/reacciones-adversas')
    getVaccines()
  }, [getVaccines, setActiveMenu])

  useEffect(() => {
    const keys = Object.keys(symptoms)
    if (keys.length > 0) {
      const activeKeys = keys.filter((k) => symptoms[k])
      const prevErrors = formRef.current?.getErrors()
      if (activeKeys.length === 0 && !prevErrors.symptom) {
        setErrors({ ...prevErrors, symptom: 'Información requerida' })
      } else if (!!prevErrors.symptom) {
        const errors = Object.assign({}, prevErrors)
        delete errors.symptom
        setErrors(errors)
      }
    }
  }, [symptoms, setErrors])

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    if (name === 'symptomText') {
      value.match(/^(^$)|[a-zA-ZÀ-ÿ0-9\u00f1\u00d1\s,.-]+$/i)
        ? setValueText(value)
        : null
    }
    if (name === 'comment') {
      value.match(/^(^$)|[a-zA-ZÀ-ÿ0-9\u00f1\u00d1\s,.-]+$/i)
        ? setValueComment(value)
        : null
    }
  }

  const filtroVaccine = (vacine) =>{
    if(vacine.length==1){
      return vacine;
    }else{
      return vacine.sort((a,b) => b.created_at > a.created_at ? 1 : b.created_at < a.created_at ? -1:0);
    }
  }

  const vaccineDateReport = dayjs().format('DD/MM/YYYY')

  if (!vaccines) {
    return (
      <Box my={4} style={{ textAlign: 'center' }}>
        <CircularProgress size={24} color="primary" />
      </Box>
    )
  }

  if (vaccines.length === 0) {
    return (
      <Container maxWidth="md">
        <Box className={classes.container}>
          <Box mb={2}>
            <Typography variant="h5" align="center">
              Usted no puede registrar una reacción adversa si aún no ha sido
              vacunado.
            </Typography>
          </Box>
        </Box>
      </Container>
    )
  }

  return (
    <Container maxWidth="md">
      <Box className={classes.container} mt={2}>
        <Box mb={4}>
          <Grid container>
            <Grid lg={6}>
              <Box>
                <Box mb={2}>
                  <Typography variant="h5" align="center">
                    Nueva Reacción Adversa
                  </Typography>
                </Box>
                <Box className={classes.formBox}>
                  <Form
                    ref={formRef}
                    onSubmit={handleFormSubmit}
                    autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <b>Vacuna:</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="vaccineCode"
                          select
                          error={!!errors.vaccineCode}
                          helperText={errors.vaccineCode}
                          className={classes.selectVaccine}
                          value={vaccine}
                          onChange={handleChangeVaccine}>
                          {filtroVaccine(vaccines)?.map((vaccine, index) =>
                          index === 0 ?(
                            dashboardConfig?.vaccinations?.map(
                              ({ code, name }) =>
                                code === vaccine.vaccine_code ? (
                                  <MenuItem
                                    key={index}
                                    value={vaccine.vaccine_code}
                                    className={classes.optionVaccine}>
                                    {name}
                                  </MenuItem>
                                ) : null,
                            )
                          ) : null,
                          )}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <b>Fecha de vacunación</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {dayjs(vaccineDate).format('DD/MM/YYYY')}
                        </Typography>
                        <TextField
                          name="vaccineDate"
                          value={dayjs(vaccineDate).format('DD/MM/YYYY')}
                          hidden
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <b>Fecha de reporte</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {vaccineDateReport}
                        </Typography>
                        <TextField
                          name="vaccineDateReport"
                          value={vaccineDateReport}
                          hidden
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          <b>Sintomas:</b>
                        </Typography>
                        <Grid container>
                          {[
                            ...(dashboardConfig?.adverse_reaction || {}),
                            { name: 'Otro', code: 'other' },
                          ]?.map((sideEffect) => (
                            <Grid item xs={6} key={sideEffect.code}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={sideEffect.code}
                                    color="primary"
                                    checked={!!symptoms[sideEffect.code]}
                                    onChange={handleChangeSymptoms}
                                  />
                                }
                                label={sideEffect.name}
                              />
                            </Grid>
                          ))}
                        </Grid>
                        <TextField name="symptom" value={symptomsCode} hidden />
                        {!errors.symptom ? null : (
                          <FormHelperText error>
                            {errors.symptom}
                          </FormHelperText>
                        )}
                        <Box>
                          {symptoms.other ? (
                            <TextField
                              name="symptomText"
                              fullWidth
                              error={!!errors.symptomText}
                              helperText={errors.symptomText}
                              multiline
                              rowsMax={4}
                              value={valueText}
                              onChange={(event) => {
                                handleChangeInput(event)
                                setFieldError('symptomText', event.target.value)
                              }}
                            />
                          ) : (
                            <TextField name="symptomText" value="-" hidden />
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          <b>Comentarios:</b>
                        </Typography>
                        <Box>
                          <TextField
                            name="comment"
                            fullWidth
                            error={!!errors.comment}
                            helperText={errors.comment}
                            multiline
                            rows={4}
                            value={valueComment}
                            onChange={(event) => {
                              handleChangeInput(event)
                              setFieldError('comment', event.target.value)
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify="center">
                          <Button
                            variant="contained"
                            onClick={(event) => {
                              event.preventDefault()
                              history.push({ pathname: '/reacciones-adversas' })
                            }}
                            className={classes.button}>
                            Cancelar
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitted}
                            endIcon={
                              isSubmitted ? (
                                <CircularProgress size={24} color="primary" />
                              ) : null
                            }
                            className={classes.button}>
                            Aceptar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box color="white" className={classes.effectsImage}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default FormPage
