import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import axios from 'axios'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAttributesStyles } from './styles'
import Box from '../../components/box'
import { useFormErrors, TextField } from '../../components/input'
import * as Schema from '../../utils/validation'
import Context from '../../Context'

const ContactSchema = Schema.object().shape({
  cityCode: Schema.string().matches(/^[0-9]*$/, 'Solo se permiten números'),
  telephone: Schema.string()
    .min(6, 'Debe contener 6 ó 7 números')
    .max(7, 'Debe contener 6 ó 7 números')
    .matches(/^[0-9]*$/, 'Solo se permiten números'),
  phone: Schema.string()
    .length(9)
    .matches(
      /^(\9)+[0-9]{0,8}$/,
      'Solo se permiten números e inicie con el número 9',
    )
    .required(),
})

export const Contact = () => {
  const classes = useAttributesStyles()
  const { ubigeo, userSession, setUserSession } = useContext(Context)
  const history = useHistory()

  const codes = useMemo(() => {
    return ubigeo.phone_codes.sort((current, next) =>
      current.name.localeCompare(next.name),
    )
  }, [ubigeo])
  const [phoneCode, setPhoneCode] = useState(
    userSession?.profile?.phone?.cityCode || '',
  )

  const handlePhoneCodeChange = (event) => {
    const value = event.target.value
    setPhoneCode(value)
  }

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: ContactSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [valuePhone, setValuePhone] = useState('')
  const [valueTelephone, setValueTelephone] = useState('')

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await ContactSchema.validate(data)
      const headers = { Authorization: `Bearer ${userSession.token}` }
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/phone`,
        data,
        {
          headers,
        },
      )
      if (!response.success) {
        throw new Error(response.error?.message)
      }
      const prevProfile = userSession.profile || {}
      const session = {
        ...userSession,
        profile: { ...prevProfile, phone: data },
      }
      setUserSession(session)
      history.push({ pathname: '/user/attributes/validate-phone' })
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserSession(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        toast(err.message, {
          type: 'error',
        })
      }
      setIsSubmitted(false)
    }
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    if (name === 'phone') {
      value.match(/^(^$)|[0-9]+$/) ? setValuePhone(value) : null
    }
    if (name === 'telephone') {
      value.match(/^(^$)|[0-9]+$/) ? setValueTelephone(value) : null
    }
  }

  useEffect(() => {
    setValuePhone(userSession?.profile?.phone?.phone || '')
    setValueTelephone(userSession?.profile?.address?.telephone || '')
  }, [setValuePhone, setValueTelephone, userSession])

  return (
    <Box className={classes.container}>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Confirma tus números
        </Typography>
        <Typography variant="body1" component="h2">
          Ahora confirma tus números de contacto.
        </Typography>
      </Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              name="cityCode"
              label="Código"
              select
              fullWidth
              error={!!errors.cityCode}
              helperText={errors.cityCode}
              value={phoneCode}
              onChange={handlePhoneCodeChange}>
              {codes.map(({ code, name }) => (
                <MenuItem
                  key={code}
                  value={code}
                  style={{
                    display: 'block',
                    padding: '6px 16px 6px 16px',
                  }}>
                  {`${code} - ${name}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              inputProps={{
                maxLength: 7,
              }}
              name="telephone"
              label="Teléfono fijo"
              fullWidth
              error={!!errors.telephone}
              helperText={errors.telephone}
              defaultValue={userSession?.profile?.phone?.telephone || ''}
              value={valueTelephone}
              onChange={(event) => {
                handleChangeInput(event)
                setFieldError('telephone', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputProps={{
                maxLength: 9,
              }}
              name="phone"
              label="Número de celular"
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone}
              defaultValue={userSession?.profile?.phone?.phone || ''}
              value={valuePhone}
              onChange={(event) => {
                handleChangeInput(event)
                setFieldError('phone', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitted}
              endIcon={
                isSubmitted ? (
                  <CircularProgress size={24} color="primary" />
                ) : null
              }>
              Continuar
            </Button>
            <Box mt={2}>
              <Button
                variant="text"
                color="inherit"
                fullWidth
                disabled={isSubmitted}
                onClick={(event) => {
                  event.preventDefault()
                  history.push('/user/attributes/address')
                }}>
                Regresar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default Contact
