import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useCallback, useEffect, useState } from 'react'
import axios from 'axios'

import Box from '../../../components/box'
import { Link } from 'react-router-dom'

import ImageInjectable from '../../../assets/img/dashboard/injectable.png'
import ImageInjectableHover from '../../../assets/img/dashboard/injectable-hover.png'

import Context from '../../../Context'

const useStyles = makeStyles((theme) => ({
  topics: {
    color: '#000000',
    borderRadius: theme.spacing(1),
    transition: 'all .25s ease',
    webkitBoxShadow: '1px 1px 20px rgba(82, 78, 78, 0.1)',
    boxShadow: '1px 1px 20px rgba(82, 78, 78, 0.1)',
    '&:hover': {
      transform: 'translateY(-4px) scale(1.02)',
      webkitBoxShadow: '1px 1px 5px rgba(82, 78, 78, 0.44)',
      boxShadow: '1px 1px 5px rgba(82, 78, 78, 0.44)',
      '&:first-child': {
        color: '#2306DE',
      },
    },
  },
  sectionBgImage: {
    padding: theme.spacing(3, 3, 3, 11),
    backgroundSize: '65px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${ImageInjectable})`,
    backgroundPosition: '10px',
    transition: '0.9s',
    '&:hover': {
      padding: theme.spacing(3, 3, 3, 11),
      backgroundSize: '65px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${ImageInjectableHover})`,
      backgroundPosition: '10px',
    },
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))

const Information = () => {
  const classes = useStyles()
  const { userAuth, setUserAuth } = useContext(Context)
  const [information, setInformation] = useState([])
  const [search, setSearch] = useState([])

  const getInformation = useCallback(async () => {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/vaccinations`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      data = data.sort((a, b) => a['order'] - b['order']);
      
      setInformation(data)
      setSearch(data)
    } catch (err) {
      console.log(err)
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      }
    }
  }, [userAuth, setUserAuth])

  useEffect(() => {
    getInformation()
  }, [getInformation])

  const handleFilterVaccine = (event) => {
    var text = event.target.value
    const newData = information.filter(function (item) {
      const itemData = item.name.toUpperCase()
      const textData = text.toUpperCase()
      return itemData.indexOf(textData) > -1
    })
    setSearch(newData)
  }

  if (!information.length > 0) {
    return (
      <Box my={4} style={{ textAlign: 'center' }}>
        <CircularProgress size={24} color="primary" />
      </Box>
    )
  }

  return (
    <Box>
      <Box mb={3} mt={3} pl={1}>
        <Grid container spacing={2}>
          <Paper className={classes.root}>
            <IconButton
              className={classes.iconButton}
              aria-label="menu"></IconButton>
            <InputBase
              className={classes.input}
              placeholder="Buscar"
              inputProps={{ 'aria-label': 'Buscar' }}
              onChange={handleFilterVaccine}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Box>
      <Box mb={4} mt={2}>
        <Grid container spacing={2}>
          {search.map((info, index) => {
            const description_short = info.description_short + '...'
            return (
              <Grid item key={index} xs={12} md={6} lg={4}>
                <Link
                  to={`/info-vacunas/${info.code}`}
                  style={{ textDecoration: 'none' }}>
                  <Box className={classes.topics}>
                    <Box className={classes.sectionBgImage}>
                      <Typography
                        color="inherit"
                        variant="body1"
                        component="h2"
                        gutterBottom>
                        <b>{info.name}</b>
                      </Typography>
                      {/*<Typography
                      style={{color: '#000000'}}
                      color="inherit"
                      variant="body2"
                      component="h2"
                      gutterBottom>
                      {description_short}
                    </Typography>*/}
                    </Box>
                  </Box>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default Information
