export const loadGoogleAnalytics = (UA) => {
  if (!UA || process.env.NODE_ENV !== 'production') return
  window._gaq = [['_setAccount', UA], ['_trackPageview']]
  ;(function (d, t) {
    var g = d.createElement(t),
      s = d.getElementsByTagName(t)[0]
    g.src =
      ('https:' == window.location.protocol ? '//ssl' : '//www') +
      '.google-analytics.com/ga.js'
    s.parentNode.insertBefore(g, s)
  })(document, 'script')
}
