import React, { createContext, useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'

const Context = createContext()

const USER_AUTH = 'user_auth'
const USER_SESSION = 'user_session'
const DASHBOARD_SESSION = 'dashboard_session'

const getDashboardConfig = localStorage.getItem('dashboard_config')
const getDashboardSession = sessionStorage.getItem(DASHBOARD_SESSION)
const getUbigeo = localStorage.getItem('ubigeos')
const getUserSession = sessionStorage.getItem(USER_SESSION)
const getUserAuth = sessionStorage.getItem(USER_AUTH)

export const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(!!getUserAuth)
  const [activeMenu, setActiveMenu] = useState(
    window.location.hash.substring(1),
  )
  // User
  const [userAuth, setUserAuth] = useState(
    getUserAuth ? JSON.parse(getUserAuth) : null,
  )
  const handleSetUserAuth = useCallback((user, expired) => {
    if (!!user) {
      sessionStorage.setItem(USER_AUTH, JSON.stringify(user))
      setUserAuth(user)
      setIsAuth(true)
    } else {
      sessionStorage.removeItem(USER_AUTH)
      setUserAuth(null)
      setIsAuth(false)
      if (expired) {
        toast('Tu sesión ha expirado, vuelve a ingresar', {
          type: 'warning',
        })
      }
    }
  }, [])

  // User module
  const [userSession, setUserSession] = useState(
    getUserSession ? JSON.parse(getUserSession) : null,
  )
  const [ubigeo, setUbigeo] = useState(getUbigeo ? JSON.parse(getUbigeo) : null)

  const handleSetUserSession = useCallback((session, expired) => {
    if (!!session) {
      sessionStorage.setItem(USER_SESSION, JSON.stringify(session))
      setUserSession(session)
    } else {
      sessionStorage.removeItem(USER_SESSION)
      setUserSession(null)
      if (expired) {
        toast('Tu sesión ha expirado, vuelve a ingresar', {
          type: 'warning',
        })
      }
    }
  }, [])

  // Dashboard module
  const [dashboardSession, setDashboardSession] = useState(
    getDashboardSession ? JSON.parse(getDashboardSession) : null,
  )
  const [dashboardConfig, setDashboardConfig] = useState(
    getDashboardConfig ? JSON.parse(getDashboardConfig) : null,
  )

  const handleSetDashboardSession = useCallback((session) => {
    if (!!session) {
      sessionStorage.setItem(DASHBOARD_SESSION, JSON.stringify(session))
      setDashboardSession(session)
    } else {
      sessionStorage.removeItem(DASHBOARD_SESSION)
      setDashboardSession(null)
    }
  }, [])

  const timeoutSession = useRef()
  const handleTimeoutSession = useCallback(
    (time, typeSession) => {
      clearTimeout(timeoutSession.current)
      timeoutSession.current = setTimeout(() => {
        if (typeSession === 'register') {
          handleSetUserSession(null, 'expired')
        } else if (typeSession === 'dasboard') {
          handleSetUserAuth(null, 'expired')
        }
      }, time)
    },
    [handleSetUserAuth, handleSetUserSession],
  )

  const value = {
    activeMenu,
    setActiveMenu,
    isAuth,
    setIsAuth,
    userAuth,
    setUserAuth: handleSetUserAuth,
    ubigeo,
    setUbigeo,
    dashboardConfig,
    setDashboardConfig,
    dashboardSession,
    setDashboardSession: handleSetDashboardSession,
    userSession,
    setUserSession: handleSetUserSession,
    setTimeoutSession: handleTimeoutSession,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default Context
