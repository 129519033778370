import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { Form } from '@unform/web'
import axios from 'axios'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Box from '../../../components/box'
import { useFormErrors, TextField } from '../../../components/input'
import * as Schema from '../../../utils/validation'
import Context from '../../../Context'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 2, 1),
  },
}))

const AddressSchema = Schema.object().shape({
  department: Schema.string().required(),
  province: Schema.string().required(),
  district: Schema.string().required(),
  address: Schema.string().required(),
})

const Address = (props) => {
  const classes = useStyles()
  const { ubigeo, setUserAuth, userAuth } = useContext(Context)
  const [userPlace, setUserPlace] = useState({
    department: userAuth.ubigeo ? `${userAuth.ubigeo.slice(0, 2)}0000` : '',
    province: userAuth.ubigeo ? `${userAuth.ubigeo.slice(0, 4)}00` : '',
    district: userAuth.ubigeo || '',
  })
  const [valueAddress, setValueAddress] = useState('')

  const history = useHistory()

  const departments = useMemo(() => {
    return ubigeo.ubigeos
      .filter((geo) => geo.code.substr(-4) === '0000')
      .sort((current, next) =>
        current.department.localeCompare(next.department),
      )
  }, [ubigeo])

  const provinces = useMemo(() => {
    if (userPlace.department) {
      return ubigeo.ubigeos
        .filter(
          ({ code, province }) =>
            province &&
            code.substr(-2) === '00' &&
            userPlace.department.substring(0, 2) === code.substring(0, 2),
        )
        .sort((current, next) => current.province.localeCompare(next.province))
    }
    return []
  }, [ubigeo, userPlace.department])

  const districts = useMemo(() => {
    if (userPlace.province) {
      return ubigeo.ubigeos
        .filter(
          ({ code, district }) =>
            district &&
            code.substr(-2) !== '00' &&
            userPlace.province.substring(0, 4) === code.substring(0, 4),
        )
        .sort((current, next) => current.district.localeCompare(next.district))
    }
    return []
  }, [ubigeo, userPlace.province])

  const handleChangePlaces = (event) => {
    const { name, value } = event.target
    if (name === 'department') {
      setFieldError('department', event.target.value)
      setUserPlace((prevPlaces) => ({
        ...prevPlaces,
        ['province']: '',
      }))
      setUserPlace((prevPlaces) => ({
        ...prevPlaces,
        ['district']: '',
      }))
    }
    if (name === 'province') {
      setFieldError('province', event.target.value)
      setUserPlace((prevPlaces) => ({
        ...prevPlaces,
        ['district']: '',
      }))
    }
    if (name === 'district') {
      setFieldError('district', event.target.value)
    }
    setUserPlace((prevPlaces) => ({
      ...prevPlaces,
      [name]: value,
    }))
  }

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: AddressSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await AddressSchema.validate(data)
      const body = {
        ubigeo: data.district,
        address: data.address,
      }
      const headers = { Authorization: `Bearer ${userAuth.token}` }
      const {
        data: response,
      } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/profile/address`,
        body,
        { headers },
      )
      if (!response.success) {
        throw new Error(response.error?.message)
      }
      props.onSuccess()
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        toast(err.message, {
          type: 'error',
        })
      }
      setIsSubmitted(false)
    }
  }

  const handleChangeInput = (event) => {
    const { value } = event.target
    value.match(/^(^$)|[a-zA-ZÀ-ÿ0-9\u00f1\u00d1\s,.-/]+$/i)
      ? setValueAddress(value)
      : null
  }

  useEffect(() => {
    setValueAddress(userAuth?.address || '')
  }, [setValueAddress, userAuth])

  return (
    <Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="department"
              label="Departamento"
              select
              fullWidth
              error={!!errors.department}
              helperText={errors.department}
              value={userPlace.department}
              onChange={handleChangePlaces}>
              {departments.map(({ code, department }) => (
                <MenuItem
                  key={code}
                  value={code}
                  style={{
                    display: 'block',
                    padding: '6px 16px 6px 16px',
                  }}>
                  {department}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="province"
              label="Provincia"
              select
              fullWidth
              error={!!errors.province}
              helperText={errors.province}
              value={userPlace.province}
              onChange={handleChangePlaces}
              disabled={!userPlace.department}>
              {provinces.map(({ code, province }) => (
                <MenuItem
                  key={code}
                  value={code}
                  style={{
                    display: 'block',
                    padding: '6px 16px 6px 16px',
                  }}>
                  {province}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="district"
              label="Distrito"
              select
              fullWidth
              error={!!errors.district}
              helperText={errors.district}
              value={userPlace.district}
              onChange={handleChangePlaces}
              disabled={!userPlace.province}>
              {districts.map(({ code, district }) => (
                <MenuItem
                  key={code}
                  value={code}
                  style={{
                    display: 'block',
                    padding: '6px 16px 6px 16px',
                  }}>
                  {district}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="address"
              label="Dirección"
              fullWidth
              error={!!errors.address}
              helperText={errors.address}
              defaultValue={userAuth?.address || ''}
              value={valueAddress}
              onChange={(event) => {
                handleChangeInput(event)
                setFieldError('address', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Button
                variant="contained"
                onClick={(event) => {
                  event.preventDefault()
                  props.onClose?.()
                }}
                className={classes.button}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitted}
                endIcon={
                  isSubmitted ? (
                    <CircularProgress size={24} color="primary" />
                  ) : null
                }
                className={classes.button}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default Address
