import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import PinInput from 'react-pin-input'

import Box from '../../../components/box'
import { useFormErrors, TextField } from '../../../components/input'
import useCountDown from '../../../hooks/useCountDown'
import * as Schema from '../../../utils/validation'
import Context from '../../../Context'

const useStyles = makeStyles((theme) => ({
  container: {
    '& .error-pin': {
      fontSize: '0.75rem',
      color: '#f44336',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      textAlign: 'center',
    },
    '& .pincode-input-container': {
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      '& .pincode-input-container': {
        width: '100%',
        display: 'flex',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& .pincode-input-container': {
        width: '60%!important',
        display: 'flex',
      },
    },
    [theme.breakpoints.up('xs')]: {
      '& .pincode-input-container': {
        width: '100%',
        display: 'flex',
      },
      '& .pincode-input-text': {
        width: '14.5%!important',
      },
    },
    '& .pincode-input-text': {
      borderColor: 'rgba(0, 0, 0, 0.23)!important',
      borderRadius: '4px',
      marginRight: '10px',
      lineHeight: '40px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '18px',
    },
    '& .pincode-input-text:hover': {
      borderColor: '#000000!important',
    },
    '& .pincode-input-text:focus': {
      border: '2px solid #1463DA!important',
    },
  },
  button: {
    margin: theme.spacing(0, 2, 1),
  },
}))

const LinkButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button)

const PhoneVerifySchema = Schema.object().shape({
  token: Schema.string()
    .length(6)
    .matches(/^[0-9]*$/, 'Solo se permiten números')
    .required(),
})

export const PhoneVerify = (props) => {
  const classes = useStyles()
  const { setUserAuth, userAuth } = useContext(Context)
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: PhoneVerifySchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const {
    isComplete,
    remainingTime,
    resetCounter,
    startCounter,
  } = useCountDown({
    initialTimeSeconds: Number(process.env.REACT_APP_CONFIG_FORM_RESEND_TIME),
    autoplay: true,
  })
  var ele = []

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await PhoneVerifySchema.validate(data)
      const headers = { Authorization: `Bearer ${userAuth.token}` }
      const {
        data: response,
      } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/profile/phone-verify`,
        data,
        { headers },
      )
      if (!response.success) {
        
        throw new Error( response.error==undefined ? 
          'Lo sentimos, problemas al enviar el codigo intentelo nuevamente.':response.error.message,
        )
      }
      props.onSuccess()
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        toast(err.message, {
          type: 'error',
        })
      }
      setIsSubmitted(false)
    }
  }

  const handleResend = async () => {
    try {
      document.getElementById('token').value = ''
      ele.clear()
      resetCounter()
      startCounter()
      const headers = { Authorization: `Bearer ${userAuth.token}` }
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/profile/phone-forward`,
        { phone: props.phone },
        { headers },
      )
      if (!response.success) {
        throw new Error(response.error?.message)
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      } else {
        toast(err.message, {
          type: 'error',
        })
      }
    }
  }

  const handleOutput = (value) => {
    document.getElementById('token').value = value
    setFieldError('token', value)
  }

  return (
    <Box className={classes.container}>
      <Box mb={2}>
        <Typography variant="body1">
          Ingresa el código de validación que te hemos enviado a tu número de
          celular ******{props.phone.slice(-3)}
        </Typography>
      </Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <TextField name="phone" defaultValue={props.phone} hidden />
          <Grid item xs={12}>
            <TextField
              id="token"
              name="token"
              onChange={(event) => {
                setFieldError('token', event.target.value)
              }}
              hidden
            />
            <PinInput
              focus={true}
              length={6}
              type="numeric"
              ref={(n) => (ele = n)}
              onChange={handleOutput}
            />
            {errors.token ? <p className="error-pin">{errors.token}</p> : ''}
            <Box mt={1} style={{ textAlign: 'right' }}>
              <LinkButton
                size="small"
                variant="text"
                color="inherit"
                disabled={!isComplete}
                onClick={(event) => {
                  event.preventDefault()
                  handleResend()
                }}>
                {isComplete
                  ? 'Reenviar código'
                  : `Reenviar código de validación en ${remainingTime} segundos`}
              </LinkButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Button
                variant="contained"
                onClick={(event) => {
                  event.preventDefault()
                  props.onClose?.()
                }}
                className={classes.button}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitted}
                endIcon={
                  isSubmitted ? (
                    <CircularProgress size={24} color="primary" />
                  ) : null
                }
                className={classes.button}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default PhoneVerify
