import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import React, { useContext, useRef, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Document, Page } from 'react-pdf'

import Logotipo from '../../assets/img/logo.png'
import TermsImage from '../../assets/img/terms.png'
import Box from '../../components/box'
import { useFormErrors, Checkbox } from '../../components/input'
import * as Schema from '../../utils/validation'
import Context from '../../Context'
import fileTerms from '../../assets/files/terms.pdf'
import TermsHtml from './TermsHtml'

const useStyles = makeStyles((theme) => ({
  container: {
    borderWidth: 2,
    borderTopWidth: 4,
    borderStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderColor: theme.palette.grey[100],
    borderTopColor: theme.palette.primary.main,
    padding: theme.spacing(3, 2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 4),
    },
  },
  termsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  termsImage: {
    width: 700,
    maxWidth: '100%',
  },
}))

const TermsSchema = Schema.object().shape({
  terms: Schema.boolean().required(
    'Es necesario aceptar los términos y condiciones de uso',
  ),
})

export const Terms = () => {
  const classes = useStyles()
  const { userSession, setUserSession } = useContext(Context)
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors } = useFormErrors({
    schema: TermsSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [valueTerms, setValueTerms] = useState(userSession?.terms ? userSession.terms : false)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await TermsSchema.validate(data)
      const session = { ...userSession, lastPathname: '/user/politics' }
      setUserSession(session)
      history.push({ pathname: '/user/politics' })
    } catch (err) {
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.log('err', err)
      }
      setIsSubmitted(false)
    }
  }

  const handleChangeTerms = (event) => {
    setErrors(event.target.value)
    userSession.terms = event.target.checked
    setValueTerms(event.target.checked)
    setUserSession(userSession)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  if (!userSession?.token) {
    return <Redirect to="/" />
  }

  return (
    <Container>
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={4} style={{ textAlign: 'center' }}>
              <img src={Logotipo} alt="Vacuna para todos" width={150} />
            </Box>
          </Grid>
          <Grid item md={7}>
            <Typography variant="h5">
              ¡Bienvenido(a) {userSession?.name}!
            </Typography>
            <Typography variant="body1">
              Para iniciar con tu confirmación de datos, por favor lee los{' '}
              <b>TÉRMINOS Y CONDICIONES DE USO,</b>{' '}al finalizar da tu consentimiento.
            </Typography>
            <Box
              my={2}
              p={0}
              height={500}
              border="2px solid #EFEFEF"
              className="custom-scrollbar"
              style={{ overflowY: 'auto' }}>
              {/*<Document
                file={fileTerms}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (_, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
                </Document>*/}
                {TermsHtml}
            </Box>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <Box mb={4}>
                <Checkbox
                  name="terms"
                  label="Estoy de acuerdo con los TÉRMINOS Y CONDICIONES DE USO"
                  color="primary"
                  error={!!errors.terms}
                  checked={valueTerms}
                  helperText={errors.terms}
                  onChange={handleChangeTerms}
                />
              </Box>
              <Button
                variant="contained"
                disabled={isSubmitted}
                color="primary"
                type="submit"
                endIcon={isSubmitted ? <CircularProgress size={24} /> : null}>
                ACEPTAR
              </Button>
            </Form>
          </Grid>
          <Grid
            item
            md={5}
            container
            direction="column"
            alignItems="center"
            justify="center">
            <Box px={6} className={classes.termsContainer}>
              <Box style={{ textAlign: 'center' }}>
                <img
                  src={TermsImage}
                  alt="Términos y condiciones"
                  className={classes.termsImage}
                />
              </Box>
              {/*<Box>
                <Typography variant="body2">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Magnam .
                </Typography>
              </Box>*/}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Terms
