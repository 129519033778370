import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      // main: '#1463DA',
      main: '#2306DE',
      contrastText: '#fff'
    },
    secondary: {
      main: '#303030',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {},
    }
  },
})

export default theme
