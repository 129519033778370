import { makeStyles, withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Check from '@material-ui/icons/Check'
import axios from 'axios'
import clsx from 'clsx'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  useLocation,
  useRouteMatch,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import UserAddress from './Address'
import UserContact from './Contact'
import UserValidatePhone from './ValidatePhone'
import UserRegisterEmail from './RegisterEmail'
import UserValidateEmail from './ValidateEmail'
import UserEndRegistration from './EndRegistration'
import Logotipo from '../../assets/img/logo_blanco.png'
import VacunationImage from '../../assets/img/vacunacion.png'
import Box from '../../components/box'
import Context from '../../Context'
import { Typography } from '@material-ui/core'

function getSteps() {
  return [
    {
      label: 'Confirma tu dirección',
      path: '/address',
      icon: 'room',
    },
    {
      label: 'Números de contacto',
      path: '/contact',
      icon: 'assignment_ind',
    },
    {
      label: 'Validación de número',
      path: '/validate-phone',
      icon: 'phone_android',
    },
    {
      label: 'Registro de correo',
      path: '/register-email',
      icon: 'email',
    },
    {
      label: 'Validación de correo',
      path: '/validate-email',
      icon: 'checkbox',
    },
    {
      label: 'Registro exitoso',
      path: '/end-registration',
      icon: 'checkbox',
    },
  ]
}

const ThemeColor = '#2306DE';

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      // borderColor: '#007bff',
      borderColor: ThemeColor,
    },
  },
  completed: {
    '& $line': {
      // borderColor: '#007bff',
      borderColor: ThemeColor,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const useStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    // color: '#007bff',
    color: ThemeColor,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    border: '2px solid',
    borderColor: 'currentColor',
  },
  completed: {
    // color: '#007bff',
    color: ThemeColor,
    zIndex: 1,
    fontSize: 18,
  },
})

function CustomStepIcon(props) {
  const classes = useStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      maxWidth: theme.breakpoints.values.lg,
      margin: '0 auto',
    },
  },
  routesContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '-50px',
      margin: theme.spacing(0, 2),
    },
  },
  hiddenMD: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  logoContainer: {
    display: 'block',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  vacunaImage: {
    width: 500,
    maxWidth: '100%',
  },
  logotipo: {
    width: 200,
    maxWidth: '100%',
  },
  root: {
    '& .MuiStepLabel-label': {
      color: 'rgb(108 117 125 / 70%)'
    },
    '& .MuiStepLabel-active': {
      // color: '#007bff'
      color: ThemeColor
    },
    '& .MuiStepLabel-completed': {
      // color: '#007bff'
      color: ThemeColor
    }
  },

}))

const Attributes = () => {
  const classes = useStyles()
  const { userSession, setUserSession } = useContext(Context)
  const location = useLocation()
  const { path } = useRouteMatch()

  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  useEffect(() => {
    const index = steps.findIndex(
      (step) => `${path}${step.path}` === location.pathname,
    )
    if (index >= 0) {
      setActiveStep(index)
    }
  }, [location, path, steps])

  if (!userSession?.token) {
    return <Redirect to="/" />
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.hiddenMD}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomConnector />}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={CustomStepIcon} className={classes.root}>
                <Grid container alignItems="center" direction="column">
                  <Icon fontSize="large">{step.icon}</Icon>
                  <Typography>{step.label}</Typography>
                </Grid>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box className={classes.innerContainer}>
        <Grid container>
          <Grid item md={6} className={classes.hiddenMD}>
            <Box style={{ textAlign: 'center' }}>
              <img
                src={VacunationImage}
                alt="slide"
                className={classes.vacunaImage}
              />
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.logoContainer}>
            <Box style={{ textAlign: 'center' }}>
              <img src={Logotipo} alt="slide" className={classes.logotipo} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.routesContainer}>
            <Box>
              <Switch>
                <Route exact path={path}>
                  <Redirect to={{ pathname: `${path}/address` }} />
                </Route>
                <Route exact path={`${path}/address`}>
                  <UserAddress />
                </Route>
                <Route exact path={`${path}/contact`}>
                  <UserContact />
                </Route>
                <Route exact path={`${path}/validate-phone`}>
                  <UserValidatePhone />
                </Route>
                <Route exact path={`${path}/register-email`}>
                  <UserRegisterEmail />
                </Route>
                <Route exact path={`${path}/validate-email`}>
                  <UserValidateEmail />
                </Route>
                <Route exact path={`${path}/end-registration`}>
                  <UserEndRegistration />
                </Route>
                <Route
                  path="*"
                  render={() => <Redirect to={{ pathname: `/no-match` }} />}
                />
              </Switch>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Attributes
