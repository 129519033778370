import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import RadioBase from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { useField } from '@unform/core'
import React, { useEffect, useRef } from 'react'

const Radio = ({
  disabled,
  error,
  helperText,
  label,
  name,
  options,
  ...restProps
}) => {
  const inputRefs = useRef([])
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.checked)
        return checked ? checked.value : null
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value)
        if (item) {
          item.checked = true
        }
      },
    })
  }, [fieldName, registerField])

  return (
    <FormControl error={error}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup {...restProps} name={fieldName}>
        {options.map((option, index) => (
          <FormControlLabel
            key={option.id}
            control={
              <RadioBase
                color="primary"
                inputRef={(ref) => (inputRefs.current[index] = ref)}
              />
            }
            label={option.label}
            value={option.id}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {!error ? null : <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Radio
