import { makeStyles } from '@material-ui/core/styles'

export const useAttributesStyles = makeStyles((theme) => ({
  container: {
    borderTopStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    padding: theme.spacing(3, 2),
    backgroundColor: '#FFF',
    '& .error-pin': {
      fontSize: '0.75rem',
      color: '#f44336',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      textAlign: 'center',
    },
    '& .pincode-input-container': {
      margin: '0 auto'
    },
    [theme.breakpoints.up('md')]: {
      borderTopWidth: 4,
      marginTop: theme.spacing(4),
      padding: theme.spacing(6, 4),
      '& .pincode-input-container': {
        width: '100%',
        display: 'flex',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& .pincode-input-container': {
        width: '60%!important',
        display: 'flex',
      },
    },
    [theme.breakpoints.up('xs')]: {
      '& .pincode-input-container': {
        width: '100%',
        display: 'flex',
      },
      '& .pincode-input-text': {
        width: '14.5%!important',
      }
    },
    '& .pincode-input-text': {
      borderColor: 'rgba(0, 0, 0, 0.23)!important',
      borderRadius: '4px',
      marginRight: '10px',
      lineHeight: '40px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '18px',
    },
    '& .pincode-input-text:hover': {
      borderColor: '#000000!important',
    },
    '& .pincode-input-text:focus': {
      border: '2px solid #1463DA!important',
    }
  }
}))
