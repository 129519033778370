import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { Form } from '@unform/web'
import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Box from '../../../components/box'
import { useFormErrors, TextField } from '../../../components/input'
import * as Schema from '../../../utils/validation'
import Context from '../../../Context'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 2, 1),
  },
}))

const EmailSchema = Schema.object().shape({
  email: Schema.string().email(),
})

export const Email = (props) => {
  const classes = useStyles()
  const { setUserAuth, userAuth } = useContext(Context)
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: EmailSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await EmailSchema.validate(data)
      const headers = { Authorization: `Bearer ${userAuth.token}` }
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/profile/email`,
        data,
        {
          headers,
        },
      )
      if (!response.success) {
        throw new Error(response.error?.message)
      }
      props.onSuccess(data)
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
        return
      }

      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        toast(err.message, {
          type: 'error',
        })
      }
      setIsSubmitted(false)
    }
  }

  return (
    <Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Correo electrónico"
              fullWidth
              error={!!errors.email}
              helperText={errors.email}
              defaultValue={userAuth.email || ''}
              onChange={(event) => {
                setFieldError('email', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Button
                variant="contained"
                onClick={(event) => {
                  event.preventDefault()
                  props.onClose?.()
                }}
                className={classes.button}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitted}
                endIcon={
                  isSubmitted ? (
                    <CircularProgress size={24} color="primary" />
                  ) : null
                }
                className={classes.button}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default Email
