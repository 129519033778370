import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAttributesStyles } from './styles'
import Box from '../../components/box'
import { useFormErrors, TextField } from '../../components/input'
import * as Schema from '../../utils/validation'
import Context from '../../Context'

const LinkButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button)

const RegisterEmailSchema = Schema.object().shape({
  email: Schema.string().email(),
})

export const RegisterEmail = () => {
  const classes = useAttributesStyles()
  const { userSession, setUserSession, setUserAuth, setIsAuth } = useContext(Context)
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: RegisterEmailSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await RegisterEmailSchema.validate(data)
      const headers = { Authorization: `Bearer ${userSession.token}` }
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/email`,
        data,
        {
          headers,
        },
      )
      if (!response.success) {
        throw new Error(response.error?.message)
      }
      const prevProfile = userSession.profile || {}
      const session = {
        ...userSession,
        profile: { ...prevProfile, email: data.email },
      }
      setUserSession(session)
      history.push({ pathname: '/user/attributes/validate-email' })
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserSession(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        toast(err.message, {
          type: 'error',
        })
        console.error('err', err)
      }
      setIsSubmitted(false)
    }
  }

  const handleConfirm = (event) => {

    event.preventDefault()

    const prevProfile = userSession.profile || {};
    const session = {
      ...userSession,
      profile: { ...prevProfile, email: null },
    }
    setUserAuth(session)
    setUserSession(null)
    setIsAuth(true)
    
    history.replace('/afiliacion/encuesta/declaracion-jurada')
  }

  return (
    <Box className={classes.container}>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Confirma tu correo
        </Typography>
        <Typography variant="body1" component="h2">
          Confirma tu correo electrónico para mantenerte informado sobre las fechas de vacunación.
        </Typography>
      </Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Correo electrónico"
              fullWidth
              error={!!errors.email}
              helperText={errors.email}
              defaultValue={userSession?.profile?.email || ''}
              onChange={(event) => {
                setFieldError('email', event.target.value)
              }}
            />
            <Box mt={1} style={{ textAlign: 'right' }}>
              <LinkButton
                size="small"
                variant="text"
                color="inherit"
                //onClick={(event) => {
                //  event.preventDefault()
                //  history.push('/user/attributes/end-registration')
                //}}>
                onClick={handleConfirm}
              >
                No cuento con correo electrónico
              </LinkButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitted}
              endIcon={
                isSubmitted ? (
                  <CircularProgress size={24} color="primary" />
                ) : null
              }>
              Continuar
            </Button>
            {/*<Box mt={2}>
              <Button
                variant="text"
                color="inherit"
                fullWidth
                disabled={isSubmitted}
                onClick={(event) => {
                  event.preventDefault()
                  history.push('/user/attributes/validate-phone')
                }}>
                Regresar
              </Button>
              </Box>*/}
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default RegisterEmail
