import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import axios from 'axios'
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAttributesStyles } from './styles'
import Box from '../../components/box'
import { useFormErrors, TextField } from '../../components/input'
import * as Schema from '../../utils/validation'
import Context from '../../Context'

const AddressSchema = Schema.object().shape({
  department: Schema.string().required(),
  province: Schema.string().required(),
  district: Schema.string().required(),
  address: Schema.string()
    .matches(
      /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1\s,.-/]+$/i,
      'Sólo se permiten letras y números',
    )
    .max(150, 'Debe contener menos de 150 caracteres')
    .required(),
})

const Address = () => {
  const classes = useAttributesStyles()
  const { ubigeo, userSession, setUserSession } = useContext(Context)
  const history = useHistory()
  const [valueAddress, setValueAddress] = useState('')

  const [userPlace, setUserPlace] = useState({
    department:
      userSession?.profile?.address?.department || userSession.ubigeo_dep,
    province: userSession?.profile?.address?.province || userSession.ubigeo_pro,
    district: userSession?.profile?.address?.district || userSession.ubigeo_dis,
  })

  const departments = useMemo(() => {
    return ubigeo.ubigeos
      .filter((geo) => geo.code.substr(-4) === '0000')
      .sort((current, next) =>
        current.department.localeCompare(next.department),
      )
  }, [ubigeo])

  const provinces = useMemo(() => {
    if (userPlace.department) {
      return ubigeo.ubigeos
        .filter(
          ({ code, province }) =>
            province &&
            code.substr(-2) === '00' &&
            userPlace.department.substring(0, 2) === code.substring(0, 2),
        )
        .sort((current, next) => current.province.localeCompare(next.province))
    }
    return []
  }, [ubigeo, userPlace.department])

  const districts = useMemo(() => {
    if (userPlace.province) {
      return ubigeo.ubigeos
        .filter(
          ({ code, district }) =>
            district &&
            code.substr(-2) !== '00' &&
            userPlace.province.substring(0, 4) === code.substring(0, 4),
        )
        .sort((current, next) => current.district.localeCompare(next.district))
    }
    return []
  }, [ubigeo, userPlace.province])

  const handleChangePlaces = (event) => {
    const { name, value } = event.target
    if (name === 'department') {
      setFieldError('department', event.target.value)
      setUserPlace((prevPlaces) => ({
        ...prevPlaces,
        ['province']: '',
      }))
      setUserPlace((prevPlaces) => ({
        ...prevPlaces,
        ['district']: '',
      }))
    }
    if (name === 'province') {
      setFieldError('province', event.target.value)
      setUserPlace((prevPlaces) => ({
        ...prevPlaces,
        ['district']: '',
      }))
    }
    if (name === 'district') {
      setFieldError('district', event.target.value)
    }
    setUserPlace((prevPlaces) => ({
      ...prevPlaces,
      [name]: value,
    }))
  }

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: AddressSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await AddressSchema.validate(data)
      const body = {
        ubigeo: data.district,
        address: data.address,
      }
      const headers = { Authorization: `Bearer ${userSession.token}` }
      const {
        data: response,
      } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/address`,
        body,
        { headers },
      )
      if (!response.success) {
        throw new Error(response.error?.message)
      }
      const prevProfile = userSession.profile || {}
      const session = {
        ...userSession,
        profile: { ...prevProfile, address: data },
      }
      setUserSession(session)
      history.push({ pathname: '/user/attributes/contact' })
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserSession(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      }
      setIsSubmitted(false)
    }
  }

  const handleChangeInput = (event) => {
    const { value } = event.target
    value.match(/^(^$)|[a-zA-ZÀ-ÿ0-9\u00f1\u00d1\s,.-/]+$/i)
      ? setValueAddress(value)
      : null
  }

  useEffect(() => {
    setValueAddress(
      userSession?.profile?.address?.address || userSession.address,
    )
  }, [setValueAddress, userSession])

  return (
    <Box className={classes.container}>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Confirma tu dirección
        </Typography>
        <Typography variant="body1" component="h2">
          Verifica y confirma tus datos personales.
        </Typography>
      </Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="department"
              label="Departamento"
              select
              fullWidth
              error={!!errors.department}
              helperText={errors.department}
              value={userPlace.department}
              onChange={handleChangePlaces}>
              {departments.map(({ code, department }) => (
                <MenuItem
                  key={code}
                  value={code}
                  style={{
                    display: 'block',
                    padding: '6px 16px 6px 16px',
                  }}>
                  {department}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="province"
              label="Provincia"
              select
              fullWidth
              error={!!errors.province}
              helperText={errors.province}
              value={userPlace.province}
              onChange={handleChangePlaces}>
              {provinces.map(({ code, province }) => (
                <MenuItem
                  key={code}
                  value={code}
                  style={{
                    display: 'block',
                    padding: '6px 16px 6px 16px',
                  }}>
                  {province}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="district"
              label="Distrito"
              select
              fullWidth
              error={!!errors.district}
              helperText={errors.district}
              value={userPlace.district}
              onChange={handleChangePlaces}>
              {districts.map(({ code, district }) => (
                <MenuItem
                  key={code}
                  value={code}
                  style={{
                    display: 'block',
                    padding: '6px 16px 6px 16px',
                  }}>
                  {district}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="address"
              label="Dirección"
              fullWidth
              error={!!errors.address}
              helperText={errors.address}
              defaultValue={userSession?.profile?.address?.address || ''}
              value={valueAddress}
              onChange={(event) => {
                handleChangeInput(event)
                setFieldError('address', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitted}
              endIcon={
                isSubmitted ? (
                  <CircularProgress size={24} color="primary" />
                ) : null
              }>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default Address
