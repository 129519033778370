import Appointments from './Appointments'
import Home from './Home'
import Membership from './Membership'
import Information from './Information'
import Profile from './Profile'
import SideEffects from './SideEffects'
import Strategy from './Strategy'

const routes = [
  {
    name: 'Inicio',
    icon: 'home',
    path: '/',
    component: Home,
  },
  {
    name: 'Vacuna COVID-19',
    icon: 'local_hospital',
    path: '/afiliacion',
    component: Membership,
    exact: false,
  },
  /*{
    name: 'Plan nacional',
    icon: 'flag',
    path: '/plan-nacional',
    component: Strategy,
  },*/
  {
    name: 'Citas de vacunación',
    icon: 'calendar_today',
    path: '/citas-vacunacion',
    component: Appointments,
    exact: false,
  },
  {
    name: 'Reacciones adversas',
    icon: 'report_problem',
    path: '/reacciones-adversas',
    component: SideEffects,
    exact: false,
  },
  {
    name: 'Info vacunas',
    icon: 'info',
    path: '/info-vacunas',
    component: Information,
    exact: false,
  },
  /*{
    name: 'Contáctanos',
    icon: 'phone_android',
    path: '/contactanos',
    component: Home,
  },*/
  {
    name: 'Profile',
    path: '/perfil',
    component: Profile,
    submenu: false,
  },
]

export default routes
