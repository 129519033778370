import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import React, { useContext, useRef, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Document, Page } from 'react-pdf'

import Logotipo from '../../assets/img/logo.png'
import PoliticsImage from '../../assets/img/terminos.png'
import Box from '../../components/box'
import { useFormErrors, Checkbox } from '../../components/input'
import * as Schema from '../../utils/validation'
import Context from '../../Context'
import filePolitics from '../../assets/files/politics.pdf'
import PoliticsHtml from './PoliticsHtml'

const useStyles = makeStyles((theme) => ({
  container: {
    borderWidth: 2,
    borderTopWidth: 4,
    borderStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderColor: theme.palette.grey[100],
    borderTopColor: theme.palette.primary.main,
    padding: theme.spacing(3, 2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 4),
    },
  },
  politicsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  politicsImage: {
    width: 700,
    maxWidth: '100%',
  },
}))

const PoliticsSchema = Schema.object().shape({
  politics: Schema.boolean().required('Es necesario aceptar la política de protección de datos personales'),
})

export const Politics = () => {
  const classes = useStyles()
  const { userSession, setUserSession } = useContext(Context)
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors } = useFormErrors({
    schema: PoliticsSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [valuePolitics, setValuePolitics] = useState(userSession?.politics ? userSession.politics : false)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await PoliticsSchema.validate(data)
      const session = { ...userSession, lastPathname: '/user/attributes' }
      setUserSession(session)
      history.push({ pathname: '/user/attributes' })
    } catch (err) {
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.log('err', err)
      }
      setIsSubmitted(false)
    }
  }

  const handleChangePolitics = (event) => {
    setErrors(event.target.value)
    userSession.politics = event.target.checked
    setValuePolitics(event.target.checked)
    setUserSession(userSession)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  if (!userSession?.token) {
    return <Redirect to="/" />
  }

  return (
    <Container>
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={4} style={{ textAlign: 'center' }}>
              <img src={Logotipo} alt="Vacuna para todos" width={150} />
            </Box>
          </Grid>
          <Grid item md={7}>
            <Typography variant="h5">¡Bienvenido(a) {userSession.name}!</Typography>
            <Typography variant="body1">
              Te invitamos a leer nuestra{' '}
              <b>POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES,</b>{' '}al finalizar da tu consentimiento.
            </Typography>
            <Box
              my={2}
              p={0}
              height={500}
              border="2px solid #EFEFEF"
              className="custom-scrollbar"
              style={{ overflowY: 'auto' }}>
              {PoliticsHtml}
            </Box>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <Box mb={4}>
                <Checkbox
                  name="politics"
                  label="Estoy de acuerdo con la POLÍTICA DE PROTECCIÓN DE DATOS
                  PERSONALES."
                  color="primary"
                  error={!!errors.politics}
                  helperText={errors.politics}
                  checked={valuePolitics}
                  onChange={handleChangePolitics}
                />
              </Box>
              <Button
                variant="contained"
                disabled={isSubmitted}
                color="primary"
                type="submit"
                endIcon={isSubmitted ? <CircularProgress size={24} /> : null}>
                ACEPTAR
              </Button>
            </Form>
          </Grid>
          <Grid
            item
            md={5}
            container
            direction="column"
            alignItems="center"
            justify="center">
            <Box px={6} className={classes.politicsContainer}>
              <Box style={{ textAlign: 'center' }}>
                <img
                  src={PoliticsImage}
                  alt="Políticas de datos"
                  className={classes.politicsImage}
                />
              </Box>
              {/*<Box>
                <Typography variant="body2">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Magnam .
                </Typography>
              </Box>*/}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Politics
