import { styled } from '@material-ui/core/styles'
import {
  compose,
  borders,
  shadows,
  sizing,
  spacing,
  palette,
} from '@material-ui/system'

const Box = styled('div')(compose(borders, shadows, sizing, spacing, palette))

export default Box
