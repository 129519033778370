import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useRouteMatch, Redirect, Route, Switch } from 'react-router-dom'

import MembershipConsent from './membership/Consent'
import MembershipProgram from './membership/Program'
import MembershipQuiz from './membership/Quiz'
import MembershipSuccess from './membership/Success'
import Box from '../../components/box'
import Context from '../../Context'

const Membership = () => {
  const { setDashboardSession, userAuth, setUserAuth } = useContext(Context)
  const { path } = useRouteMatch()
  const [membership, setMembership] = useState()

  const findSurvey = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/find-survey`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      if (data.status === 'complete') {
        setDashboardSession({ lastPathname: '/afiliacion/confirmacion' })
      }
      setMembership(data.status)
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      } else {
        setMembership('error')
      }
    }
  }, [setDashboardSession, setUserAuth, setMembership, userAuth])

  useEffect(() => {
    findSurvey()
  }, [findSurvey])

  const getContent = useCallback(() => {
    if (!membership) {
      return (
        <Box my={4} style={{ textAlign: 'center' }}>
          <CircularProgress size={24} color="primary" />
        </Box>
      )
    }

    switch (membership) {
      case 'pending':
      case 'complete':
        return (
          <Switch>
            <Route exact path={path}>
              <MembershipProgram />
            </Route>
            <Route path={`${path}/encuesta`}>
              <MembershipQuiz />
            </Route>
            <Route exact path={`${path}/consentimiento-informado`}>
              <MembershipConsent />
            </Route>
            <Route exact path={`${path}/confirmacion`}>
              <MembershipSuccess />
            </Route>
          </Switch>
        )
      case 'error':
      default:
        return (
          <Box my={4}>
            <Typography variant="body1" align="center" color="error">
              Lo sentimos, no pudimos ver el estado de tu vacuna COVID-19.
              Inténtalo más tarde.
            </Typography>
          </Box>
        )
    }
  }, [membership, path])

  if (membership === 'appointment') {
    return <Redirect to="/citas-vacunacion" />
  }

  return (
    <Box className="_encuesta">
      <Box mb={2}>
        <Typography
          variant="h5"
          component="h2"
          className="title-head"
          gutterBottom>
          Vacuna COVID-19
        </Typography>
        <Typography variant="body1" component="h2" className="title-head">
            Regístrate en el programa de vacunación contra la COVID-19, para programar tu cita y gestionar tu información.
        </Typography>
        {getContent()}
      </Box>
    </Box>
  )
}

export default Membership
