import React from "react";

const htmlContent = `
<h1 style="color: #212529; text-align: center; margin-top: 20px; font-size: 1.5rem;">TÉRMINOS Y CONDICIONES DEL USO DE LA PLATAFORMA VACUNA PARA TODOS </h1>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
    El presente contrato describe los términos y condiciones generales (en lo sucesivo los “Términos y Condiciones”), que regulan el uso y los beneficios que la <strong>Plataforma Vacuna para Todos</strong> para vacunas ofrece a los usuarios (en lo sucesivo el “Usuario”). 
  </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Lea atentamente estos Términos y Consentimiento de uso. Si no está de acuerdo con cualquiera de estos, no puede utilizar este sitio web y no debe introducir ninguna información en él.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">El uso de la Plataforma conlleva la <strong>aceptación implícita</strong> por parte del Usuario de todas las cláusulas y políticas establecidas en estos Términos y Condiciones. Asimismo, al utilizar la Plataforma estará comunicándose con [SALUTARE S.A.C.] a través de medios electrónicos, por lo que acepta recibir   comunicación   bajo   esta   modalidad   utilizando   diversos   mecanismos   tales   como: videollamada,  el  aplicativo  web o móvil denominado “WhatsApp” de WhatsApp, Inc., Correo Electrónico, mensajes de texto, avisos y la plataforma de mensajería personal de la plataforma; en virtud de  lo  anterior,  el  Usuario reconoce que los formularios, contratos, avisos, mensajes, así como cualquier tipo de notificación y/o comunicación que se envíe por los medios electrónicos satisfacen cualquier requisito legal y cuentan con plenos efectos legales y validez.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">El uso de notificaciones, así como su habilitación o deshabilitación, será entera responsabilidad del Usuario. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">El medio de notificación que utilizará SALUTARE S.A.C. para informar al usuario respecto de las Operaciones Electrónicas realizadas por el Usuario, será el correo electrónico proporcionado por el mismo durante el proceso de suscripción.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Definiciones.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Para efectos de los presentes Términos y Condiciones, cualquier palabra que comience con mayúscula, deberá ser entendida conforme a las siguientes definiciones:</p>
  <ol type="a">
    <li style="padding-bottom: 5px;"><strong>Asegurado o afiliado:</strong> Persona beneficiaria de las prestaciones a cargo de SALUD.
    </li><li style="padding-bottom: 5px;"><strong>Autenticación:</strong> el conjunto de técnicas y procedimientos utilizados para verificar la identidad del Usuario y su facultad para realizar Operaciones Electrónicas y para enviar instrucciones a SALUTARE S.A.C. a través de medios electrónicos.
    </li><li style="padding-bottom: 5px;"><strong>Asegurador:</strong> Empresas o entidades, públicas, privadas o mixtas, autorizadas conforme a ley a ofrecer coberturas de Salud, bajo la modalidad de IAFAS o empresas de seguros.
    </li><li style="padding-bottom: 5px;"><strong>Autoridades:</strong> Ministerio de SALUD y sus órganos desconcentrados.
    </li><li style="padding-bottom: 5px;"><strong>Contraseña:</strong> Cadena de caracteres que autentica al Usuario en los Medios Electrónicos.
    </li><li style="padding-bottom: 5px;"> <strong>Correo Electrónico:</strong> Los mensajes disponibles para su envío a través de sistemas de comunicación electrónica, mediante los cuales SALUTARE S.A.C. realizará las notificaciones necesarias.
    </li><li style="padding-bottom: 5px;"><strong>Cuenta: </strong> El conjunto de ID y Contraseña que permite al Usuario acceder a la plataforma para realizar Operaciones Electrónicas.
    </li><li style="padding-bottom: 5px;"><strong>Directorio:</strong> Directorio de hospitales, doctores, laboratorios y farmacias, todos los anteriores independientes de SALUTARE S.A.C.
    </li><li style="padding-bottom: 5px;"><strong>Equipo del Usuario:</strong> Equipo que cuenta con un sistema operativo iOS o Android, mediante el cual se realiza el uso de la Plataforma y se inicia sesión con el ID y Contraseña.
    </li><li style="padding-bottom: 5px;"><strong>ID:</strong> Cadena de al menos siete caracteres a través del cual el Usuario se identifica en los Medios Electrónicos.
    </li><li style="padding-bottom: 5px;"><strong>Medios Electrónicos:</strong> comprende videollamada, el aplicativo web o móvil denominado “WhatsApp” de WhatsApp, Inc., redes sociales, Correo Electrónico, mensajes de texto, avisos y la plataforma de mensajería personal de la plataforma.
    </li><li style="padding-bottom: 5px;"><strong> Mecanismos de confirmación:</strong> Notificaciones enviadas por SALUTARE S.A.C. al Usuario a través de los Medios Electrónicos.
    </li><li style="padding-bottom: 5px;">
        <strong>Operaciones Electrónicas: </strong> Conjunto de acciones determinadas para que SALUTARE S.A.C. pueda realizar operaciones y servicios requeridos por el Usuario a través de los Medios Electrónicos, para efectos de los presentes Términos y Condiciones serán considerados de manera enunciativa pero no limitativa las siguientes:
        <ol type="a">
          <li>Afiliación al programa.
          </li><li>Generación de cita para vacunación.
          </li><li>Trazabilidad de vacunación
        </li></ol>
    </li><li style="padding-bottom: 5px;"><strong>Plataforma: </strong> Plataformas o aplicaciones oficiales de SALUTARE S.A.C. que se usan para brindar al Usuario información, dentro de los cuales se contempla el uso de los Medios Electrónicos.
    </li><li style="padding-bottom: 5px;">
        <strong>Servicios: </strong> Los siguientes Servicios brindados por SALUTARE S.A.C. al Usuario a través de la plataforma:
        <ol type="a">
          <li>Consulta en Línea.
          </li><li>Registro de Reacciones adversas
          </li><li>Gestión de información de vacunas
        </li></ol>
    </li><li style="padding-bottom: 5px;"><strong> Términos y Condiciones:</strong> Los presentes términos y condiciones de a plataforma, así como del uso de medios electrónicos que pueda publicar SALUTARE S.A.C. de tiempo en tiempo.
    </li><li style="padding-bottom: 5px;"><strong>Usuario: </strong> Persona con registro en la plataforma de SALUTARE S.A.C.
    </li><li style="padding-bottom: 5px;"><strong> Vacunas:</strong> Se entiende por vacuna cualquier preparación destinada a generar inmunidad contra una enfermedad estimulando la producción de anticuerpos. Puede tratarse, por ejemplo, de una suspensión de microorganismos muertos o atenuados, o de productos o derivados de microorganismos. El método más habitual para administrar las vacunas es la inyección, aunque algunas se administran con un vaporizador nasal u oral.
  </li></ol>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Objeto.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
    El Usuario a través de la Plataforma Vacuna para Todos podrá acceder a los Servicios y recibir información relacionada con los mismos, conforme a los presentes Términos y Condiciones. 
  </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
    SALUTARE S.A.C. se reserva el derecho de ampliar o disminuir el número de Operaciones Electrónicas, sin embargo, dichas modificaciones deberán de ser publicadas en la Plataforma de SALUTARE S.A.C. para conocimiento del Usuario.
  </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
    SALUTARE S.A.C. se reserva el derecho de rechazar cualquier solicitud de Cuenta, cancelar o suspender una Cuenta, sin que esté obligado a comunicar las razones de ello y sin que ello genere alguna responsabilidad para SALUTARE S.A.C.
  </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
    En ningún momento se entenderá que la presentación por parte del Usuario de la solicitud debidamente llenada, así como la aceptación de los presentes Términos y Condiciones constituye la aceptación del riesgo por parte de SALUTARE S.A.C., sin que ello genere responsabilidad alguna para SALUTARE S.A.C.
  </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
    SALUTARE S.A.C. se reserva el derecho de grabar cualquier conversación telefónica sostenida con el Usuario, en caso de utilizar este medio de comunicación.
  </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Contenido del sitio web y/o portal web </strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">La Plataforma Vacuna para Todos proporciona los materiales de este sitio web únicamente para su propio uso con fines informativos únicamente. Todo uso del sitio web está sujeto a los términos y condiciones establecidos a continuación. Al acceder y utilizar este sitio web, usted acepta estar sujeto a estos Términos de uso y a todos los demás términos y políticas que aparecen en este sitio web. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Fuera de la información que usted reciba directamente de los proveedores, el contenido de este sitio web no se debe considerar asesoramiento médico. Usted siempre deberá consultar con un profesional de la salud cualificado para diagnosticar y ofrecer tratamiento, incluida la información sobre cuáles medicamentos o tratamientos son apropiados para usted. Ninguna parte del contenido de este sitio web representa o garantiza la seguridad, idoneidad o efectividad de un medicamento o tratamiento particular para usted. Sin restricción alguna, la plataforma Vacuna para Todos no recomienda ni endosa ningún examen, proveedor, medicamento, producto o procedimiento específico.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Acceso a Medios Electrónicos.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Para el primer acceso a la plataforma Vacuna para Todos, el Usuario deberá ingresar en la misma del siguiente link [<a href="https://vacunaparatodos.pe">https://vacunaparatodos.pe</a>] y llevar a cabo un registro previo en la plataforma de SALUTARE S.A.C. en el que deberá de brindar cierta información que ayude a su Autenticación, dicha información será utilizada en términos de los presentes Términos y Condiciones y el Aviso de Privacidad (según dicho término se describe en estos Términos y Condiciones). A efecto de lo anterior, el Usuario reconoce y acepta que, la correcta prestación de los Servicios y la utilización la plataforma depende de la veracidad, certeza y exactitud de la información proporcionada por el Usuario, por lo que el único responsable de la información proporcionada y operaciones de la Cuenta será el Usuario, de tal manera que SALUTARE S.A.C no será responsable de la certeza de la información ni operaciones que ocurran en la Cuenta y SALUTARE S.A.C. no se responsabilizará en forma alguna por la prestación de los Servicios y la utilización de la plataforma en el supuesto en el que el Usuario proporcione información errónea, falsa, incompleta o desactualizada.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE S.A.C. puede en cualquier momento solicitar cualquier tipo de documento o dato adicional a efecto de corroborar la información de la Cuenta. SALUTARE S.A.C. tendrá derecho a suspender temporal o definitivamente a aquellos Usuarios cuyos datos o información no hayan podido ser confirmados, o no sean correctos.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Una vez registrado, el Usuario podrá acceder a los Medios Electrónicos con su ID y su Contraseña. El Asegurado autoriza que el Contratante podrá realizar las siguientes acciones a través del Usuario</p>
  <ol type="a">
    <li>Solicitar y acceder a los Servicios en nombre del usuario.
    </li><li>Brindar o Intercambiar información médica a nombre del usuario.
    </li><li>Otros contemplados en la plataforma o en los presentes Términos y Condiciones.
  </li></ol>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">En caso de que el Usuario requiera que se modifique su Contraseña o ID deberá de cumplir con una serie de pasos en el momento que lo desee, mediante cualquiera de los Medios Electrónicos disponibles que tenga habilitado dicho servicio.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Obligaciones del Usuario.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">En adición a las demás obligaciones contenidas dentro de los presentes Términos y Condiciones, el Usuario deberá cumplir con las siguientes obligaciones como medidas para mitigar riesgos inherentes al uso de Medios Electrónicos; así como prevenir la realización de operaciones irregulares o ilegales:</p>
  <ol type="a">
    <li style="padding-bottom: 5px;">El Usuario se compromete a mantener de forma segura y confidencial el ID y Contraseña, y en ningún caso SALUTARE S.A.C. será responsable del uso del ID y Contraseña del Usuario; por lo anterior, el Usuario será responsable del correcto uso de su cuenta y, por lo tanto, deberá pagar a SALUTARE S.A.C. cualquier daño, perjuicio, gasto o costo derivado del mal uso de la cuenta.
    </li><li style="padding-bottom: 5px;">En el momento en que el Usuario tenga conocimiento o sospeche el robo o el uso no autorizado de su cuenta, deberá notificar inmediatamente a SALUTARE S.A.C. por el medio que mejor le convenga, incluyendo el correo electrónico mencionado de la institución, y realizar la modificación de su ID y Contraseña.
    </li><li style="padding-bottom: 5px;">En caso de que el Usuario exceda el número de intentos permitidos de acceso a la plataforma por tratar de ingresar con información errónea deberá de notificar tal circunstancia a SALUTARE S.A.C. para que ésta le asista para restablecer la Contraseña.
    </li><li style="padding-bottom: 5px;">El Usuario se abstendrá de permitir que cualquier persona distinta al titular de la cuenta utilice cualquiera de los servicios a través de su cuenta. SALUTARE S.A.C. no será responsable, en ningún caso, de los servicios cuando una persona distinta al titular de la cuenta utilice los servicios. El Usuario deberá indemnizar y sacar en paz y a salvo a SALUTARE S.A.C. de cualquier procedimiento que se inicie en su contra, así como de los daños y perjuicios que deriven del incumplimiento a la presente Cláusula por parte del Usuario.
    </li><li style="padding-bottom: 5px;">Se excluye de lo señalado en el presente inciso “d)”, aquellos supuestos en los que un tercero asista al Usuario, en virtud de que el Usuario se encuentre imposibilitado de utilizar su cuenta por sí mismo. En dichos casos, se entenderá que el Usuario ha usado su cuenta personalmente, por lo que el Usuario será responsable del uso de la cuenta de conformidad con los términos y condiciones señalados en los presentes Términos y Condiciones.
    </li><li style="padding-bottom: 5px;">Los Usuarios menores de edad, pueden utilizar los Servicios únicamente con la autorización, participación e involucramiento de sus padres o tutores, quienes actuarán en carácter de representantes legales de estos, siendo responsables de cualquier obligación y/o compromiso que dichos menores de edad adquieran con SALUTARE S.A.C. En ningún caso SALUTARE S.A.C. será responsable de verificar que el Usuario sea mayor de edad o, en su caso, que el usuario cuente con la autorización de sus padres o tutores, por lo que los Usuarios menores de edad y no cuenten con la autorización de sus padres o tutores deberán abstenerse de utilizar la plataforma.
    </li><li style="padding-bottom: 5px;">El Usuario asume todos los riesgos inherentes al uso de los Medios Electrónicos por la realización de Operaciones Electrónicas, en la plataforma y/o a cualquier Medio Electrónico de SALUTARE S.A.C., según sea el caso, así como aquellas consecuencias que deriven de un mal uso de ellos.
  </li></ol>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Obligaciones de SALUTARE S.A.C. .</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE S.A.C. deberá cumplir con las siguientes obligaciones:</p>
  <ol type="a">
    <li style="padding-bottom: 5px;">Mantener controles de seguridad técnicos, físicos y administrativos para proteger en todo momento la confidencialidad e integridad de la información del Usuario, incluyendo la de sus factores de autenticación.
    </li><li style="padding-bottom: 5px;">Emplear la información de acuerdo con las finalidades descritas en su aviso de privacidad.
    </li><li style="padding-bottom: 5px;">Notificar al Usuario cada vez que se realice de forma exitosa una Operación Electrónica.
    </li><li style="padding-bottom: 5px;">Comunicar por cualquier medio que tenga disponible SALUTARE S.A.C., cualquier suspensión temporal o definitiva de la plataforma sin necesidad de otorgar alguna explicación.
    </li><li style="padding-bottom: 5px;">Brindar atención de cualquier falla o duda que tenga el Usuario respecto de la plataforma y/o cualquier Medio Electrónico de SALUTARE S.A.C., según sea el caso.
  </li></ol>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Factores de Autenticación.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Los factores de Autenticación que podrá solicitar SALUTARE S.A.C. para efectuar los Servicios en sus Medios Electrónicos, son los siguientes:</p>
  <ol type="a">
    <li style="padding-bottom: 5px;"><strong>Clase 1:</strong>  Se compone de información obtenida mediante la aplicación de cuestionarios al Usuario, por parte de operadores telefónicos, en los cuales se requieran datos que el Usuario conozca.
    </li><li style="padding-bottom: 5px;"><strong>Clase 2:</strong> Se compone de información que sólo el Usuario conozca e ingrese a través de un Dispositivo de Acceso para el módulo de Personal Sanitario, el cual es la Contraseña; (i) su longitud deberá ser de al menos ocho caracteres; (ii) la composición de estos Factores de Autenticación deberá incluir caracteres alfabéticos y numéricos, cuando el Dispositivo de Acceso lo permita conteniendo al menos una mayúscula y minúsculas; y (iii) En ningún caso se podrá utilizar como tales, la información siguiente: 1) El Identificador de Usuario; 2) El nombre comercial o denominación social de SALUTARE S.A.C.; 3) Más de dos caracteres idénticos en forma consecutiva; o 4) Más de dos caracteres consecutivos numéricos o alfabéticos.
    </li><li style="padding-bottom: 5px;"> <strong>Clase 3:</strong> Se compone de información contenida o generada por medios o dispositivos electrónicos, así como la obtenida por dispositivos generadores de contraseñas dinámicas de un sólo uso. SALUTARE S.A.C. podrá, pero no estará obligada a, brindar estos medios al Usuario, sin embargo, en caso de ser brindados por SALUTARE S.A.C. su uso será obligatorio.
    </li><li style="padding-bottom: 5px;"><strong> Clase 4:</strong> Se compone de información del Usuario derivada de sus propias características físicas, tales como huellas dactilares, geometría de la mano o patrones en iris o retina, entre otras. SALUTARE S.A.C. podrá, pero no estará obligada a, brindar estos medios al Usuario, sin embargo, en caso de ser brindados por SALUTARE S.A.C. su uso será obligatorio.
  </li></ol>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Soporte técnico.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">El Servicio de Soporte técnico únicamente se relacionará con consultas, seguimiento a los Servicios y Asistencia Técnica sobre la plataforma y, en ningún caso se entenderá que el Usuario podrá solicitar información médica o relacionada con la atención médica. En ningún caso se podrá interpretar que la información otorgada a través de Soporte Técnico tiene carácter médico, por lo que el Usuario será el único responsable sobre el uso que dé a la información obtenida a través de Soporte Técnico, sin que se pueda considerar a SALUTARE S.A.C. responsable por los daños o perjuicios que sean provocados al Usuario o a cualquier tercero con motivo del uso de Sabios.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Una vez seleccionada la opción de Soporte Técnico, el Usuario podrá enviar sus dudas a través de la plataforma de mensajería personal de la Plataforma. En caso de ser posible, el personal de SALUTARE S.A.C. responderá a las consultas y asistencia técnica solicitada por el Usuario en relación con la plataforma.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE S.A.C. no es responsable de la disponibilidad del suministro de las vacunas o cualquier atención médica relacionada con las mismas, por lo que en ningún momento garantiza ni será responsable, por la disponibilidad de dicho servicio médico.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Una vez programada la fecha de vacunación, el Usuario será el único responsable de realizar la confirmación de la cita y cualquier otro trámite o requisito para que se lleve a cabo la vacunación. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Directorio.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Los Usuarios podrán acceder al Servicio de Directorio a través de la plataforma. Este Servicio permitirá a los Usuarios consultar un directorio de locales de vacunación.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Los locales de vacunación dentro   del   Directorio son independientes a SALUTARE S.A.C., por lo que SALUTARE S.A.C. no será responsable, en ningún caso, de los Servicios brindados por los mismos, ni de los daños o perjuicios que sea provocado por los servicios que éstos brinden al Usuario.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE S.A.C. no garantiza ni será responsable en forma alguna por los servicios brindados en los locales de vacunación señalados dentro del Directorio, o bien, por su calidad y/o disponibilidad.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Vigencia.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Las disposiciones de los presentes Términos y Condiciones estarán vigentes mientras el Usuario dé uso de la plataforma y/o dentro del periodo durante el cual el Usuario cuente con una cuenta activa. Ahora bien, SALUTARE S.A.C. podrá dar por terminado el acceso a la Cuenta y a los Servicios y los presentes Términos y Condiciones respecto de cualquier Usuario, en caso de que dicho Usuario no dé cabal cumplimiento a todas y cada una de sus obligaciones previstas en los presentes Términos y Condiciones.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Terminación.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE S.A.C. puede dejar de ofrecer los Servicios a través de los Medios Electrónicos o denegar el acceso a los mismos e incluso dar por terminado en cualquier momento las disposiciones previstas en los presentes Términos y Condiciones, sin responsabilidad para SALUTARE S.A.C. y sin que esto implique un incumplimiento del Contrato de Seguro.
  </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">En caso de terminación, el Usuario deberá de dejar de hacer uso de la plataforma.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">En caso de cancelación a voluntad del Usuario, la solicitud de cancelación se llevará a cabo a través de la plataforma siguiendo el flujo que en la misma se indique. A partir del momento de la cancelación, el Usuario dejará de tener acceso a los Servicios a través de Medios Electrónicos.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Sin perjuicio de lo anterior, la terminación de los presentes Términos y Condiciones, no afectará los derechos y obligaciones que surjan antes de la fecha de terminación.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE S.A.C. podrá terminar los presentes Términos y Condiciones y restringir el uso de la plataforma por parte del Usuario, en caso de que el Usuario incumpla con cualquiera de las obligaciones señaladas en los presentes Términos y Condiciones.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Fallas en la Plataforma.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE S.A.C. no se responsabiliza por ningún daño, perjuicio o pérdida al Usuario que pudiera ocasionarse por fallas en la plataforma, en el servidor o en Internet. SALUTARE S.A.C. tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso o uso de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos o audio contenidos en el mismo.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">El Usuario no podrá exigirle responsabilidad alguna a SALUTARE S.A.C., ni podrá exigir el pago de ninguna cantidad por concepto de daño, perjuicio o pérdida resultante de dificultades técnicas o fallas en la plataforma o en Internet. SALUTARE S.A.C. no garantiza el acceso y uso continuado o ininterrumpido a la plataforma, debido a que éste puede eventualmente no estar disponible debido a dificultades técnicas, fallas de Internet, mantenimiento o por causas ajenas a SALUTARE S.A.C.; en tales casos se procurará restablecerlo con la mayor celeridad posible, sin que por ello pueda imputarse algún tipo de responsabilidad a SALUTARE S.A.C. De la misma manera, SALUTARE S.A.C.  tampoco será responsable por ningún error u omisión contenidos en la plataforma. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">La plataforma puede contener enlaces a sitios web de terceros. En dichos casos, SALUTARE S.A.C.  no será responsable de ninguna manera del contenido, productos, servicios, anuncios u otra información que pueda ser proporcionada por o a través de tales sitios o publicidad, ni de sus políticas de privacidad o su cumplimiento con las disposiciones legales aplicables. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Limitación de Responsabilidad.
    </strong> 
  </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE S.A.C.  únicamente pone a disposición de los Usuarios la plataforma para tener acceso a los Servicios, por lo que no será responsable y en ningún caso garantiza el contenido, calidad, cantidad, estado, seguridad, idoneidad, integridad o legitimidad de los Servicios. En consecuencia, SALUTARE S.A.C.  no será responsable de cualquier daño, menoscabo, pérdida o perjuicio, relacionado con la calidad, integridad, legitimidad, o cualquier otra causa análoga a los Servicios. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> <strong>Indemnización.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> El Usuario acuerda y se obliga a indemnizar y a dejar y a salvo a SALUTARE S.A.C.  de cualquier reclamación que pudiera hacerse en su contra con motivo o en relación con el uso de la Cuenta. En razón de lo anterior, el Usuario acuerda indemnizar y eximir de responsabilidad a SALUTARE S.A.C.  (incluyendo, pero no limitado a sus respectivos accionistas, directores, gerentes, funcionarios, representantes, agentes y empleados) por cualquier reclamo o demanda (incluyendo honorarios y costos legales), obligándose a resarcir a SALUTARE S.A.C.  de cualquier cantidad que se erogare por estos conceptos.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Relación.</strong> </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Ninguna disposición contemplada en estos Términos y Condiciones constituye de manera alguna cualquier tipo de sociedad, asociación, comisión mercantil o relación laboral entre SALUTARE S.A.C.  y el Usuario. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> <strong>Propiedad Intelectual.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> La plataforma y todos los derechos relativos a éste, son propiedad exclusiva de SALUTARE S.A.C.  Ninguna disposición prevista en los Términos y Condiciones, ni el uso de la plataforma otorgan o transfieren ningún derecho sobre o en relación con la plataforma.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Todos los derechos de propiedad intelectual relacionados con los Servicios, incluyendo sin limitar a, todas las patentes, modelos de utilidad, diseños industriales, marcas, avisos comerciales, nombres comerciales, derechos de autor, secretos industriales, know-how, tecnología o cualquier solicitud para obtener el registro de cualquiera de las anteriores, así como sus registros, permisos, licencias o cualquier otro derecho que derive de la prestación de los Servicios, son y serán, en todo momento, propiedad de SALUTARE S.A.C. Los Términos y Condiciones no otorgan al Usuario, en ningún caso, derechos sobre la Propiedad Intelectual de SALUTARE S.A.C. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">La plataforma y sus contenidos (textos, fotografías, gráficos, imágenes, tecnología, software, links, contenidos, diseño gráfico, código fuente, etc.), así como las marcas y demás signos distintivos son propiedad de SALUTARE S.A.C.  o de terceros, no adquiriendo el Usuario ningún derecho sobre ellos por el mero uso de la plataforma. El Usuario, deberá abstenerse de: </p>
  <ol type="a">
    <li style="padding-bottom: 5px;"> Reproducir, copiar, distribuir, poner a disposición de terceros, comunicar públicamente, 
        transformar o modificar la plataforma o sus contenidos, salvo en los casos contemplados en la expresamente autorizados por SALUTARE S.A.C.  o por el titular de dichos derechos. 
    </li><li style="padding-bottom: 5px;"> Reproducir o copiar para uso privado la plataforma o sus contenidos, así como comunicarlos públicamente o ponerlos a disposición de terceros cuando ello conlleve su reproducción, o
    </li><li style="padding-bottom: 5px;"> Extraer o reutilizar todo o parte sustancial de los contenidos integrantes de la plataforma.
    </li>
  </ol>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Confidencialidad.</strong> </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Toda la información proporcionada por los Usuarios será tratada como información confidencial y no podrá ser revelada sin el consentimiento del Usuario. Esta obligación de confidencialidad no cesará con motivo de la expiración, suspensión, terminación o rescisión de los presentes Términos y Condiciones. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Tendrán acceso a dicha información aquellos empleados o representantes de SALUTARE S.A.C., así como terceros con los que SALUTARE S.A.C.  tenga una relación jurídica para el cumplimiento de las obligaciones conforme a los presentes Términos y Condiciones. No obstante, SALUTARE S.A.C.  podrá proporcionar información mediante requerimiento fundado y motivado de autoridad competente. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> <strong>Privacidad.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> El tratamiento de los datos personales que se lleve a cabo en relación con los Servicios, se regirá conforme a lo establecido en el Aviso de Privacidad de SALUTARE S.A.C. , el cual se encuentra disponible en <a href=""> el siguiente link</a>, en cumplimiento a lo establecido por la Ley de Protección de Datos Personales, su Reglamento y las demás disposiciones legales aplicables.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> De conformidad con el Aviso de Privacidad de SALUTARE S.A.C. , la información y datos personales que se obtengan durante y derivado de la prestación de los Servicios podrá ser utilizada con fines de investigación médica, científica, tecnológica y/o para la salud.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> El consentimiento para el tratamiento de los datos personales sensibles se obtendrá al momento de aceptar los presentes Términos y Condiciones.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Modificaciones.</strong> </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> SALUTARE S.A.C.  podrá modificar los Términos y Condiciones en cualquier momento, para lo cual, SALUTARE S.A.C.  podrá informar a los Usuarios a través de una publicación en su página web, la plataforma y/o a través de Correo Electrónico sobre dichas modificaciones. SALUTARE S.A.C.  elegirá el medio de notificación a los Usuario a su entera discreción, bajo el entendido que la notificación se entenderá hecha cuando 
  se realice por cualquiera de los medios señalados en el presente párrafo. Sin perjuicio de lo anterior, SALUTARE S.A.C.  podrá publicar las actualizaciones o modificaciones a los Términos y Condiciones en la plataforma, en el entendido de que la sola utilización de la plataforma, implica la aceptación expresa por parte del Usuario de cualquier actualización o modificación de los Términos y Condiciones. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> <strong>Enlaces a otros sitios web</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"> Este sitio web puede contener enlaces a sitios web de terceros. Estos enlaces se proporcionan únicamente para su comodidad y no como un respaldo o recomendación por parte de la Plataforma Vacuna para Todos del contenido en dichos sitios web de terceros o como una indicación de cualquier afiliación, patrocinio o respaldo de o por dichos sitios web de terceros. Si utiliza algún enlace, abandonará el sitio web de la Plataforma Vacuna para Todos.  SALUTARE S.A.C.  no se hace responsable del contenido de los sitios web de terceros vinculados y no hace ninguna declaración con respecto a las prácticas de privacidad de, o cualquier información o producto o exactitud de los materiales encontrados en dichos sitios web de terceros. Si decide acceder a cualquiera de los sitios web de terceros vinculados a este sitio web, lo hace bajo su propio riesgo.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Domicilio</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 2rem;">El domicilio de SALUTARE S.A.C.  se encuentra ubicado en:</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 2rem;">Correo Electrónico: </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Jurisdicción y Ley Aplicable.</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Para todo lo relativo a la interpretación y cumplimiento de estos Términos y Condiciones, las partes en este acto se someten, de manera expresa e irrevocable, a las leyes aplicables en la República del Perú y a la jurisdicción de los tribunales competentes de la Ciudad de Lima, Perú, y renuncian, de manera expresa e irrevocable, a cualquier otra jurisdicción que pudiere llegar a corresponderles en virtud de sus domicilios presentes o futuros o por cualquier otra razón.</p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Consentimiento informado</strong></p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Al presionar el botón de «ACEPTAR», usted como Usuario reconoce que SALOG/SALUTARE no se hace responsable de retrasos en la prestación médica, consultas o tratamientos debido a deficiencia o fallas en el equipo y/o conectividad al internet. SALUTARE S.A.C.  no se hace responsable de interacciones adversas de las vacunas, medicamentos, reacciones alérgicas u otros resultados negativos debido a la falta de información o información errónea en el expediente de salud personal del paciente. Usted se compromete a luego de ser evaluado y utilizar el servicio a asistir a su médico primario para el seguimiento respectivo. </p>
  <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Usted asegura haber leído y comprendido la información anteriormente provista, y comprende los riesgos y beneficios de usar la Plataforma Vacuna para Todos, y al presionar el botón de aceptar o su equivalente, estos Términos y Condiciones brinda su consentimiento informado de participar en el plan de vacunación bajo los términos aquí descritos.</p>
  <hr/>
`;

export default function TermsHtml() {
  return (
    <div 
      className="custom-scrollbar"
      style={{ overflowY: 'auto', height: '500px', border: '2px solid #EFEFEF', fontFamily: 'Roboto, Helvetica' }} dangerouslySetInnerHTML={{__html: htmlContent}} />
  )
}
