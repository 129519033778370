import React from "react";

const htmlContent =`
<h1 style="color: #212529; text-align: center; margin-top: 20px; font-size: 1.5rem;">AVISO DE PRIVACIDAD </h1>
<p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
   En cumplimiento de la Ley N° 29733 – Ley de Protección de Datos Personales, y su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS, usted declara haber sido informado por <strong>[SALUTARE S.A.C.]</strong>, sobre el tratamiento de mis datos personales, con el detalle siguiente:
</p>
<h2 style="color: #212529; margin-top: 20px; font-size: 1.2rem;    margin-left: 1rem;">Introducción: </h2>
<p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Para efectos de la gestión y administración de la Plataforma “Vacuna para Todos”, la cual ha sido implementada para fines del Servicio y la aplicación de las Vacunas por el Asegurador, SALUTARE requiere tratar sus datos personales, incluyendo pero no limitándose a su documento de identidad, fecha de nacimiento, seguro médico, dirección domiciliaria, número de celular, profesión, datos de salud, historial clínico, triaje, prueba de COVID-19, información sobre la aplicación de las Vacunas. En ese sentido, el presente documento tiene como finalidad proporcionar los lineamientos bajo los cuales esto ocurrirá.</p>
<h2 style="color: #212529; margin-top: 20px; font-size: 1.2rem;    margin-left: 1rem;">Encargo de Tratamiento de Datos Personales:  </h2>
<p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE tratará sus datos personales por encargo del Asegurador. Es decir, SALUTARE realizará el tratamiento de datos personales en nombre, por cuenta, bajo lo expresamente permitido y siguiendo las instrucciones del Asegurador. Para estos efectos, trataremos datos personales sobre usted que nos sean proporcionados por el Asegurador o que obtengamos de usted por encargo del mismo. Sus datos personales pertenecen al banco de datos de titularidad del Asegurador.</p>
<h2 style="color: #212529; margin-top: 20px; font-size: 1.2rem;    margin-left: 1rem;">Finalidades:  </h2>
<ol type="i" start="1">
   <li style="padding-bottom: 5px;">Gestionar y administrar la Plataforma “Vacuna para Todos”.
   </li><li style="padding-bottom: 5px;">Usar, brindar y/o transferir esta información para dar cumplimiento a las obligaciones y/o requerimientos de índole sanitaria y afín, que se generen en virtud de las normas vigentes en el ordenamiento jurídico peruano. 
   </li><li style="padding-bottom: 5px;">Usar, brindar y/o transferir esta información a los proveedores de servicios de salud o afines para el uso de la plataforma en proceso de vacunación, así como a autoridades y terceros autorizados por ley.  
   </li><li style="padding-bottom: 5px;">Cumplir con fines estadísticos e históricos conforme a ley, por cuestiones de salud pública.
   </li><li style="padding-bottom: 5px;">Efectuar encuestas sobre los servicios relacionados con el tratamiento recibido a través de las vacunas.
</li></ol>
<h2 style="color: #212529; margin-top: 20px; font-size: 1.2rem;    margin-left: 1rem;">Transferencias y destinatarios:  </h2>
<p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Sus datos personales podrán ser transferidos a servidores de compañías de tecnologías de la información como Amazon Web Services, ubicados en Virginia, Estados Unidos de Norte América, a fin de gestionar el almacenamiento y gestión de la Plataforma “Vacuna para Todos”.</p>
<h2 style="color: #212529; margin-top: 20px; font-size: 1.2rem;    margin-left: 1rem;">Plazo de conservación: </h2>
<p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">SALUTARE tendrá derecho a utilizar sus datos personales en tanto sea necesario para cumplir con las finalidades indicadas en el presente documento. Los datos de su historial clínico, serán conservados por los plazos legales establecidos. Los datos de contacto serán conservados mientras no solicite su derecho de supresión o baja.</p>
<h2 style="color: #212529; margin-top: 20px; font-size: 1.2rem;    margin-left: 1rem;">Seguridad y Confidencialidad: </h2>
<p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Sus datos personales serán tratados de forma confidencial y con los niveles de seguridad requeridos por ordenamiento legal vigente.
</p>
<h2 style="color: #212529; margin-top: 20px; font-size: 1.2rem;    margin-left: 1rem;">Derechos ARCO: </h2>
<p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">En caso de ser requeridos, colaboraremos para que usted pueda ejercer sus derechos en materia de protección de datos personales. Usted cuenta con sus derechos de acceso, actualización, inclusión, rectificación, supresión y oposición, respecto de mis datos personales en los términos previstos en la Ley N° 29733 – Ley de Protección de Datos Personales, y su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS. 
</p>
<h2 style="color: #212529; margin-top: 20px; font-size: 1.2rem;    margin-left: 1rem;">Restricciones:  </h2>
<p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">El tratamiento de sus datos personales se encuentra limitado exclusivamente al cumplimiento de las finalidades previstas en el presente documento. <br><br>
   Asimismo, declara haber sido informado de que en ningún caso podrá limitar los datos personales brindados cuando estos sean necesarios para el desarrollo y cumplimiento de las actividades que de acuerdo a ley no requieren del consentimiento del usuario, como en el caso de la vigilancia sanitaria y afines. De no aceptar el tratamiento de mis datos personales, su tratamiento se limitará al que corresponda conforme a ley, en aquellos casos que no requieren del consentimiento previo del titular. <br><br>
   Luego de haber leído y comprendido, ACEPTO proporcionar mis datos, con el objetivo de ser utilizados para los fines antes señalados. Además, ACEPTO que mi consentimiento quedará registrado haciendo <a href="#">click</a>  en el botón de “aceptar” o “confirmar” contenido en este aplicativo.
</p>
<hr>
`;
// const htmlContent = `
// <h1 style="color: #212529; text-align: center; margin-top: 20px; font-size: 1.5rem;">TRATAMIENTO Y PROTECCIÓN DE DATOS PERSONALES</h1>
// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
//     Declaro que he sido informado por [SALUTARE S.A.C.] como titular del Banco de Datos Personales [“pacientes”] sobre el tratamiento de mis datos personales, con el detalle siguiente:
// </p>

// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Finalidad: </strong></p>

// <ol type="i" start="1">
//     <li style="padding-bottom: 5px;">Usar, brindar y/o transferir esta información para dar cumplimiento a las obligaciones y/o requerimientos de índole sanitaria y afín, que se generen en virtud de las normas vigentes en el ordenamiento jurídico peruano. 
//     </li><li style="padding-bottom: 5px;">Usar, brindar y/o transferir esta información a los proveedores de servicios de salud o afines, así como a autoridades y terceros autorizados por ley. 
//     </li><li style="padding-bottom: 5px;">Cumplir con fines estadísticos e históricos conforme a ley, por cuestiones de salud pública.
//     </li><li style="padding-bottom: 5px;">Efectuar encuestas sobre los servicios relacionados con el tratamiento recibido a través de las vacunas.


// </li></ol>
// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Objeto.</strong></p>
// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
//     El Usuario a través de la Plataforma Vacuna para Todos podrá acceder a los Servicios y recibir información relacionada con los mismos, conforme a los presentes Términos y Condiciones. 
// </p>
// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
//     SALUTARE S.A.C. se reserva el derecho de ampliar o disminuir el número de Operaciones Electrónicas, sin embargo, dichas modificaciones deberán de ser publicadas en la Plataforma de SALUTARE S.A.C. para conocimiento del Usuario.
// </p>
// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
//     SALUTARE S.A.C. se reserva el derecho de rechazar cualquier solicitud de Cuenta, cancelar o suspender una Cuenta, sin que esté obligado a comunicar las razones de ello y sin que ello genere alguna responsabilidad para SALUTARE S.A.C.
// </p>
// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
//     En ningún momento se entenderá que la presentación por parte del Usuario de la solicitud debidamente llenada, así como la aceptación de los presentes Términos y Condiciones constituye la aceptación del riesgo por parte de SALUTARE S.A.C., sin que ello genere responsabilidad alguna para SALUTARE S.A.C.
// </p>
// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">
//     <strong>Plazo de conservación: </strong>Los datos de mi historial clínico, serán conservados por los plazos legales establecidos. Los datos de contacto serán conservados mientras no solicite su derecho de supresión o baja.
// </p>

// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Seguridad y Confidencialidad: </strong>Los datos personales que facilite serán tratados de forma confidencial, de acuerdo al ordenamiento legal vigente.</p>


// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Derechos: </strong>declaro estar informado que puedo ejercer mis derechos de acceso, actualización, inclusión, rectificación, supresión y oposición, respecto de mis datos personales en los términos previstos en la Ley N° 29733 – Ley de Protección de Datos Personales, y su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS. </p>

// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;"><strong>Restricciones:</strong>El tratamiento de mis datos personales se encuentra limitado exclusivamente al cumplimiento de las finalidades previstas en el apartado inmediato anterior.
// Asimismo, he sido informado de que en ningún caso podrá limitar los datos personales brindados cuando estos sean necesarios para el desarrollo y cumplimiento de las actividades que de acuerdo a ley no requieren del consentimiento del usuario, como en el caso de la vigilancia sanitaria y afines. De no aceptar el tratamiento de mis datos personales, su tratamiento se limitará al que corresponda conforme a ley, en aquellos casos que no requieren del consentimiento previo del titular.
// </p>

// <p style="text-align: initial; font-size: 1rem;padding-left: 1rem;">Luego de haber leído y comprendido, ACEPTO proporcionar mis datos, con el objetivo de ser utilizados para los fines antes señalados. Además, ACEPTO que mi consentimiento quedará registrado haciendo click en el botón de “aceptar” o “confirmar” contenido en este aplicativo.</p>

// <hr/>
// `

export default function PoliticsHtml() {
  return (
    <div 
      className="custom-scrollbar"
      style={{ overflowY: 'auto', height: '500px', border: '2px solid #EFEFEF', fontFamily: 'Roboto, Helvetica' }} dangerouslySetInnerHTML={{__html: htmlContent}} />
  )
}
