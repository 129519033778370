import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useRouteMatch, Route, Switch } from 'react-router-dom'

import ListPage from './sideeffects/List'
import FormPage from './sideeffects/Form'
import Box from '../../components/box'

const SideEffects = () => {
  const { path } = useRouteMatch()

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" component="h2" className="title-head" gutterBottom>
          Reacciones adversas
        </Typography>
        <Typography variant="body1" component="h2" className="title-head">
          Registra si tuviste algún tipo de malestar después de la vacunación
        </Typography>
      </Box>
      <Switch>
        <Route exact path={path}>
          <ListPage />
        </Route>
        <Route exact path={`${path}/registro`}>
          <FormPage />
        </Route>
      </Switch>
    </Box>
  )
}

export default SideEffects
