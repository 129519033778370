import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useRouteMatch, Route, Switch } from 'react-router-dom'

import InformationPage from './information/Information'
import InformationDetail from './information/InformationDetail'
import Box from '../../components/box'
import { borderRadius } from '@material-ui/system'

const Information = () => {
  const { path } = useRouteMatch()

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" component="h2" className="title-head" gutterBottom>
          Info vacunas
        </Typography>
        <Box mb={3} mt={2} px={3} pt={3} pb={3} style={{ background: '#e9ff204d', borderRadius: '10px', }}>
        &quot; Información actualizada de acuerdo a la Directiva Sanitaria No. 129- MINSA/2021/DGIESP: 
        Directiva Sanitaria para la Vacunación contra la COVID-19 en la situación de emergencia 
        sanitaria por la pandemia en Perú&quot;
        </Box>
        <Typography variant="body1" component="h2" className="title-head">
          Todo lo que debes saber sobre las vacunas
        </Typography>
        <Switch>
          <Route exact path={path}>
            <InformationPage />
          </Route>
          <Route path={`${path}/:code`}>
            <InformationDetail />
          </Route>
        </Switch>
      </Box>
    </Box>
  )
}

export default Information
