import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { useHistory, Redirect } from 'react-router-dom'

import Box from '../../../components/box'
import Context from '../../../Context'

const Program = () => {
  const { dashboardSession, setDashboardSession } = useContext(Context)
  const history = useHistory()

  const handleProgram = (event) => {
    event.preventDefault()
    const session = {
      read_program: true,
      lastPathname: '/afiliacion/encuesta',
    }
    setDashboardSession(session)
    history.push({ pathname: '/afiliacion/encuesta' })
  }

  if (dashboardSession?.lastPathname) {
    return <Redirect to={dashboardSession.lastPathname} />
  }

  return (
    <Container maxWidth="md">
      <Box
        style={{ background: '#FFFFFF', boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)' }}
        border="2px #F3F1F18C solid"
        borderTop="4px #2306DE solid"
        borderRadius={10}
        borderColor="primary"
        mt={4}
        px={2}
        py={4}>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justify="center">
          <Icon>assignment</Icon>
          <Box ml={2}>
            <Typography variant="h5" component="h3">
              Programa de Vacunación COVID-19
            </Typography>
          </Box>
        </Grid>
        <Box
          my={4}
          p={2}
          height={350}
          border="2px solid #EFEFEF"
          className="custom-scrollbar"
          style={{ overflowY: 'auto' }}>
          <Typography variant="body2" gutterBottom>
            Te damos la bienvenida a Vacuna para Todos.
          </Typography>
          <Typography variant="body2" gutterBottom>
            En esta parte de la plataforma podrás realizar las siguientes actividades:
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ul>
              <li>Inscripción para que puedas vacunarte contra el COVID-19.</li>
              <li>Podrás ver tu punto de vacunación.</li>
              <li>Podrás gestionar tu próxima dosis de vacunación COVID-19.</li>
              <li>Podrás reportar reacciones adversas.</li>
              <li>•	Y finalmente podrás tener acceso a la información dada por el gobierno en relación a las vacunas aprobadas.</li>
            </ul>
          </Typography>
        </Box>
        <Grid container justify="center">
          <Button variant="contained" color="primary" onClick={handleProgram}>
            Continuar
          </Button>
        </Grid>
      </Box>
    </Container>
  )
}

export default Program
