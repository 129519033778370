import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { toast } from 'react-toastify'

import Box from '../../components/box'
import Context from '../../Context'
import ModalAddress from './profile/Address'
import ModalEmail from './profile/Email'
import ModalEmailVerify from './profile/EmailVerify'
import ModalPhone from './profile/Phone'
import ModalPhoneVerify from './profile/PhoneVerify'

const useStyles = makeStyles((theme) => ({
  container: {
    borderTopStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    padding: theme.spacing(1.5, 2),
    backgroundColor: '#FFF',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderTopWidth: 4,
      padding: theme.spacing(3, 4),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const ModalText = {
  address: 'Editar Dirección',
  email: 'Editar Correo Electrónico',
  emailVerify: 'Valida tu correo',
  phone: 'Editar Número Telefónico',
  phoneVerify: 'Valida tu número',
}

const Profile = () => {
  const classes = useStyles()
  const { ubigeo, userAuth, setUserAuth } = useContext(Context)

  const getProfile = useCallback(
    async (force) => {
      try {
        if (!userAuth?.token || (userAuth?.created_at && !force)) return
        const toastRef = force
          ? toast.info('Actualizando', { autoClose: false })
          : null

        const { data: response } = await axios.get(
          `${process.env.REACT_APP_BASE_URL_CITIZEN}/user`,
          {
            headers: {
              Authorization: `Bearer ${userAuth.token}`,
            },
          },
        )
        if (!response.success) throw new Error(response.error?.message)
        if (toastRef) {
          toast.update(toastRef, {
            render: 'Perfil actualizado',
            type: 'success',
            autoClose: 3000,
          })
        }
        const user = { ...userAuth, ...response.data }
        setUserAuth(user)
      } catch (err) {
        console.log(err)
        if (err?.response?.status === 401) {
          setUserAuth(null, 'expired')
        }
      }
    },
    [userAuth, setUserAuth],
  )

  useEffect(() => {
    getProfile()
  }, [getProfile])

  const userUbigeo = useMemo(() => {
    if (!userAuth?.ubigeo) return {}
    const location = ubigeo?.ubigeos?.find(
      (location) => location.code === userAuth.ubigeo,
    )
    if (!location) return {}
    return location
  }, [userAuth?.ubigeo, ubigeo])

  const [modal, setModal] = useState({})

  const closeModal = useCallback(() => {
    setModal({})
  }, [])

  const successModal = useCallback(() => {
    setModal({})
    getProfile(true)
  }, [getProfile])

  const lastnameConcat = (lastname1, lastname2, lastname3) => {
    var l1=lastname1==undefined || lastname1==''?'':lastname1+' ';
    var l2=lastname2==undefined || lastname1==''?'':lastname2+' ';
    var l3=lastname3==undefined || lastname1==''?'':lastname3+' ';

    return (l1+l2+l3).trim();
  }

  const getModalContent = useCallback(() => {
    switch (modal.name) {
      case 'address': {
        return <ModalAddress onSuccess={successModal} onClose={closeModal} />
      }
      case 'email': {
        return (
          <ModalEmail
            onSuccess={({ email }) => {
              setModal({ name: 'emailVerify', props: { email } })
            }}
            onClose={closeModal}
          />
        )
      }
      case 'emailVerify': {
        return (
          <ModalEmailVerify
            onSuccess={successModal}
            onClose={closeModal}
            email={modal.props.email}
          />
        )
      }
      case 'phone': {
        return (
          <ModalPhone
            onSuccess={({ phone }) => {
              if (userAuth.phone !== phone) {
                setModal({ name: 'phoneVerify', props: { phone } })
              } else {
                successModal()
              }
            }}
            onClose={closeModal}
          />
        )
      }
      case 'phoneVerify': {
        return (
          <ModalPhoneVerify
            onSuccess={successModal}
            onClose={closeModal}
            phone={modal.props.phone}
          />
        )
      }
      default:
        return null
    }
  }, [closeModal, modal, successModal, userAuth])




  const getContent = useCallback(() => {



    const getDocumentType = (documentId) => {
      const documents = [
        {
          value: 'dni',
          label: 'DNI',
        },
        {
          value: 'ce',
          label: 'Carné de extranjería',
        },
        {
          value: 'pass',
          label: 'Pasaporte',
        },
        {
          value: 'ext',
          label: 'Documento de identidad extranjero',
        },
      ]

      return documents.find(doc => doc.value == documentId)?.label;
    };


    if (!userAuth?.created_at) {
      return (
        <Box my={4} style={{ textAlign: 'center' }}>
          <CircularProgress size={24} color="primary" />
        </Box>
      )
    }

    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Box className={classes.container}>
              <Typography variant="h6" gutterBottom>
                <b>Datos Personales</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Nombre:</b>
                <br />
                {userAuth.name}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Apellidos:</b>
                <br />
                {lastnameConcat(userAuth.lastname_1,userAuth.lastname_2,userAuth.lastname_3)}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Género:</b>
                <br />
                {userAuth.gender}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Tipo de documento:</b>
                <br />
                {getDocumentType(userAuth.credential_type)}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Número de documento:</b>
                <br />
                {userAuth.user}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Fecha de nacimiento:</b>
                <br />
                {userAuth.birthdate}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className={classes.container}>
              <Typography variant="h6" gutterBottom>
                <b>Dirección</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Departamento:</b>
                <br />
                {userUbigeo.department}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Provincia:</b>
                <br />
                {userUbigeo.province}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Distrito:</b>
                <br />
                {userUbigeo.district}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Dirección:</b>
                <br />
                {userAuth.address}
              </Typography>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setModal({ name: 'address' })
                  }}>
                  Actualizar
                </Button>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className={classes.container}>
              <Typography variant="h6" gutterBottom>
                <b>Número Telefónico</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Teléfono fijo:</b>
                <br />
                {userAuth.city_code || ''}{' '}
                {userAuth.telephone || 'No especificó'}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Número de celular:</b>
                <br />
                {userAuth.phone}
              </Typography>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setModal({ name: 'phone' })
                  }}>
                  Actualizar
                </Button>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className={classes.container}>
              <Typography variant="h6" gutterBottom>
                <b>Correo Electrónico</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <b>Correo electrónico:</b>
                <br />
                {userAuth.email || 'No especificó'}
              </Typography>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setModal({ name: 'email' })
                  }}>
                  Actualizar
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }, [classes, userAuth, userUbigeo])

  return (
    <Box>
      <Box mb={2}>
        <Typography
          variant="h5"
          component="h2"
          className="title-head"
          gutterBottom>
          Mi Perfil
        </Typography>
        <Typography variant="body1" component="h2" className="title-head">
          Te invitamos a modificar y/o actualizar tu perfil
        </Typography>
      </Box>
      {getContent()}
      <Dialog open={!!modal.name} onClose={closeModal} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6">{ModalText[modal.name]}</Typography>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            onClick={closeModal}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{getModalContent()}</DialogContent>
      </Dialog>
    </Box>
  )
}

export default Profile
