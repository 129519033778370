// developed for Franz Moreno based in Yup https://github.com/jquense/yup

const validations = {
  email: (value = '', message) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
      throw new Error(message || 'Información invalida')
    }
  },
  length: (value, limit, message) => {
    if (value && value.length !== limit) {
      throw new Error(message || `Debe contener ${limit} caracteres`)
    }
  },
  matches: (value, regex, message) => {
    if (!value?.match(regex)) {
      throw new Error(message)
    }
  },
  max: (value, max, message) => {
    if (value && value.length > max) {
      throw new Error(message || `Máximo ${max} caracteres`)
    }
  },
  min: (value, min, message) => {
    if (value && value.length < min) {
      throw new Error(message || `Mínimo ${min} caracteres`)
    }
  },
  required: (value, message) => {
    if (value === true) {
      value = value 
    } else {
      if (value !== false) {
        if (value !== null) {
          value = value.trim()
        }
      }
    }
    if (!value) throw new Error(message || 'Información requerida')
  },
}

export class ValidationError {
  constructor() {
    this.inner = {}
    this.length = 0
  }

  setError(code, message) {
    this.inner[code] = message
    this.length++
    return this
  }
}

class BooleanSchema {
  constructor() {
    this.rules = []
  }

  setRules(name, options) {
    this.rules = [[name, options], ...this.rules]
  }
  required(...params) {
    this.setRules('required', params)
    return this
  }
}

class StringSchema {
  constructor() {
    this.rules = []
  }

  setRules(name, options) {
    this.rules = [[name, options], ...this.rules]
  }
  email(...params) {
    this.setRules('email', params)
    return this
  }
  length(...params) {
    this.setRules('length', params)
    return this
  }
  matches(...params) {
    this.setRules('matches', params)
    return this
  }
  min(...params) {
    this.setRules('min', params)
    return this
  }
  max(...params) {
    this.setRules('max', params)
    return this
  }
  required(...params) {
    this.setRules('required', params)
    return this
  }
}

class ObjectSchema {
  constructor() {
    this.fields = {}
  }

  shape(fields) {
    this.fields = fields
    return this
  }
  validate(data) {
    return new Promise((resolve, reject) => {
      const errors = new ValidationError()
      const fields = Object.keys(this.fields)
      fields.forEach((field) => {
        const value = data[field]
        const rules = this.fields[field].rules
        rules.find(([rule, params]) => {
          try {
            const _rule = validations[rule]
            _rule(value, ...params)
            return false
          } catch (err) {
            errors.setError(field, err.message)
            return true
          }
        })
      })
      if (errors.length > 0) return reject(errors)
      resolve(true)
    })
  }
}

export const boolean = () => new BooleanSchema()
export const string = () => new StringSchema()
export const object = () => new ObjectSchema()
