import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Box from '../../../components/box'
import Context from '../../../Context'

const useStyles = makeStyles((theme) => ({
  container: {
    borderTopStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    padding: theme.spacing(1.5, 2),
    marginTop: '1rem',
    backgroundColor: '#FFF',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderTopWidth: 4,
      padding: theme.spacing(3, 4),
    },
  },
  code: {
    backgroundColor: '#000',
    width: 150,
    height: 150,
    margin: theme.spacing(0.5, 'auto', 1),
  },
  button: {
    margin: theme.spacing(1, 'auto'),
    textAlign: 'center',
    width: 150,
  },
}))

const InformationDetail = () => {
  const classes = useStyles()
  const { userAuth, setUserAuth, setActiveMenu } = useContext(Context)

  const history = useHistory()
  const { code } = useParams()

  const [infoDetail, setInfoDetail] = useState()

  const getInfoDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/vaccination/one?code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      if (!data) {
        throw new Error('Info not found')
      }
      setInfoDetail(data)
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      } else {
        setInfoDetail('error')
      }
    }
  }, [code, userAuth, setUserAuth])

  useEffect(() => {
    setActiveMenu('/info-vacunas')
    getInfoDetail()
  }, [getInfoDetail, setActiveMenu])

  const handleBack = (event) => {
    event.preventDefault()
    history.push({ pathname: '/info-vacunas' })
  }

  if (!infoDetail) {
    return (
      <Box my={4} style={{ textAlign: 'center' }}>
        <CircularProgress size={24} color="primary" />
      </Box>
    )
  }

  if (infoDetail === 'error') {
    return (
      <Box my={4}>
        <Typography variant="body1" align="center">
          Detalle de vacuna no encontrado
        </Typography>
      </Box>
    )
  }

  return (
    <Container className={classes.container} maxWidth="md">
      <Box>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justify="center"
          spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <div
                dangerouslySetInnerHTML={{ __html: infoDetail.description }}
              />
            </Typography>
          </Grid>
          <Box className={classes.button}>
            <Button variant="contained" onClick={handleBack} fullWidth>
              Volver
            </Button>
          </Box>
        </Grid>
      </Box>
    </Container>
  )
}

export default InformationDetail
