import React from "react";

const htmlContent = `
<body>
<h1 style="color: #212529; text-align: center; margin-top: 20px; font-size: 1.5rem;">ANEXO Nº 2:</h1>
<h2 style="color: #212529; text-align: center; margin-top: 20px; font-size: 1.5rem;">Formato de Consentimiento Informado para la Vacunación contra la COVID-19</h2>

    <h2 style="color: #212529; text-align: center; margin-top: 20px; font-size: 1.5rem;">HOJA INFORMATIVA SOBRE LA VACUNA CONTRA LA COVID-19</h2>
<p style="text-align: initial; font-size: 1rem;   padding-left: 1rem;">
La pandemia ocasionada por la COVID-19 ha producido hasta el momento, más de 113 millones de casos y más de 2,5 millones de muertes a lo largo de todo el mundo. La COVID-19 es la enfermedad producida por un nuevo coronavirus, llamado SARS CoV-2, aparecido en China en diciembre del 2019. Se estima que el 85% de los casos de infección por este virus presentarán síntomas leves, un 15% síntomas moderados y un 5% síntomas severos que pueden llevar a la muerte.
<br> <br>
Desde la identificación del virus causante de la pandemia se han ido desarrollando diversas vacunas contra la COVID-19 y algunas ya se encuentran disponibles para su uso en el contexto de la emergencia sanitaria.
<br> <br>
La vacunación es la principal herramienta para la prevención de la COVID-19 y se espera que cuando la mayoría de la población se encuentre vacunada (entre el 70-85%), la transmisión del virus en la comunidad sea mínima.
<br> <br>
Las vacunas contra la COVID-19 reducen significativamente  la posibilidad  de presentar síntomas o complicaciones a causa de la infección por el SARS-CoV-2.
<br> <br>
Se le está ofreciendo a usted una vacuna, aprobada por el Ministerio de Salud, contra laCOVID-19. Las características de la vacuna, el procedimiento para la vacunación, así como los beneficios y los riesgos de esta, serán informados y explicados por el personal de salud a cargo. Luego de ello, usted decidirá voluntariamente continuar con el proceso de vacunación.

<br> <br>
De manera general, la mayoría de los eventos adversos presentados por los vacunados se localizan en el lugar de la inyección: dolor, ligera hinchazón, enrojecimiento. Se han reportado algunas reacciones sistémicas como dolor de cabeza, malestar general, dolores musculares o cansancio. Estas reacciones se resuelven entre 48 a 72 horas después de la vacunación.

<br> <br>
Posterior   a   recibir   la   vacuna,   usted   se   quedará   30   minutos   en   observación,   para posteriormente retirarse.
<br> <br>
Se le hará entrega de una cartilla, donde se registra la vacunación y que deberá conservar para dosis posteriores de la vacuna.

<br> <br>
En caso presentara alguna molestia, debe acercarse Inmediatamente al establecimiento de salud más cercano a su domicilio.
</p>
</body>
`

export default function ConsentHtml() {
  return (
    <div 
      className="custom-scrollbar"
      style={{ overflowY: 'auto', height: '500px', border: '2px solid #EFEFEF', fontFamily: 'Roboto, Helvetica' }} dangerouslySetInnerHTML={{__html: htmlContent}} />
  )
}