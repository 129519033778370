import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Box from '../../../../components/box'
import { useFormErrors, Radio } from '../../../../components/input'
import * as Schema from '../../../../utils/validation'
import Context from '../../../../Context'
import jwt_decode from 'jwt-decode'

const radioErrorMessage = 'Debes seleccionar una opción'

const Statement = () => {
  const { dashboardConfig, setDashboardConfig, dashboardSession, setDashboardSession, userAuth } = useContext(
    Context,
  )
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)

  

  if (dashboardConfig == null) {
    location.reload()
  }

  
  const [radioValues, setRadioValues] = useState(
    dashboardSession?.quiz_statement ? dashboardSession?.quiz_statement : dashboardConfig.questions_covid.sort(function (a, b) {
      return (a.order - b.order)
    })
  )

  const decoded = jwt_decode(userAuth.token)






  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      const ValidateStatementSchema = Schema.object().shape(
        dashboardConfig.questions_covid.reduce((prev, value) => {
          if (decoded.gender !== 'female') {
            if (value.code !== 'qcovid_13' && value.code !== 'qcovid_14') {
              return {
                ...prev,
                [value.code]: Schema.string().required(radioErrorMessage),
              }
            } else {
              return {
                ...prev,
                [value.code]: Schema.string(),
              }
            }
          } else {
            return {
              ...prev,
              [value.code]: Schema.string().required(radioErrorMessage),
            }
          }
        }, {}),
      )
      await ValidateStatementSchema.validate(data)
      const pathname =
        data.qcovid_3 === 'yes' && data.qcovid_4 === 'yes'
          ? '/afiliacion/encuesta/prueba-covid'
          : '/afiliacion/encuesta/auto-triaje'
      const session = {
        ...dashboardSession,
        quiz_statement: data,
        lastPathname: pathname,
      }
      setDashboardSession(session)
      history.push({ pathname })
    } catch (err) {
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.log('err', err)
      }
      setIsSubmitted(false)
    }
  }
  let count = 0
  const handleRadioChange = (event) => {
    const { name, value } = event.target
    const newValue = { [name]: value }
    if (name === 'qcovid_3') {
      newValue['qcovid_4'] = value
    }
    setRadioValues((prevValues) => ({ ...(prevValues || {}), ...newValue }))
  }

  useEffect(() => {
    dashboardConfig.questions_covid.sort(function (a, b) {
      return (a.order - b.order)
    })
    if (radioValues) {
      const findEmptyValues = dashboardConfig.questions_covid.reduce(
        (prevState, question) => {
          const prevError = formRef.current.getFieldError(question.code)
          if (!prevError || radioValues[question.code]) return prevState
          return { ...prevState, [question.code]: radioErrorMessage }
        },
        {},
      )
      setErrors(findEmptyValues)
    }
  }, [userAuth, radioValues, dashboardConfig.questions_covid, setErrors])

  return (
    <Box >
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Encuesta COVID-19
        </Typography>
        <Typography variant="body1" component="h2">
          Gracias por participar compartiendo información relevante con nosotros.
          Esta encuesta es confidencial y tiene el valor de una Declaración Jurada.
        </Typography>
      </Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} >
        <Grid container spacing={3}>
          {dashboardConfig.questions_covid.map((question, index) => {
            const id = question.code
            const disabled =
              id === 'qcovid_4' && radioValues?.['qcovid_3'] === 'no'

            if (decoded.gender !== 'female') {
              if (question.code !== 'qcovid_13' && question.code !== 'qcovid_14') {
                count++
                return (
                  <Grid key={id} item xs={12}>
                    <Radio
                      name={id}
                      label={`${count}. ${question.name}`}
                      value={radioValues?.[id] || ''}
                      options={[
                        { id: 'yes', label: 'Si' },
                        { id: 'no', label: 'No' },
                      ]}
                      disabled={disabled}
                      onChange={handleRadioChange}
                      error={!!errors[id]}
                      helperText={errors[id]}
                      row
                    />
                  </Grid>
                )
              }
            } else {
              count++
              return (
                <Grid key={id} item xs={12}>
                  <Radio
                    name={id}
                    label={`${count}. ${question.name}`}
                    value={radioValues?.[id] || ''}
                    options={[
                      { id: 'yes', label: 'Si' },
                      { id: 'no', label: 'No' },
                    ]}
                    disabled={disabled}
                    onChange={handleRadioChange}
                    error={!!errors[id]}
                    helperText={errors[id]}
                    row
                  />
                </Grid>
              )
            }
          })}

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitted}
              endIcon={
                isSubmitted ? (
                  <CircularProgress size={24} color="primary" />
                ) : null
              }>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default Statement
