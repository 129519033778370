import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Box from '../../../components/box'
import Context from '../../../Context'

const useStyles = makeStyles((theme) => ({
  boxForm: {
    borderRadius: theme.spacing(1),
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    textDecoration: 'none',
    color: '#212529',
    textAlign: 'center',
    height: '100%',
    minHeight: 350,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    transition: 'all .25s ease',
    '&:hover': {
      color: theme.palette.primary.main,
      transform: 'translateY(-4px) scale(1.02)',
    },
  },
  iconForm: {
    fontSize: 72,
  },
  report: {
    borderRadius: theme.spacing(1),
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    overflow: 'hidden',
    transition: 'all .25s ease',
    '&:hover': {
      transform: 'translateY(-4px) scale(1.02)',
    },
  },
  reportTitle: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    padding: theme.spacing(1, 2),
    textAlign: 'center',
  },
  textField: {
    '& > div': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
}))

const List = () => {
  const classes = useStyles()
  const { dashboardConfig, userAuth, setUserAuth, setActiveMenu } = useContext(
    Context,
  )
  const [reports, setReports] = useState()

  const getReports = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/get-adverse-reaction`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      // if (!response.success) throw new Error(response.error?.message)
      setReports(data)
    } catch (err) {
      console.log(err)
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      }
    }
  }, [setUserAuth, userAuth])

  useEffect(() => {
    setActiveMenu('/reacciones-adversas')
    getReports()
  }, [getReports, setActiveMenu])

  if (!reports) {
    return (
      <Box my={4} style={{ textAlign: 'center' }}>
        <CircularProgress size={24} color="primary" />
      </Box>
    )
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Link className={classes.boxForm} to="/reacciones-adversas/registro">
            <Box>
              <Icon className={classes.iconForm}>add_circle</Icon>
              <Typography variant="body1">
                Si presentaste alguna reacción a la vacuna, regístrala aquí.
              </Typography>
            </Box>
          </Link>
        </Grid>
        {reports.map((sideeffect, index) => {
          const vaccine = dashboardConfig?.vaccinations.find(
            (v) => v.code === sideeffect.vaccine_code,
          )
          let text = ''
          let symptoms = []
          if (sideeffect?.symptom) {
            text =
              sideeffect?.symptom.length > 1
                ? ', ' + sideeffect?.symptom_text
                : ', ' + sideeffect?.symptom_text
            symptoms = sideeffect.symptom.map((symptom) => {
              const value = dashboardConfig?.adverse_reaction.find(
                (s) => s.code === symptom,
              )
              return value ? value.name : ''
            })
          } else {
            text = sideeffect?.symptom_text
          }
          return (
            <Grid item key={index} xs={12} md={6} lg={4}>
              <Box className={classes.report}>
                <Box className={classes.reportTitle}>
                  <Typography color="inherit" variant="h6">
                    <b>Reporte N° {index + 1}</b>
                  </Typography>
                </Box>
                <Box p={2}>
                  <Typography color="inherit" variant="body1" gutterBottom>
                    <b>Vacuna:</b> {vaccine?.name}
                  </Typography>
                  <Typography color="inherit" variant="body1" gutterBottom>
                    <b>Fecha de vacunación:</b>{' '}
                    {dayjs(sideeffect.vaccine_date).format('DD/MM/YYYY')}
                  </Typography>
                  <Typography color="inherit" variant="body1" gutterBottom>
                    <b>Fecha de reporte:</b>{' '}
                    {dayjs(sideeffect.created_at).format('DD/MM/YYYY')}
                  </Typography>
                  <Box mb={2}>
                    <Typography color="inherit" variant="body1" gutterBottom>
                      <b>Síntomas:</b>
                    </Typography>
                    <TextField
                      className={classes.textField}
                      value={
                        symptoms.join(', ') +
                        (text !== ',-' && text !== '-' ? text : '')
                      }
                      disabled
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <Typography color="inherit" variant="body1" gutterBottom>
                      <b>Comentarios:</b> {}
                    </Typography>
                    <TextField
                      className={classes.textField}
                      value={sideeffect.comment}
                      multiline
                      rows={4}
                      disabled
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default List
