import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Document, Page } from 'react-pdf'

import Box from '../../../components/box'
import { useFormErrors, Checkbox } from '../../../components/input'
import * as Schema from '../../../utils/validation'
import Context from '../../../Context'
import fileConsent from '../../../assets/files/consent.pdf'
import ConsentHtml from './ConsentHtml'
import fileConsentimiento from '../../../assets/files/Consentimiento.pdf'

import { Base64 } from 'js-base64'

const ConsentSchema = Schema.object().shape({
  consent: Schema.boolean().required(
    'Es necesario estar de acuerdo con el consentimiento informado',
  ),
})

const Consent = () => {
  const {
    dashboardSession,
    setDashboardSession,
    setUserAuth,
    userAuth,
  } = useContext(Context)
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors } = useFormErrors({
    schema: ConsentSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [numPages, setNumPages] = useState(null)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await ConsentSchema.validate(data)
      const formData = new FormData()
      const testAnswers = Object.keys(dashboardSession.quiz_statement).map(
        (statement) => ({
          code: statement,
          answer: dashboardSession.quiz_statement[statement] === 'yes',
        }),
      )
      formData.append('testAnswers', JSON.stringify(testAnswers))
      const file = dashboardSession.quiz_testcovid?.file
      formData.append('testPhoto', file || '')
      formData.append('weight', dashboardSession.quiz_evaluation.peso)
      formData.append('height', dashboardSession.quiz_evaluation.altura)
      const higher_risk_diseases = dashboardSession.quiz_evaluation?.higher_risk_diseases
        ?.map((disease) => disease.code)
        .join(',')
      console.log(higher_risk_diseases)
      formData.append('higherRiskDiseases', higher_risk_diseases || '')
      const lower_risk_diseases = dashboardSession.quiz_evaluation?.lower_risk_diseases
        ?.map((disease) => disease.code)
        .join(',')
      formData.append('lowerRiskDiseases', lower_risk_diseases || '')
      const highest_risk_population = dashboardSession.quiz_evaluation?.highest_risk_population
        ?.map((disease) => disease.code)
        .join(',')
      formData.append('highestRiskPopulation', highest_risk_population || '')
      formData.append(
        'profession',
        dashboardSession.quiz_profession.profession !== 'other'
          ? dashboardSession.quiz_profession.profession
          : '',
      )

      formData.append(
        'professionText',
        Base64.encode(dashboardSession.quiz_profession.professionText),
      )

      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/register-survey`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )

      const pathname = '/afiliacion/confirmacion'
      setIsSubmitted(false)
      const session = {
        ...dashboardSession,
        membership_success: response,
        lastPathname: pathname,
      }
      setDashboardSession(session)
      history.push({ pathname })
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.log('err', err)
        toast('Problemas al registrar su solicitud', {
          type: 'warning',
        })
      }
      setIsSubmitted(false)
    }
  }

  const handleChangeConsent = (event) => {
    setErrors(event.target.value)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const handleDownloadConsent = () => {
    window.open(fileConsentimiento);
  };

  return (
    <Container maxWidth="md">
      <Box
        style={{
          background: '#FFFFFF',
          boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
        }}
        border="2px #F3F1F18C solid"
        borderTop="4px #2306DE solid"
        borderRadius={10}
        borderColor="primary"
        mt={4}
        px={2}
        py={4}>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justify="center">
          <Icon>assignment</Icon>
          <Box ml={2}>
            <Typography variant="h5" component="h3">
              Consentimiento Informado
            </Typography>
          </Box>
        </Grid>
        <Box
          my={4}
          p={0}
          height={700}
          border="2px solid #EFEFEF"
          className="custom-scrollbar"
          style={{ overflowY: 'auto' }}>
          {ConsentHtml}
        </Box>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <Box mb={4}>
            <Checkbox
              name="consent"
              label="He leído y estoy de acuerdo con el CONSENTIMIENTO INFORMADO."
              color="primary"
              error={!!errors.consent}
              helperText={errors.consent}
              onChange={handleChangeConsent}
            />
          </Box>
          <Box style={{ display: 'flex', alignContent: 'space-between' }}>
            <div style={{ width: '50%' }}>
              <Button
                variant="contained"
                disabled={isSubmitted}
                color="primary"
                type="submit"
                endIcon={isSubmitted ? <CircularProgress size={24} /> : null}>
                ACEPTAR
              </Button>
            </div>
            <div style={{ width: '50%', textAlign: 'end' }}>
              <Button
                style={{ justifyContent: 'flex-end' }}
                variant="contained"
                onClick={handleDownloadConsent}
                color="secondary"
                type="button">
                Descargar
              </Button>
            </div>
            {/* <Box width="100%">
            </Box>
            <Box flexShrink={0}>
            </Box> */}
          </Box>
        </Form>
      </Box>
    </Container>
  )
}

export default Consent
