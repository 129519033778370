import { useField } from '@unform/core'
import BaseCheckbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import React, { useEffect, useRef } from 'react'

const Checkbox = ({ error, helperText, label, name, ...restProps }) => {
  const inputRef = useRef()
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'checked',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  return (
    <FormControl error={error}>
      <FormControlLabel
        control={
          <BaseCheckbox {...restProps} name={fieldName} inputRef={inputRef} />
        }
        label={label}
      />
      {!error ? null : <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Checkbox
