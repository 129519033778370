import { useField } from '@unform/core'
import BaseTextField from '@material-ui/core/TextField'
import React, { useEffect, useRef } from 'react'

const TextField = ({
  defaultValue,
  hidden,
  name,
  variant = 'outlined',
  ...restProps
}) => {
  const inputRef = useRef()
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    if (inputRef.current && defaultValue) {
      inputRef.current.value = defaultValue
    }
  }, [defaultValue])

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
      getValue() {
        return inputRef.current.value
      },
    })
  }, [fieldName, registerField])

  if (hidden) {
    return (
      <input {...restProps} name={fieldName} ref={inputRef} type="hidden" />
    )
  }

  return (
    <BaseTextField
      {...restProps}
      name={fieldName}
      inputRef={inputRef}
      variant={variant}
    />
  )
}

export default TextField
