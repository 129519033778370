import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import * as Schema from '../../../utils/validation'
import React, { useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

import Box from '../../../components/box'
import Context from '../../../Context'

const useStyles = makeStyles((theme) => ({
  code: {
    backgroundColor: '#000',
    width: 150,
    height: 150,
    margin: theme.spacing(0.5, 'auto', 1),
  },
  button: {
    margin: theme.spacing(1, 'auto'),
    textAlign: 'center',
    width: 150,
  },
}))

const Reschedule = (props) => {
  const classes = useStyles()
  const { ubigeo, userAuth, setUserAuth } = useContext(Context)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSendReschedule = async () => {
    try {
      setIsSubmitted(true)
      const headers = { Authorization: `Bearer ${userAuth.token}` }
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/appointment/reschedule`,
        { date: props.data.created_at },
        { headers },
      )
      if (response.success) {
        toast(
          'Hemos recibido tu solicitud, te comunicaremos en cuanto se programe tu nueva cita.',
          {
            type: 'success',
          },
        )
      } else {
        toast(
          'Su solicitud ya fue registrada, está en proceso de atención.',
          {
            type: 'warning',
          },
        )
      }
      props.onClose()
      setIsSubmitted(false)
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.log('err', err)
        toast(
          'Problemas al enviar su solicitud, por favor intentelo nuevamente.',
          {
            type: 'error',
          },
        )
        props.onClose()
      }
      setIsSubmitted(false)
    }
  }

  const handleClose = () => {
    props.onClose()
  }

  const getUbigeo = useCallback(
    (code) => {
      const location = ubigeo.ubigeos?.find(
        (location) => location.code === code,
      )
      if (!location) return ''
      return `${location.department}, ${location.province}, ${location.district}`
    },
    [ubigeo],
  )

  const getDate = useCallback((date) => {
    const [dateFormat] = new Date(date).toLocaleString().split(' ')
    return dateFormat
  }, [])

  return (
    <Box px={6} py={1}>
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justify="center"
        spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {userAuth?.name}, vas a reprogramar tu vacunación COVID-19, verifica
            que los datos sean correctos:
          </Typography>
        </Grid>
        {props.data ? (
          <Grid item xs={12}>
            <Box>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <b>Vacuna:</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    COVID-19
                    {/*props.data.local_address} -{' '}
                  {getUbigeo(props.data.local_ubigeo)*/}
                  </Typography>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <b>Fecha:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {dayjs(props.data.date).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <b>Dosis:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{props.data.dose}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <b>Estado:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {props.data.status === 'expired' ? 'Expirado' : '--'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        ) : null}
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12}>
            <Box mt={1} mb={2}>
              <Typography variant="body1">
                <b>¿Estás seguro que deseas reprogramar tu cita?</b>
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box className={classes.button}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSendReschedule}
                disabled={isSubmitted}
                endIcon={
                  isSubmitted ? (
                    <CircularProgress size={24} color="primary" />
                  ) : null
                }>
                Si
              </Button>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box className={classes.button}>
              <Button variant="contained" fullWidth onClick={handleClose}>
                No
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Reschedule
