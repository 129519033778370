import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AutocompleteBase from '@material-ui/lab/Autocomplete'
import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Box from '../../../../components/box'
import { useFormErrors, TextField } from '../../../../components/input'
import * as Schema from '../../../../utils/validation'
import Context from '../../../../Context'

const icon = (
  <Icon color="primary" fontSize="small">
    check_box_outline_blank
  </Icon>
)
const checkedIcon = (
  <Icon color="primary" fontSize="small">
    check_box
  </Icon>
)

const Autocomplete = (props) => {
  return (
    <AutocompleteBase
      multiple
      getOptionLabel={(option) => option.name}
      noOptionsText="Sin opciones"
      disableCloseOnSelect
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      {...props}
    />
  )
}

const EvaluationSchema = Schema.object().shape({
  peso: Schema.string()
    .matches(/^[1-9]\d*$/, 'Solo se permiten números y que sean mayores a 0')
    .required(),
  altura: Schema.string()
    .matches(/^[1-9]\d*$/, 'Solo se permiten números y que sean mayores a 0')
    .required(),
})

const Evaluation = () => {
  const { dashboardConfig, dashboardSession, setDashboardSession } = useContext(
    Context,
  )
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    ref: formRef,
    schema: EvaluationSchema,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const diseases = useRef({
    lower_risk_diseases: dashboardSession.quiz_evaluation?.lower_risk_diseases,
    higher_risk_diseases: dashboardSession.quiz_evaluation?.higher_risk_diseases,
    highest_risk_population: dashboardSession.quiz_evaluation?.highest_risk_population,
  })
  const [valueHeight, setValueHeight] = useState('')
  const [valueWidth, setValueWidth] = useState('')

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await EvaluationSchema.validate(data)
      const pathname = '/afiliacion/encuesta/profesion'
      const session = {
        ...dashboardSession,
        quiz_evaluation: { ...data, ...diseases.current },
        lastPathname: pathname,
      }
      setDashboardSession(session)
      history.push({ pathname })
    } catch (err) {
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.log('err', err)
      }
      setIsSubmitted(false)
    }
  }

  const handleDiseases = (type, data) => {
    diseases.current = { ...diseases.current, [type]: data }
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    if (name === 'peso') {
      value.match(/^(^$)|[0-9]+$/) ? setValueWidth(value) : null
    }
    if (name === 'altura') {
      value.match(/^(^$)|[0-9]+$/) ? setValueHeight(value) : null
    }
  }

  useEffect(() => {
    setValueWidth(dashboardSession?.quiz_evaluation?.peso || '')
    setValueHeight(dashboardSession?.quiz_evaluation?.altura || '')
  }, [setValueWidth, setValueHeight, dashboardSession])

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Autotriaje
        </Typography>
        <Typography variant="body1" component="h2">
          Gracias por participar compartiendo información relevante con nosotros. 
          Esta encuesta es confidencial y tiene el valor de una Declaración Jurada.
        </Typography>
      </Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{
                maxLength: 3,
              }}
              name="peso"
              label="Peso (kg)"
              fullWidth
              error={!!errors.peso}
              helperText={errors.peso}
              defaultValue={dashboardSession?.quiz_evaluation?.peso || ''}
              value={valueWidth}
              onChange={(event) => {
                handleChangeInput(event)
                setFieldError('peso', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{
                maxLength: 3,
              }}
              name="altura"
              label="Altura (cm)"
              fullWidth
              error={!!errors.altura}
              helperText={errors.altura}
              defaultValue={dashboardSession?.quiz_evaluation?.altura || ''}
              value={valueHeight}
              onChange={(event) => {
                handleChangeInput(event)
                setFieldError('altura', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={dashboardConfig.higher_risk_diseases}
              defaultValue={diseases.current.higher_risk_diseases || []}
              onChange={(_, value) => handleDiseases('higher_risk_diseases', value)}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    name="higher_risk_diseases"
                    variant="outlined"
                    label="Enfermedades de mayor riesgo"
                    
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={dashboardConfig.lower_risk_diseases}
              defaultValue={diseases.current.lower_risk_diseases || []}
              onChange={(_, value) => handleDiseases('lower_risk_diseases', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="lower_risk_diseases"
                  variant="outlined"
                  label="Enfermedades de menor riesgo"
                  
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={dashboardConfig.highest_risk_population}
              defaultValue={diseases.current.highest_risk_population || []}
              onChange={(_, value) =>
                handleDiseases('highest_risk_population', value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="highest_risk_population"
                  variant="outlined"
                  label="Grupo poblacional de mayor riesgo"
                  
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitted}
              endIcon={
                isSubmitted ? (
                  <CircularProgress size={24} color="primary" />
                ) : null
              }>
              Continuar
            </Button>
            <Box mt={2}>
              <Button
                variant="text"
                color="inherit"
                fullWidth
                disabled={isSubmitted}
                onClick={(event) => {
                  event.preventDefault()
                  history.push('/afiliacion/encuesta/prueba-covid')
                }}>
                Regresar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default Evaluation
