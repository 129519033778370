import { useCallback, useRef, useState } from 'react'

export const useFormErrors = ({ ref: formRef, schema: defaultSchema }) => {
  const [validationErrors, setValidationErros] = useState({})
  const schema = useRef(defaultSchema)

  const handleChangeField = useCallback(
    async (name, value) => {
      const error = formRef.current?.getFieldError(name)
      const hasError = error && error.length > 0
      try {
        await schema.current.validate({ [name]: value })
        if (hasError) {
          formRef.current?.setFieldError(name, '')
          setValidationErros((prev) => ({ ...prev, [name]: '' }))
        }
      } catch (err) {
        if (err.inner[name] && (!error || error !== err.inner[name])) {
          formRef.current?.setFieldError(name, err.inner[name])
          setValidationErros((prev) => ({ ...prev, [name]: err.inner[name] }))
        } else if (!err.inner[name] && hasError) {
          formRef.current?.setFieldError(name, '')
          setValidationErros((prev) => ({ ...prev, [name]: '' }))
        }
      }
    },
    [formRef],
  )

  const setErrors = useCallback(
    (errors) => {
      formRef.current?.setErrors(errors)
      setValidationErros(errors)
    },
    [formRef],
  )

  const setSchema = useCallback((_schema) => {
    schema.current = _schema
  }, [])

  return {
    errors: validationErrors,
    setFormSchema: setSchema,
    setFieldError: handleChangeField,
    setErrors,
  }
}
