import CssBaseline from '@material-ui/core/CssBaseline'
import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


import Context from './Context'
import DashboardContainer from './modules/dashboard/Container'
import DashboardRoutes from './modules/dashboard/routes'
import UserAttributes from './modules/user/Attributes'
import UserPolitics from './modules/user/Politics'
import UserRegister from './modules/user/Register'
import UserTerms from './modules/user/Terms'
import { modalInstallRef } from './utils/refs'
import { loadGoogleAnalytics } from './utils/scripts'
import Home from './modules/dashboard/Home'
import './App.css'

const App = () => {
  const { isAuth } = useContext(Context)

  useEffect(() => {
    loadGoogleAnalytics(process.env.REACT_APP_ANALYTICS_ID)
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault()
      modalInstallRef.current = event
    })

    window.addEventListener('appinstalled', function(event) {
      window.dataLayer.push({
        'event':'eventoGA',
        'eventCat':'pwa',
        'eventAct':'instalada',
      })
    })
  }, [])

  return (
    <div>
      {isAuth ? (
        <DashboardContainer>
          <CssBaseline />
          <Switch>
            {DashboardRoutes.map((route) => {
              const Component = route.component
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact ?? true}>
                  <Component />
                </Route>
              )
            })}
            <Route path="*">
              <Route path="/" component={Home} />
            </Route>
          </Switch>
        </DashboardContainer>
      ) : (
        <Switch>
          <Route exact path="/">
            <UserRegister />
          </Route>
          <Route exact path="/user/terms">
            <UserTerms />
          </Route>
          <Route exact path="/user/politics">
            <UserPolitics />
          </Route>
          <Route path="/user/attributes">
            <UserAttributes />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      )}
      <ToastContainer />
    </div>
  )
}

export default App
