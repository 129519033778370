import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import MuiTableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAttributesStyles } from './styles'
import Box from '../../components/box'
import Context from '../../Context'
import jwt_decode from 'jwt-decode'

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell)

const rows = {
  //name: 'Nombre',
  //lastname: 'Apellidos',
  gender: 'Género:',
  credential_type: 'Tipo de documento:',
  user: 'Número de documento:',
  birthdate: 'Fecha de nacimiento:',
  department: 'Departamento:',
  province: 'Provincia:',
  district: 'Distrito:',
  address: 'Dirección:',
  telephone: 'Teléfono fijo:',
  phone: 'Número de celular:',
  email: 'Correo electrónico:',
  // digit: 'Dígito de verificación',
  // organization_code: 'Organización',
  //created_at: 'Fecha de registro',
  //ubigeo: 'Ubigeo',
}

const EndRegistration = () => {
  const classes = useAttributesStyles()
  const {
    ubigeo,
    userSession,
    setIsAuth,
    setUserSession,
    setUserAuth,
  } = useContext(Context)
  const history = useHistory()

  const [profile, setProfile] = useState()
  const getProfile = useCallback(async () => {



    const getDocumentType = (documentId) => {
      const documents = [
        {
          value: 'dni',
          label: 'DNI',
        },
        {
          value: 'ce',
          label: 'Carné de extranjería',
        },
        {
          value: 'pass',
          label: 'Pasaporte',
        },
        {
          value: 'ext',
          label: 'Documento de identidad extranjero',
        },
      ]

      return documents.find(doc => doc.value == documentId)?.label;
    };



    try {
      const headers = { Authorization: `Bearer ${userSession.token}` }
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/user`,
        {
          headers,
        },
      )
      if (!response.success) {
        throw new Error('Lo sentimos, pero no se pudo encontrar su registro.')
      }
      const keys = Object.keys(rows)
      const place = ubigeo.ubigeos.find(
        (place) => place.code === response.data.ubigeo,
      )

      const attributes = keys.map((key) => {
        let detail = response.data[key]
        const ubigeosKeys = ['department', 'district', 'province']

        if (ubigeosKeys.includes(key)) {
          detail = place[key]
        }

        if (key === 'created_at') {
          detail = new Date(detail).toLocaleString()
        }

        if (key === 'telephone') {
          if (response.data.telephone) {
            detail = response.data.city_code + ' ' + response.data.telephone
          } else {
            detail = 'No especificó'
          }
        }

        if (!detail) {
          detail = 'No especificó'
        }

        if (key === 'email') {
          if (!response.data.email_verified) {
            detail = 'No especificó'
          }
        }

        if (key === 'credential_type') {
          if (response.data.credential_type) {
            detail = getDocumentType(response.data.credential_type)
          }
        }

        return {
          name: rows[key],
          detail,
        }
      })
      setProfile(attributes)
    } catch (err) {
      console.log('err', err)
      if (err?.response?.status === 401) {
        setUserSession(null, 'expired')
      }
    }
  }, [ubigeo, setUserSession, userSession])

  useEffect(() => {
    if (!profile) {
      getProfile()
    }
  }, [profile, getProfile])

  const handleConfirm = (event) => {
    event.preventDefault()
    setUserAuth(userSession)
    setUserSession(null)
    setIsAuth(true)
    // history.replace('/afiliacion')
    history.replace('/afiliacion/survey')
  }

  return (
    <Box className={classes.container}>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Excelente {userSession.name}
        </Typography>
        <Typography variant="body1" component="h2">
          Has confirmado tu registro, valida tus datos e ingresa. Recuerda mantener actualizado tus datos para una mejor atención.
        </Typography>
      </Box>
      <Box mb={4}>
        <Table size="small" aria-label="información de usuario">
          <TableBody>
            {!profile ? (
              <Box py={4} style={{ textAlign: 'center' }}>
                <CircularProgress size={24} color="primary" />
              </Box>
            ) : (
              profile.map((attribute) => (
                <TableRow key={attribute.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <b>{attribute.name}</b>
                    <br />
                    {attribute.detail}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleConfirm}>
          Confirmar
        </Button>
        {/*<Box mt={2}>
          <Button
            variant="text"
            color="inherit"
            fullWidth
            onClick={(event) => {
              event.preventDefault()
              history.push('/user/attributes/contact')
            }}>
            Regresar
          </Button>
          </Box>*/}
      </Box>
    </Box>
  )
}

export default EndRegistration
