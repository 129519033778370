import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import React, { useContext, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Box from '../../../../components/box'
import { useFormErrors, Radio, TextField } from '../../../../components/input'
import * as Schema from '../../../../utils/validation'
import Context from '../../../../Context'

const ValidateProffesionSchema = Schema.object().shape({
  profession: Schema.string().required('Debes seleccionar una opción'),
  professionText: Schema.string()
    .matches(/^[a-zA-ZÀ-ÿ0-9*\u00f1\u00d1\s,.-]+$/i, 'Sólo se permiten letras y números')
    .max(150, 'Debe contener menos de 100 caracteres')
    .required('Información requerida'),
})

const Profession = () => {
  const { dashboardConfig, dashboardSession, setDashboardSession } = useContext(
    Context,
  )

  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    ref: formRef,
    schema: ValidateProffesionSchema,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [profession, setProfession] = useState(
    dashboardSession?.quiz_profession?.profession ? dashboardSession.quiz_profession?.profession : ''
  )
  const [valueOther, setValueOther] = useState(
    dashboardSession?.quiz_profession?.profession ? dashboardSession.quiz_profession?.professionText : ''
  )

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await ValidateProffesionSchema.validate(data)
      const pathname = '/afiliacion/consentimiento-informado'
      const session = {
        ...dashboardSession,
        quiz_profession: data,
        lastPathname: pathname,
      }
      setDashboardSession(session)
      history.push({ pathname })
    } catch (err) {
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.log('err', err)
      }
      setIsSubmitted(false)
    }
  }

  const options = useMemo(() => {
    const items = dashboardConfig.professions.map((proffesion) => ({
      id: proffesion.code,
      label: proffesion.name,
    }))
    return [...items, { id: 'other', label: 'Otro' }]
  }, [dashboardConfig.professions])

  const handleChangeProfession = (event) => {
    event.target.value === 'other' ? setValueOther('') : null
    setProfession(event.target.value)
  }

  const profesionText = useMemo(() => {
    if (!profession) return ''
    const item = dashboardConfig.professions.find(
      (prof) => prof.code === profession,
    )
    return item?.name || ''
  }, [dashboardConfig.professions, profession])

  const handleChangeInput = (event) => {
    const { value } = event.target
    value.match(/^(^$)|[a-zA-ZÀ-ÿ0-9\u00f1\u00d1\s,.-]+$/i) ? setValueOther(value) : null
  }

  /*useEffect(() => {
    setValueOther(dashboardSession?.quiz_evaluation?.altura || '')
  }, [setValueOther, dashboardSession])*/

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Selecciona tu profesión
        </Typography>
        <Typography variant="body1" component="h2">
          Gracias por participar compartiendo información relevante con nosotros. 
          Esta encuesta es confidencial y tiene el valor de una Declaración Jurada.
        </Typography>
      </Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Radio
              name="profession"
              value={profession}
              options={options}
              error={!!errors.profession}
              helperText={errors.profession}
              onChange={handleChangeProfession}
            />
            {profession === 'other' ? (
              <TextField
                name="professionText"
                fullWidth
                error={!!errors.professionText}
                helperText={errors.professionText}
                value={valueOther}
                onChange={(event) => {
                  handleChangeInput(event)
                  setFieldError('professionText', event.target.value)
                }}
              />
            ) : (
              <TextField name="professionText" value={profesionText} hidden />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="body1" component="h2">
                * Deberá presentar obligatoriamente el carné que acredite su profesión al momento de vacunarte.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitted}
              endIcon={
                isSubmitted ? (
                  <CircularProgress size={24} color="primary" />
                ) : null
              }>
              Continuar
            </Button>
            <Box mt={2}>
              <Button
                variant="text"
                color="inherit"
                fullWidth
                disabled={isSubmitted}
                onClick={(event) => {
                  event.preventDefault()
                  history.push('/afiliacion/encuesta/auto-triaje')
                }}>
                Regresar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default Profession
