import { makeStyles, withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import React, { useEffect, useState } from 'react'
import {
  useLocation,
  useRouteMatch,
  Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import QuizEvaluation from './quiz/Evaluation'
import QuizProfession from './quiz/Profession'
import QuizStatement from './quiz/Statement'
import QuizTestCovid from './quiz/TestCovid'
import QuizImage from '../../../assets/img/encuesta.png'
import Box from '../../../components/box'

function getSteps() {
  return [
    {
      label: 'Declaración jurada',
      path: '/declaracion-jurada',
      component: QuizStatement,
    },
    {
      label: 'Prueba COVID-19',
      path: '/prueba-covid',
      component: QuizTestCovid,
    },
    {
      label: 'Autotriaje',
      path: '/auto-triaje',
      component: QuizEvaluation,
    },
    {
      label: 'Profesión',
      path: '/profesion',
      component: QuizProfession,
    },
  ]
}

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#2306DE',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#2306DE',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const useStyles = makeStyles((theme) => ({
  container: {
    borderTopStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    padding: theme.spacing(3, 2),
    backgroundColor: '#FFF',
    [theme.breakpoints.up('md')]: {
      borderTopWidth: 4,
      marginTop: theme.spacing(4),
      padding: theme.spacing(6, 4),
    },
  },
}))

const Quiz = () => {
  const classes = useStyles()
  const location = useLocation()
  const { path } = useRouteMatch()

  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  useEffect(() => {
    const index = steps.findIndex(
      (step) => `${path}${step.path}` === location.pathname,
    )
    if (index >= 0) {
      setActiveStep(index)
    }
  }, [location, path, steps])

  return (
    <Container maxWidth="lg">
      <Hidden smDown implementation="css">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomConnector />}
          style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Hidden>
      <Grid container>
        <Grid item md={5}>
          <Hidden smDown implementation="css">
            <Box style={{ textAlign: 'center' }}>
              <img src={QuizImage} alt="encuesta" width={400} />
            </Box>
          </Hidden>
        </Grid>
        <Grid item md={7}>
          <Box className={classes.container}>
            <Switch>
              <Route exact path={path}>
                <Redirect to={{ pathname: `${path}/declaracion-jurada` }} />
              </Route>
              {steps.map((route) => {
                const Component = route.component
                return (
                  <Route key={route.path} path={`${path}${route.path}`} exact>
                    <Component />
                  </Route>
                )
              })}
              <Route path="*" render={() => <h1>Error Page not Found</h1>} />
            </Switch>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Quiz
