import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CallIcon from '@material-ui/icons/Call';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { Form } from '@unform/web'
import axios from 'axios'
import { es } from 'date-fns/locale'
import dayjs from 'dayjs'
import jwt_decode from 'jwt-decode'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import SlideHomeOne from '../../assets/img/home/portada.png'
import Box from '../../components/box'
import { useFormErrors, TextField } from '../../components/input'
import * as Schema from '../../utils/validation'
import Context from '../../Context'
import { Dialog, DialogContent } from '@material-ui/core'

import Image_1 from '../../assets/img/comunicado.svg'

const useStyles = makeStyles((theme) => ({


  sectionComunicado: {
    backgroundImage: `url(${Image_1})`,
    height: '100%',
    width: '100%',
    height: '768px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  container: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
  logotipo: {
    width: 200,
  },
  slideContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      height: '100vh',
    },
  },
  borderImage: {
    borderRight: '4px #2306DE solid',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'inherit',
    },
  },
  slideBox: {
    backgroundImage: `url(${SlideHomeOne})`,
    backgroundSize: 'cover',
    textAlign: 'center',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: '28vh',
      //display: 'none',
    },
  },
  slideImage: {
    maxWidth: '100%',
  },
  formContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '-50px',
      margin: theme.spacing(0, 2),
      backgroundColor: '#FFF',
      borderRadius: theme.spacing(1),
      boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    },
  },
  formBox: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
  },
}))

const documents = [
  {
    value: 'dni',
    label: 'DNI',
  },
  {
    value: 'ce',
    label: 'Carné de extranjería',
  },
  {
    value: 'pass',
    label: 'Pasaporte',
  },
  {
    value: 'ext',
    label: 'Documento de identidad extranjero',
  },
]

const CreateDynamicSchema = (schemas) =>
  Schema.object().shape({
    typeDocument: Schema.string().required(),
    birthdate: Schema.string().required(),
    recaptcha: Schema.string().required(),
    ...schemas,
  })

const SignUpDniSchema = CreateDynamicSchema({
  numberDocument: Schema.string()
    .length(8)
    .matches(/^[0-9]*$/, 'Solo se permiten números')
    .required(),
  // codeVerify: Schema.string()
  //   .length(1)
  //   .matches(/^[0-9]*$/, 'Solo se permiten números')
  //   .required(),
})

const SignUpCexSchema = CreateDynamicSchema({
  numberDocument: Schema.string()
    .matches(/^[A-Za-z0-9]+$/i, 'Solo se permiten letras y números')
    .min(6, 'Debe contener más de 5 caracteres')
    .max(9, 'Debe contener menos de 10 caracteres')
    .required(),
})

const SignUpPassSchema = CreateDynamicSchema({
  numberDocument: Schema.string()
    .matches(/^[A-Za-z0-9]+$/i, 'Solo se permiten letras y números')
    .min(6, 'Debe contener más de 5 caracteres')
    .max(9, 'Debe contener menos de 10 caracteres')
    .required(),
})

const SignUpExtSchema = CreateDynamicSchema({
  numberDocument: Schema.string()
    .matches(/^[A-Za-z0-9]+$/i, 'Solo se permiten letras y números')
    .min(6, 'Debe contener más de 5 caracteres')
    .max(9, 'Debe contener menos de 10 caracteres')
    .required(),
})
const schemas = {
  dni: SignUpDniSchema,
  ce: SignUpCexSchema,
  pass: SignUpPassSchema,
  ext: SignUpExtSchema,
}

export const Register = () => {
  const classes = useStyles()
  const {
    ubigeo,
    setUbigeo,
    userSession,
    setUserSession,
    setUserAuth,
    setTimeoutSession,
  } = useContext(Context)

  const getUbigeo = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ADMIN}/cache-register`,
      )
      const { data } = response.data
      setUbigeo(data)
      localStorage.setItem('ubigeos', JSON.stringify(data))
    } catch (err) {
      console.error(err.message)
    }
  }, [setUbigeo])

  useEffect(() => {
    if (!ubigeo) {
      getUbigeo()
    }
  }, [getUbigeo, ubigeo])

  const history = useHistory()
  const [typeDocument, setTypeDocument] = useState('dni')
  const schema = useRef(schemas.dni)

  const formRef = useRef()
  const { errors, setErrors, setFieldError, setFormSchema } = useFormErrors({
    schema: schema.current,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState()
  const [valueDocument, setValueDocument] = useState('')
  const [valueDigit, setValueDigit] = useState('')
  const recaptchaRef = useRef()
  const [selectedDate, setSelectedDate] = useState(null)
  const [modalInvalidDoc, setModalInvalidDoc] = useState(false)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      const recaptcha = recaptchaRef.current?.getValue()
      const invalidDate =
        String(selectedDate) === 'Invalid Date' || !selectedDate
      const birthdate = invalidDate ? '' : dayjs(selectedDate).format('DD/MM/YYYY')
      await schema.current.validate({
        ...data,
        birthdate: birthdate,
        recaptcha,
      })
      const body = {
        // ip: '127.0.0.1',
        recaptcha,
        docType: data.typeDocument,
        doc: data.numberDocument,
        birthdate,
        // digit: parseInt(data.codeVerify) || 0,
      }
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/login`,
        body,
      )
      if (!response.success) {
        //if (response.error.code == 111) {
        //  setModalInvalidDoc(true)
        //  return false;
        //} else {
          throw new Error(response.error?.message)
        //}
      }
      const decoded = jwt_decode(response.token)
      const timeout = (decoded.exp - decoded.iat) * 1000
      if (response.new_user) {
        const session = {
          ...body,
          token: response.token,
          new_user: true,
          name: decoded.name,
        }
        setTimeoutSession(timeout, 'register')
        setUserSession(session)

        try {
          const headers = { Authorization: `Bearer ${session.token}` }
          const { data: result } = await axios.get(
            `${process.env.REACT_APP_BASE_URL_CITIZEN}/person`,
            {
              headers,
            },
          )
          const ubigeo = result.data.ubigeo.substr(-6)
          session.ubigeo_dep = `${ubigeo.substring(0, 2)}0000`
          session.ubigeo_pro = `${ubigeo.substring(0, 4)}00`
          session.ubigeo_dis = ubigeo
          session.address = result.data.address
          session.gender = result.data.gender
          setUserSession(session)
        } catch (err) {
          console.error(err)
          session.ubigeo_dep = ''
          session.ubigeo_pro = ''
          session.ubigeo_dis = ''
          session.address = ''
          session.gender = ''
          setUserSession(session)
        }

        history.push({ pathname: '/user/terms' })
      } else {
        setTimeoutSession(timeout, 'dasboard')
        setUserAuth({ name: decoded.name, token: response.token })
      }
    } catch (err) {
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        toast(err?.message || 'Error inesperado', {
          type: 'warning',
        })
        recaptchaRef.current?.reset()
      }
      setIsSubmitted(false)
    }
  }

  const handleDocumentChange = (event) => {
    const value = event.target.value
    setTypeDocument(value)
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    if (name === 'numberDocument') {
      if (typeDocument === 'dni') {
        value.match(/^(^$)|[0-9]+$/) ? setValueDocument(value) : null
      } else {
        value.match(/^(^$)|[A-Za-z0-9]+$/) ? setValueDocument(value) : null
      }
    }
    // if (name === 'codeVerify') {
    //   value.match(/^(^$)|[0-9]+$/) ? setValueDigit(value) : null
    // }
  }

  const handleDateChange = (date) => {
    const invalid = String(date) === 'Invalid Date' || !date
    setSelectedDate(date)
    setFieldError('birthdate', invalid ? '' : 'date')
  }

  const handleFormDocNoFountRedirect = (event) => {
    event.preventDefault();
    window.open(
      `https://forms.gle/DupC9fTGcELSMcTq9`,
      '_blank',
    )
  }

  useEffect(() => {
    const newSchema = schemas[typeDocument]
    schema.current = newSchema
    setFormSchema(newSchema)
  }, [setFormSchema, typeDocument])

  if (!!userSession?.token) {
    const lastPathname = userSession.lastPathname || '/user/terms'
    return <Redirect to={lastPathname} />
  }

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid
        item
        md={7}
        style={{ width: '100%' }}
        className={classes.borderImage}>
        <Box color="white" className={classes.slideBox}></Box>
        {/*<Grid
          container
          alignItems="center"
          justify="center"
          className={classes.slideContainer}>
          <Grid item style={{ maxWidth: 500 }} xs={12}>
            <Box mb={4} style={{ textAlign: 'center' }}>
              <img
                src={Logotipo}
                alt="Vacuna para todos"
                className={classes.logotipo}
              />
            </Box>
            <Box color="white" className={classes.slideBox}>
              <Typography variant="body1">
                Lorem, ipsum dolor sit amet, consectetur adipisicing elit.
                Molestias, quisquam veritatis, laudantium at magnam fuga,
                obcaecati minus doloribus facere repudiandae incidunt placeat
                tenetur totam eaque. Odit incidunt perferendis cum consequatur.
              </Typography>
              <img
                src={SlideHomeOne}
                alt="Slide 1"
                className={classes.slideImage}
              />
            </Box>
          </Grid>
        </Grid>*/}
      </Grid>
      <Grid item md={5} className={classes.formContainer}>
        <Box className={classes.formBox}>
          <Box mb={4}>
            <Typography variant="h6" component="h2">
              Iniciar Sesión
            </Typography>
            <Typography mt={1} component="h2">
              Bienvenido al programa “Vacuna para todos”. Por favor ingresa tus
              datos.
            </Typography>
          </Box>
          <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="typeDocument"
                  label="Tipo de documento"
                  select
                  fullWidth
                  error={!!errors.typeDocument}
                  helperText={errors.typeDocument}
                  value={typeDocument}
                  onChange={handleDocumentChange}>
                  {documents.map((doc) => (
                    <MenuItem
                      key={doc.value}
                      value={doc.value}
                      style={{
                        display: 'block',
                        padding: '6px 16px 6px 16px',
                      }}>
                      {doc.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                // lg={typeDocument === 'dni' ? 6 : 12}
                // sm={typeDocument === 'dni' ? 6 : 12}
                sm={12}
                md={12}
                xs={12}>
                <TextField
                  inputProps={{
                    maxLength: typeDocument === 'dni' ? 8 : 9,
                  }}
                  name="numberDocument"
                  label="Número de documento"
                  fullWidth
                  error={!!errors.numberDocument}
                  helperText={errors.numberDocument}
                  value={valueDocument}
                  onChange={(event) => {
                    handleChangeInput(event)
                    setFieldError('numberDocument', event.target.value)
                  }}
                />
              </Grid>
              {/*{typeDocument === 'dni' ? (*/}
              {/*  <Grid item lg={6} md={12} sm={6} xs={12}>*/}
              {/*    <TextField*/}
              {/*      inputProps={{*/}
              {/*        maxLength: 1,*/}
              {/*      }}*/}
              {/*      name="codeVerify"*/}
              {/*      label="Dígito de verificación"*/}
              {/*      fullWidth*/}
              {/*      error={!!errors.codeVerify}*/}
              {/*      helperText={errors.codeVerify}*/}
              {/*      value={valueDigit}*/}
              {/*      onChange={(event) => {*/}
              {/*        handleChangeInput(event)*/}
              {/*        setFieldError('codeVerify', event.target.value)*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </Grid>*/}
              {/*) : null}*/}
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    placeholder="dd/mm/aaaa"
                    margin="none"
                    label="Fecha de nacimiento"
                    value={selectedDate}
                    onChange={handleDateChange}
                    fullWidth
                    inputVariant="outlined"
                    error={!!errors.birthdate}
                    invalidDateMessage="Formato inválido"
                    minDateMessage=""
                    maxDateMessage=""
                  />
                </MuiPickersUtilsProvider>
                {!!errors.birthdate && selectedDate !== null ? null : (
                  <FormHelperText style={{ marginLeft: '14px' }} error>
                    {errors.birthdate}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box className="recaptcha-container" mb={2}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(token) => {
                      setFieldError('recaptcha', token)
                    }}
                  />
                  {!errors.recaptcha ? null : (
                    <FormHelperText error>{errors.recaptcha}</FormHelperText>
                  )}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={isSubmitted}
                  endIcon={
                    isSubmitted ? (
                      <CircularProgress size={24} color="primary" />
                    ) : null
                  }>
                  Ingresar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Grid>




      <Dialog open={modalInvalidDoc} onClose={() => {
        setModalInvalidDoc(false);
        setIsSubmitted(false);
        recaptchaRef.current?.reset();
      }} fullWidth maxWidth="xs">
        <DialogContent>
          <Box p={1} className={classes.modalContainer}>

            <Typography variant="h5" gutterBottom>
              <b>¿TU DNI NO SE ENCUENTRA REGISTRADO?</b>
            </Typography>

            <Typography align="justify" variant="body1" gutterBottom>
              Si cumples con estos tres requisitos:
              <Box mb={3} mt={2} px={3} pt={1} pb={1} style={{ background: '#e9ff204d', borderRadius: '10px', }}>

                <ol style={{ paddingLeft: '10px' }}>
                  <li>Ser mayor de 60 años.</li>
                  <li>Tener un seguro privado de salud o EPS</li>
                  <li>No estar asegurado en otras instituciones (EsSalud, SIS o FFAA)</li>
                </ol>
              </Box>

              <p>
                Ingresa tus datos <a onClick={(event) => { handleFormDocNoFountRedirect(event) }} href="#"><b>AQUÍ</b></a>.
                Validaremos la información y nos comunicaremos contigo.
              </p>
              <small>
                <i>
                  *Los nombres de los asegurados que tienen un plan de salud pre-paga en las clínicas que
                  participan de este programa se subirán a la plataforma en los próximos días.
                  Les estaremos informando.
                </i>
              </small>
            
            </Typography>

            <Box my={2}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                fullWidth               
                onClick={() => {
                  setModalInvalidDoc(false);
                  setIsSubmitted(false);
                  recaptchaRef.current?.reset();
                }}>
                <b>#PonElHombro</b>
              </Button>
            </Box>

            
          </Box>
        </DialogContent>
      </Dialog>


{/* 
      <Dialog open={true} onClose={() => {
      // fullWidth maxWidth="false"
      }} fullWidth maxWidth="sm" onClick={()=>{
        window.open('https://www.apeseg.org.pe/wp-content/uploads/2021/04/Preguntas-y-Respuestas-2.pdf', '_blank');

      }}>
        <DialogContent style={{padding:0,overflowX:'auto',cursor:'pointer'}} >
        <Box className={classes.sectionComunicado}></Box>
        </DialogContent>
      </Dialog> */}



    </Grid>
  )
}

export default Register
