import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Box from '../../components/box'
import axios from 'axios'
import dayjs from 'dayjs'

import Image_0 from '../../assets/img/dashboard/img_citas.png'
import Image_1 from '../../assets/img/dashboard/icon_vacuna.png'
import Image_2 from '../../assets/img/dashboard/img_2.svg'
import Image_3 from '../../assets/img/dashboard/img_3.svg'
import Image_4 from '../../assets/img/dashboard/img_4.svg'
import Image_5 from '../../assets/img/dashboard/img_5.svg'

import Logo1 from '../../assets/img/APEPS.png'
import Logo2 from '../../assets/img/logoAPESEG.png'


import Context from '../../Context'

console.log(Logo1.icoURL);
console.log(Logo2.icoURL);

const sections = [
  {
    name: 'Vacuna COVID-19',
    description: 'Regístrate al programa de vacunación con un clic',
    link: '/afiliacion',
    img: 'img-1',
  },
  /*{
    name: 'Plan Nacional de Vacunación',
    description:
      'Todo lo que tienes que saber sobre el Plan Nacional de Vacunación COVID-19',
    link: '/plan-nacional',
    img: 'img-2',
  },*/
  {
    name: 'Citas de Vacunación',
    description: 'Encuentra tus citas de vacunación',
    link: '/citas-vacunacion',
    img: 'img-3',
  },
  {
    name: 'Reacciones Adversas',
    description:
      'Registra si tuviste algún tipo de malestar después de la vacunación',
    link: '/reacciones-adversas',
    img: 'img-4',
  },
  {
    name: 'Info Vacunas',
    description: 'Todo lo que debes saber sobre las vacunas',
    link: '/info-vacunas',
    img: 'img-5',
  },
  {
    name: 'Contáctanos',
    description: 'Estamos para atenderte, en cualquier momento del día',
    link: 'contact',
    img: 'img-6',
  },
]

const useStyles = makeStyles((theme) => ({
  body:{
    backgroundColor:'#666666'
  },
  topics: {
    color: '#FFFFFF',
    borderRadius: theme.spacing(1),
    transition: 'all .25s ease',
    '&:hover': {
      transform: 'translateY(-4px) scale(1.02)',
    },
  },
  section: {
    // background: 'linear-gradient(270deg, #1fa3e5 0%, #57bfe2 100%);',
    background: 'linear-gradient(270deg, #2306DE 0%, #2306DE 100%);',
    color: '#FFFFFF',
    borderRadius: theme.spacing(1),
    transition: 'all .25s ease',
    '&:hover': {
      transform: 'translateY(-4px) scale(1.02)',
    },
  },
  sectionBgImageCitas: {
    padding: theme.spacing(4, 6, 4, 4),
    backgroundSize: 'auto 120%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Image_0})`,
    backgroundPosition: 'calc(100% + 0rem) bottom',
  },
  sectionBgImage: {
    padding: theme.spacing(4, 10, 4, 4),
    backgroundSize: 'auto 75%',
    backgroundRepeat: 'no-repeat',
    height: '220px!important',
  },
}))

const Home = () => {
  const classes = useStyles()
  const { userAuth, setUserAuth, setActiveMenu } = useContext(Context)
  const [appointment, setAppointment] = useState([])
  const history = useHistory()

  const getAppointment = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/appointment/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      setAppointment(data)
    } catch (err) {
      console.log(err)
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      }
    }
  }, [setUserAuth, userAuth])

  /* if (!userAuth) {
    history.push({ pathname: '/' })
  } */

  useEffect(() => {
    setActiveMenu('/')
    getAppointment()
  }, [getAppointment, setActiveMenu])

  const getStatusAppointment = useCallback((status) => {
    if (status === 'vaccinate') {
      return { color: '#febb2f', label: 'Vacunar' }
    }
    if (status === 'expired') {
      return { color: '#dc3545', label: 'Cita expirada' }
    }
    return { color: '#4caf50', label: 'Vacunado' }
  }, [])

  const getImageSections = useCallback((img) => {
    if (img === 'img-1') {
      return { uri: `url(${Image_1})`, position: 'calc(100% + 1rem) bottom' }
    } else if (img === 'img-2') {
      return { uri: `url(${Image_2})`, position: 'calc(100% + 0.5rem) bottom' }
    } else if (img === 'img-3') {
      return { uri: `url(${Image_3})`, position: 'calc(100% + 0.5rem) bottom' }
    } else if (img === 'img-4') {
      return { uri: `url(${Image_1})`, position: 'calc(100% + 1rem) bottom' }
    } else if (img === 'img-5') {
      return { uri: `url(${Image_4})`, position: 'calc(100% + 1rem) bottom' }
    } else if (img === 'img-6') {
      return { uri: `url(${Image_5})`, position: 'calc(100% + 1rem) bottom' }
    }
  }, [])

  const getDateStart = useCallback((date) => {
    let date1 = new Date(date)
    let date2 = new Date()
    let diff = date1.getTime() - date2.getTime()
    return Math.round(diff / (1000 * 60 * 60 * 24))
  }, [])

  const handleRedirect = (link) => {
    if (link === 'contact') {
      window.open(
          `tel:${process.env.REACT_APP_NUMBER_CALLPHONE}`,
          '_blank',
      )
    } else {
      history.push({
        pathname: link,
      })
    }
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography
          variant="h5"
          component="h2"
          className="title-head"
          gutterBottom>
          ¡Bienvenido(a) {userAuth?.name}
        </Typography>
        <Typography variant="body1" component="h2" className="title-head">
          Te invitamos a revisar toda la información que hemos desarrollado para
          ti
        </Typography>
      </Box>
      <Box mb={4}>
        <Grid container spacing={2}>
          {appointment.map((topic, index) => {
            const status = getStatusAppointment(topic.status)
            const dateStart = getDateStart(topic.date)
            return (
              <Grid item key={index} xs={12} md={6} lg={3}>
                {topic.status === 'expired' ? (
                  <Link
                    to={`/citas-vacunacion/${topic.created_at}`}
                    style={{ textDecoration: 'none' }}>
                    <Box
                      className={classes.topics}
                      style={{ backgroundColor: status.color }}>
                      <Box className={classes.sectionBgImageCitas}>
                        <Typography
                          color="inherit"
                          variant="h6"
                          component="h2"
                          gutterBottom>
                          <b>{status.label}</b>
                        </Typography>
                        <Typography
                          color="inherit"
                          variant="body2"
                          component="h2"
                          gutterBottom>
                          {dayjs(topic.date).format('DD/MM/YYYY')}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                ) : null}
                {topic.status === 'vaccinate' ? (
                  <Link
                    to={
                      topic.status === 'vaccinate' && dateStart <= 3
                        ? `/citas-vacunacion/${topic.created_at}`
                        : `/citas-vacunacion`
                    }
                    style={{ textDecoration: 'none' }}>
                    <Box
                      className={classes.topics}
                      style={{ backgroundColor: status.color }}>
                      <Box className={classes.sectionBgImageCitas}>
                        <Typography
                          color="inherit"
                          variant="h6"
                          component="h2"
                          gutterBottom>
                          <b>{status.label}</b>
                        </Typography>
                        <Typography
                          color="inherit"
                          variant="body2"
                          component="h2"
                          gutterBottom>
                          {dayjs(topic.date).format('DD/MM/YYYY')}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                ) : null}
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {sections.map((section, index) => {
            const image = getImageSections(section.img)
            return (
              <Grid item key={index} xs={12} md={6} lg={4}>
                <Box className={classes.section}>
                  <Box
                    className={classes.sectionBgImage}
                    style={{
                      backgroundImage: image.uri,
                      backgroundPosition: image.position,
                    }}>
                    <Typography
                      style={{ lineHeight: '18px' }}
                      color="inherit"
                      variant="h6"
                      component="h2"
                      gutterBottom>
                      <b>{section.name}</b>
                    </Typography>
                    <Typography
                      color="inherit"
                      variant="body2"
                      component="h2"
                      gutterBottom>
                      {section.description}
                    </Typography>
                    <Box mt={2}>
                      <Button
                        onClick={() => {
                          handleRedirect(section.link)
                        }}
                        variant="contained"
                        disableElevation>
                        Ver
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default Home
