import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import Box from '../../../components/box'
import Context from '../../../Context'

const LocalizedFormat = require('dayjs/plugin/localizedFormat')
const localeData = require('dayjs/plugin/localeData')

dayjs.extend(LocalizedFormat)
dayjs.extend(localeData)

console.log(dayjs().localeData().longDateFormat('L'))

const useStyles = makeStyles((theme) => ({
  appointment: {
    boxShadow: '0 0 30px 0 rgb(82 63 105 / 15%)',
    borderRadius: theme.spacing(1),
    margin: theme.spacing(4, 0),
    overflow: 'hidden',
    transition: 'all .25s ease',
    '&:hover': {
      transform: 'translateY(-4px) scale(1.02)',
    },
  },
  appointmentTitle: {
    color: '#FFF',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  appointmentContent: {
    padding: theme.spacing(2),
  },
}))

const Schedule = () => {
  const classes = useStyles()
  const { ubigeo, userAuth, setUserAuth, setActiveMenu } = useContext(Context)
  const history = useHistory()
  const [schedule, setSchedule] = useState()

  const getSchedule = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/appointment`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      setSchedule(data)
    } catch (err) {
      console.log(err)
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      }
    }
  }, [setUserAuth, userAuth])

  useEffect(() => {
    setActiveMenu('/citas-vacunacion')
    getSchedule()
  }, [getSchedule, setActiveMenu])

  const getStatusAppointment = useCallback((status) => {
 
    if (status === 'vaccinate') {
      return { color: '#febb2f', label: 'Vacunar' }
    }
    if (status === 'expired') {
      return { color: '#dc3545', label:  'Cita expirada'  }
    }
    
    if (status === 'cancelled') {
      return { color: 'rgb(136, 147, 136)', label: 'Cita cancelada' }
    }

    return { color: '#4caf50', label: 'Vacunado' }
  }, [])

  const getUbigeo = useCallback(
    (code) => {
      const location = ubigeo.ubigeos?.find(
        (location) => location.code === code,
      )
      if (!location) return ''
      return `${location.department}, ${location.province}, ${location.district}`
    },
    [ubigeo],
  )

  const getDateStart = useCallback((date) => {
    let date1 = new Date(date)
    let date2 = new Date()
    let diff = date1.getTime() - date2.getTime()
    return Math.round(diff / (1000 * 60 * 60 * 24))
  }, [])

  if (!schedule) {
    return (
      <Box my={4} style={{ textAlign: 'center' }}>
        <CircularProgress size={24} color="primary" />
      </Box>
    )
  }

  if (!schedule.data || schedule.data?.length === 0) {
    return (
      <Box my={4}>
        <Typography variant="h5" align="center">
          Aún no tienes citas
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {schedule.data.map((appointment, index) => {
          const status = getStatusAppointment(appointment.status)
          const dateStart = getDateStart(appointment.date)
          return (
            <Grid item key={index} xs={12} md={6} lg={3}>
              <Box className={classes.appointment}>
                <Box
                  className={classes.appointmentTitle}
                  style={{ backgroundColor: status.color }}>
                  <Typography variant="h6">Vacuna COVID-19</Typography>
                  <Grid container alignContent="center" justify="center">
                    <Box mr={1}>
                      <Icon fontSize="small">schedule</Icon>
                    </Box>
                    <Typography variant="body2">
                      {dayjs(appointment.date).format('DD/MM/YYYY hh:mm a')}
                    </Typography>
                  </Grid>
                </Box>
                <Box className={classes.appointmentContent}>
                  <Typography>
                    <b>Estado:</b> {status.label}
                  </Typography>
                  <Typography>
                    <b>Dosis:</b> {appointment.dose}
                  </Typography>
                  <Typography>
                    <b>Centro de Vacunación:</b> {appointment.local_name}
                  </Typography>
                  <Typography>
                    <b>Dirección:</b> {appointment.local_address} -{' '}
                    {getUbigeo(appointment.local_ubigeo)}
                  </Typography>
                  {appointment.status !== 'vaccinate' ? (
                    <Box mt={2} style={{ textAlign: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push({
                            pathname: `/citas-vacunacion/${appointment.created_at}`,
                          })
                        }}>
                        Ver
                      </Button>
                    </Box>
                  ) : null}
                  {appointment.status === 'vaccinate' && dateStart <= 3 ? (
                    <Box mt={2} style={{ textAlign: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push({
                            pathname: `/citas-vacunacion/${appointment.created_at}`,
                          })
                        }}>
                        Ver
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      mt={2}
                      style={{ textAlign: 'left', color: '#febb2f' }}
                      hidden={
                        appointment.status !== 'vaccinate' ? true : false
                      }>
                      <Grid container>
                        <Box>
                          <Icon
                            fontSize="small"
                            style={{ float: 'left', marginRight: '6px' }}>
                            error
                          </Icon>
                          <Typography style={{ fontSize: '14px' }}>
                            <b>
                              Esta cita se habilitará 3 días antes de la fecha
                              indicada.
                            </b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default Schedule
