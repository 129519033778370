import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useRef, useState } from 'react'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import { Document, Page } from 'react-pdf'
import { useHistory, Redirect } from 'react-router-dom'
import 'react-html5-camera-photo/build/css/index.css'
import { toast } from 'react-toastify'


import Box from '../../../../components/box'
import Context from '../../../../Context'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  options: {
    textAlign: 'center',
  },
  '#display-error': {
    marginTop: '100px',
  },
}))

const FileButton = withStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    height: '120px',
    width: '140px',
  },
}))(Button)

function dataURLtoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

const TestCovid = () => {
  const classes = useStyles()
  const { dashboardSession, setDashboardSession } = useContext(Context)
  const history = useHistory()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [dataUri, setDataUri] = useState('')
  const [typeFile, setTypeFile] = useState('')
  const [openPhotography, setOpenPhotography] = useState(false)
  const fileData = useRef()
  const [numPages, setNumPages] = useState(null)
  const [disabled, setDisabled] = useState(true)

  const handleCapture = (event) => {
    const fileTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']
    const { files } = event.target
    if (files && files.length !== 0) {
      const file = files[0]
      const fileSize = file.size / 1024 / 1024

      if (fileTypes.find(type => type === file.type) && fileSize <= 2) {
        setDisabled(false)
        console.log(file)
        fileData.current = file
        const url = URL.createObjectURL(file)
        setDataUri(url)
        files[0].type === 'application/pdf'
          ? setTypeFile('pdf')
          : setTypeFile('jpg')
      } else {

        toast(
          'Archivo no válido, Los formatos aceptados son: jpeg, jpg, png y pdf  (de hasta 2 MB).',
          {
            type: 'warning',
          },
        )
      }

    }
  }

  const handleTakePhoto = (dataUri) => {
    if (!!dataUri) {
      const blob = dataURLtoBlob(dataUri)
      fileData.current = blob
    }
    document.getElementById('evidence').value = ''
    setOpenPhotography(false)
    setDataUri(dataUri)
    dataUri ? setDisabled(false) : setDisabled(true)
  }

  const handleOpenPhotography = () => {
    setOpenPhotography(true)
  }

  const handleClosePhotography = () => {
    setOpenPhotography(false)
  }

  function handleCameraError(error) {
    console.log('handleCameraError', error)
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      const pathname = '/afiliacion/encuesta/auto-triaje'
      const session = {
        ...dashboardSession,
        quiz_testcovid: { file: fileData.current },
        lastPathname: pathname,
      }
      setDashboardSession(session)
      history.push({ pathname })
    } catch (err) {
      console.log(err)
    }
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const statement = dashboardSession?.quiz_statement
  if (
    !statement ||
    statement.qcovid_3 !== 'yes' ||
    statement.qcovid_4 !== 'yes'
  ) {
    return <Redirect to="/afiliacion/encuesta/declaracion-jurada" />
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Sube la foto de tu prueba COVID-19
        </Typography>
        <Typography variant="body1" component="h2">
          Gracias por participar compartiendo información relevante con nosotros.
          Esta encuesta es confidencial y tiene el valor de una Declaración Jurada.
        </Typography>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container justify="center">
              <FileButton variant="contained" component="label">
                <Icon fontSize="large">image</Icon>
                <span style={{ textAlign: 'center' }}>
                  Subir archivo
                  <input
                    id="evidence"
                    accept="image/png,image/jpeg,application/pdf"
                    type="file"
                    onChange={handleCapture}
                    hidden
                  />
                </span>
              </FileButton>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="center">
              <FileButton
                variant="contained"
                component="label"
                onClick={handleOpenPhotography}>
                <Icon fontSize="large">camera_alt</Icon>
                <span style={{ textAlign: 'center' }}>Tomar foto</span>
              </FileButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center">
              <Typography style={{ fontSize: '13px' }}>
                Los formatos aceptados son: jpeg, jpg, png y pdf  (de hasta 2 MB)
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.options}>
            {dataUri ? (
              <ButtonGroup size="small">
                <Button onClick={handleOpenPhotography}>Ver archivo</Button>
                <Button
                  onClick={() => {
                    handleTakePhoto('')
                  }}>
                  Eliminar
                </Button>
              </ButtonGroup>
            ) : null}
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        type="submit"
        disabled={disabled}
        endIcon={
          isSubmitted ? <CircularProgress size={24} color="primary" /> : null
        }
        onClick={handleSubmit}>
        Continuar
      </Button>
      <Box mt={2}>
        <Button
          variant="text"
          color="inherit"
          fullWidth
          disabled={isSubmitted}
          onClick={(event) => {
            event.preventDefault()
            history.push('/afiliacion/encuesta/declaracion-jurada')
          }}>
          Regresar
        </Button>
      </Box>
      <Dialog
        fullScreen={!dataUri}
        open={openPhotography}
        onClose={handleClosePhotography}>
        <AppBar style={{ position: dataUri ? 'relative' : 'fixed' }}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleClosePhotography}>
              <Icon>close</Icon>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Prueba COVID-19
            </Typography>
            {/*dataUri ? (
              <IconButton
                color="inherit"
                onClick={() => {
                  handleTakePhoto('')
                }}>
                <Icon>delete</Icon>
              </IconButton>
              ) : null*/}
          </Toolbar>
        </AppBar>
        <div>
          {dataUri ? (
            typeFile === 'pdf' ? (
              <Document
                file={dataUri}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (_, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            ) : (
              <img
                src={dataUri}
                style={{ display: 'block', maxWidth: '100%' }}
              />
            )
          ) : (
            <Camera
              onTakePhoto={handleTakePhoto}
              onCameraError={(error) => {
                handleCameraError(error)
              }}
              isFullscreen
              isImageMirror={false}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
            />
          )}
        </div>
      </Dialog>
    </Box>
  )
}

export default TestCovid
