import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form } from '@unform/web'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import React, { useContext, useRef, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import PinInput from 'react-pin-input'

import { useAttributesStyles } from './styles'
import Box from '../../components/box'
import { useFormErrors, TextField } from '../../components/input'
import useCountDown from '../../hooks/useCountDown'
import * as Schema from '../../utils/validation'
import Context from '../../Context'

const LinkButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button)

const ValidatePhoneSchema = Schema.object().shape({
  token: Schema.string()
    .length(6)
    .matches(/^[0-9]*$/, 'Solo se permiten números')
    .required(),
})

export const ValidatePhone = () => {
  const classes = useAttributesStyles()
  const { userSession, setUserSession, setTimeoutSession } = useContext(Context)
  const history = useHistory()

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: ValidatePhoneSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const {
    isComplete,
    remainingTime,
    resetCounter,
    startCounter,
  } = useCountDown({
    initialTimeSeconds: Number(process.env.REACT_APP_CONFIG_FORM_RESEND_TIME),
    autoplay: true,
  })
  var ele = []

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await ValidatePhoneSchema.validate(data)
      const headers = { Authorization: `Bearer ${userSession.token}` }
      const {
        data: response,
      } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/phone-verify`,
        data,
        { headers },
      )
      if (!response.success) {
        throw new Error( response.error==undefined ? 
          'Lo sentimos, problemas al enviar el codigo intentelo nuevamente.':response.error.message,
        )
      } else {
        const decoded = jwt_decode(response.token)
        const timeout = (decoded.exp - decoded.iat) * 1000
        const session = {
          ...userSession,
          token: response.token,
        }
        setTimeoutSession(timeout, 'register')
        setUserSession(session)
      }
      history.push({ pathname: '/user/attributes/register-email' })
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserSession(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.error('err', err)
        toast(err.message, {
          type: 'error',
        })
      }
      setIsSubmitted(false)
    }
  }

  const handleResend = async () => {
    try {
      document.getElementById('token').value = ''
      ele.clear()
      resetCounter()
      startCounter()
      const headers = { Authorization: `Bearer ${userSession.token}` }
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/phone-forward`,
        { phone: userSession.profile.phone.phone },
        { headers },
      )
      if (!response.success) {
        throw new Error(
          'Lo sentimos, no pudimos reenviarte el código de verificación.',
        )
      }
    } catch (err) {
      console.error('err', err)
      if (err?.response?.status === 401) {
        setUserSession(null, 'expired')
      } else {
        toast(err.message, {
          type: 'error',
        })
      }
    }
  }

  const handleOutput = (value) => {
    document.getElementById('token').value = value
    setFieldError('token', value)
  }

  if (!userSession?.profile?.phone?.phone) {
    return <Redirect to="/user/attributes/contact" />
  }

  return (
    <Box className={classes.container}>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Valida tu número
        </Typography>
        <Typography variant="body1" component="h2">
          Ingresa el código de validación que te hemos enviado a tu número de
          celular ******{userSession.profile.phone.phone.slice(-3)}
        </Typography>
      </Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <TextField
            name="phone"
            defaultValue={userSession.profile.phone.phone}
            hidden
            error={!!errors.token}
            helperText={errors.token}
          />
          <Grid item xs={12}>
            <TextField
              id="token"
              name="token"
              onChange={(event) => {
                setFieldError('token', event.target.value)
              }}
              hidden
            />
            <PinInput
              focus={true}
              length={6}
              type="numeric"
              ref={(n) => (ele = n)}
              onChange={handleOutput}
            />
            {errors.token ? <p className="error-pin">{errors.token}</p> : ''}
            <Box mt={1} style={{ textAlign: 'right' }}>
              <LinkButton
                size="small"
                variant="text"
                color="inherit"
                disabled={!isComplete}
                onClick={(event) => {
                  event.preventDefault()
                  handleResend()
                }}>
                {isComplete
                  ? 'Reenviar código'
                  : `Reenviar código de validación en ${remainingTime} segundos`}
              </LinkButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitted}
              endIcon={
                isSubmitted ? (
                  <CircularProgress size={24} color="primary" />
                ) : null
              }>
              Validar
            </Button>
            <Box mt={2}>
              <Button
                variant="text"
                color="inherit"
                fullWidth
                disabled={isSubmitted}
                onClick={(event) => {
                  event.preventDefault()
                  history.push('/user/attributes/contact')
                }}>
                Regresar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default ValidatePhone
