import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { Form } from '@unform/web'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import QRCode from 'react-qr-code'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import dayjs from 'dayjs'

import * as Schema from '../../../utils/validation'
import Box from '../../../components/box'
import Context from '../../../Context'
import ModalReschedule from '../appointment/Reschedule'
import { useFormErrors, TextField } from '../../../components/input'
import { flexbox } from '@material-ui/system'

const LocalizedFormat = require('dayjs/plugin/localizedFormat')
const localeData = require('dayjs/plugin/localeData')

dayjs.extend(LocalizedFormat)
dayjs.extend(localeData)

const useStyles = makeStyles((theme) => ({
  code: {
    backgroundColor: '#000',
    width: 150,
    height: 150,
    margin: theme.spacing(0.5, 'auto', 1),
  },
  button: {
    margin: theme.spacing(1, 'auto'),
    textAlign: 'center',
    width: 150,
  },
  container: {
    borderTopStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    borderTopColor: theme.palette.primary.main,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 8%)',
    padding: theme.spacing(1.5, 2),
    backgroundColor: '#FFF',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderTopWidth: 4,
      padding: theme.spacing(3, 4),
    },
    '& .MuiTypography-body1': {
      paddingLeft: '10px',
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const sendCodeEmailSchema = Schema.object().shape({
  email: Schema.string().email().required(),
})

const Appointment = () => {
  const classes = useStyles()
  const { ubigeo, userAuth, setUserAuth, setActiveMenu } = useContext(Context)
  const history = useHistory()
  const { date } = useParams()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [valueEmail, setValueEmail] = useState('')

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: sendCodeEmailSchema,
    ref: formRef,
  })

  const [modal, setModal] = useState({})

  const closeModal = useCallback(() => {
    setModal({})
  }, [])

  const [appointment, setAppointment] = useState()

  const getAppointment = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/appointment/one?date=${date}`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      if (!data.status && !data.success) {
        throw new Error('Appointment not found')
      }
      setAppointment(data.data)
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
      } else {
        setAppointment('error')
      }
    }
  }, [date, setUserAuth, userAuth])

  useEffect(() => {
    setActiveMenu('/citas-vacunacion')
    getAppointment()
  }, [getAppointment, setActiveMenu])

  const getUbigeo = useCallback(
    (code) => {
      const location = ubigeo.ubigeos?.find(
        (location) => location.code === code,
      )
      if (!location) return ''
      return `${location.department}, ${location.province}, ${location.district}`
    },
    [ubigeo],
  )

  const getDate = useCallback((date) => {
    const [dateFormat] = new Date(date).toLocaleString().split(' ')
    return dateFormat
  }, [])

  const handleBack = (event) => {
    event.preventDefault()
    history.push({ pathname: '/citas-vacunacion' })
  }

  const handleSendCode = async (data) => {
    try {
      setIsSubmitted(true)
      await sendCodeEmailSchema.validate(data)
      const body = {
        email: data.email,
        date: date,
      }
      const headers = { Authorization: `Bearer ${userAuth.token}` }
      const {
        data: response,
      } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/appointment/forward`,
        body,
        { headers },
      )
      setValueEmail('')
      if (response.success) {
        toast(
          'Hemos enviado el código de vacunación a tu correo electrónico.',
          {
            type: 'success',
          },
        )
      } else {
        toast(
          'Problemas al enviar su solcitud, por favor intentelo nuevamente.',
          {
            type: 'warning',
          },
        )
      }
      setIsSubmitted(false)
    } catch (err) {
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        console.log('err', err)
        if (err?.response?.status === 401) {
          setUserAuth(null, 'expired')
        } else {
          toast(
            'Problemas al enviar su solcitud, por favor intentelo nuevamente.',
            {
              type: 'error',
            },
          )
        }
      }
      setIsSubmitted(false)
    }
  }

  const successModal = useCallback(() => {
    setModal({})
    appointment
  }, [appointment])

  const getModalContent = useCallback(() => {
    return (
      <ModalReschedule
        data={appointment}
        onSuccess={successModal}
        onClose={closeModal}
      />
    )
  }, [closeModal, appointment, successModal])

  const handleChangeInput = (event) => {
    const { value } = event.target
    setValueEmail(value)
  }

  if (!appointment) {
    return (
      <Box my={4} style={{ textAlign: 'center' }}>
        <CircularProgress size={24} color="primary" />
      </Box>
    )
  }
  if (appointment === 'error') {
    return (
      <Box my={4}>
        <Typography variant="body1" align="center">
          Cita no encontrada
        </Typography>
      </Box>
    )
  }
  // console.log(appointment);
  return (
    <Container maxWidth="sm">
      <Box className={classes.container}
        mt={4}
        px={6}
        py={4}>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justify="center"
          spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3" align="center">
              Cita de Vacunación
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" >
              {userAuth?.name},
              {appointment.status === 'vaccinated'
                ? ' felicidades has recibido la vacuna y ya te encuentras protegido(a).'
                : (appointment.status === 'cancelled' ? ' tu cita ha sido cancelada, por favor, solicita una nueva.' :

                  ' debes mostrar la siguiente información al personal de salud del centro de vacunación designado para que puedan atenderte.')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      <b>Centro de Vacunación:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {appointment.local_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      <b>Dirección:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {appointment.local_address} -{' '}
                      {getUbigeo(appointment.local_ubigeo)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      <b>Fecha y hora:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {dayjs(appointment.date).format('DD/MM/YYYY hh:mm a')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      <b>Dosis:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">{appointment.dose}</Typography>
                  </Grid>
                </Grid>
              </Box>
              {appointment.status === 'vaccinated' ?
                <Box mt={2}>
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography variant="body1">
                        <b>Fabricante:</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">{appointment.vaccine_lot_fabricator}</Typography>
                    </Grid>
                  </Grid>
                </Box> : null}
              {appointment.status !== 'cancelled' && appointment.status !== 'vaccinated' ?
                (<Box mt={2}>
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <b>Importante:</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>) : null}

              {appointment.status !== 'cancelled' && appointment.status !== 'vaccinated' ?
                (<Box mt={0}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} mb={3} mt={4} px={3} pt={3} pb={3} style={{ background: '#e9ff204d', borderRadius: '10px', }}>
                      <Typography variant="body1">
                        <ul>
                          <li>Recuerde acudir a tu cita con anticipación.</li>
                          <li>Acude sólo con un acompañante, si fuese necesario.</li>
                          <li>Si faltas a tu cita, podrás pedir la reprogramación al día siguiente a través de la plataforma. La nueva cita estará sujeta a la disponibilidad de dósis.</li>
                        </ul>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>) : null}

            </Box>
          </Grid>

          {appointment.status !== 'cancelled' && appointment.status !== 'vaccinated' ?
            (<Grid item sm={12} xs={12}>
              <Typography variant="body1" align="center">
                <b>
                  {appointment.status === 'vaccinated'
                    ? 'Carnet de Vacunación'
                    : 'Código de Vacunación'}
                </b>
              </Typography>
              <Box className={classes.code}>
                <QRCode value={appointment.qr} size="150" />
              </Box>
              <Typography variant="body1" align="center">
                {appointment.status === 'vaccinated'
                  ? 'Puedes enviar tu código de carnet de vacunación a tu correo electrónico:'
                  : 'Debes mostrar la imagen (Código de Vacunación) al personal de salud del local designado para que puedan atenderte.'}
              </Typography>
            </Grid>) : null}

          {appointment.status !== 'cancelled' && appointment.status !== 'vaccinated' ?
            (
              <Grid item lg={12} className="oculto-impresion">
                <Box mb={3} mt={3}>
                  <Form ref={formRef} onSubmit={handleSendCode} autoComplete="off">
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={3}>
                      <Grid item sm={7} xs={12}>
                        <TextField
                          inputProps={{
                            maxLength: 50,
                          }}
                          name="email"
                          label="Correo electrónico"
                          fullWidth
                          value={valueEmail}
                          error={!!errors.email}
                          helperText={errors.email}
                          onChange={(event) => {
                            handleChangeInput(event)
                            setFieldError('email', event.target.value)
                          }}
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          type="submit"
                          disabled={isSubmitted}
                          endIcon={
                            isSubmitted ? (
                              <CircularProgress size={24} color="primary" />
                            ) : null
                          }>
                          ENVIAR CÓDIGO
                    </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </Box>
              </Grid>
            ) : null}

          <Grid item xs={12} lg={12}>
            {appointment.status === 'expired' ? (
              <Box className={classes.button}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setModal({ name: 'reprogramar' })
                  }}>
                  Reprogramar
                </Button>
              </Box>
            ) : null}
            {/* className={classes.button} */}
            <Grid container spacing={3} className="oculto-impresion">

              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button variant="contained" onClick={handleBack}>
                  Volver
              </Button>
              </Grid>
              <Grid item xs={6} >
                <Button variant="contained" onClick={() => window.print()}>
                  Imprimir
              </Button>
              </Grid>
            </Grid>
            {appointment.status === 'cancelled' ?
              <Box mb={3} mt={2} px={3} pt={2} pb={2} style={{ background: '#e9ff204d', borderRadius: '10px', textAlign: 'center' }}>
                <b>CITA CANCELADA.</b>
              </Box> : null}
            {appointment.status === 'expired' ?
              <Box mb={3} mt={2} px={3} pt={2} pb={2} style={{ background: '#e9ff204d', borderRadius: '10px', textAlign: 'center' }}>
                <b>CITA EXPIRADA.</b>
              </Box> : null}


          </Grid>
        </Grid>
      </Box>
      <Dialog open={!!modal.name} onClose={closeModal} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6">Reprogramar Cita</Typography>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            onClick={closeModal}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{getModalContent()}</DialogContent>
      </Dialog>
    </Container>
  )
}

export default Appointment
