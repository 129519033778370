import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { Form } from '@unform/web'
import axios from 'axios'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'

import Box from '../../../components/box'
import { useFormErrors, TextField } from '../../../components/input'
import * as Schema from '../../../utils/validation'
import Context from '../../../Context'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 2, 1),
  },
}))

const PhoneSchema = Schema.object().shape({
  cityCode: Schema.string().matches(/^[0-9]*$/, 'Solo se permiten números'),
  telephone: Schema.string()
    .min(6, 'Debe contener 6 ó 7 números')
    .max(7, 'Debe contener 6 ó 7 números')
    .matches(/^[0-9]*$/, 'Solo se permiten números'),
  phone: Schema.string()
    .length(9)
    .matches(
      /^(\9)+[0-9]{0,8}$/,
      'Solo se permiten números e inicie con el número 9',
    )
    .required(),
})

export const Phone = (props) => {
  const classes = useStyles()
  const { setUserAuth, ubigeo, userAuth } = useContext(Context)
  const history = useHistory()

  const codes = useMemo(() => {
    return ubigeo.phone_codes.sort((current, next) =>
      current.name.localeCompare(next.name),
    )
  }, [ubigeo])

  const [phoneCode, setPhoneCode] = useState(
    userAuth?.city_code ? userAuth.city_code : '',
  )

  const handlePhoneCodeChange = (event) => {
    const value = event.target.value
    setPhoneCode(value)
  }

  const formRef = useRef()
  const { errors, setErrors, setFieldError } = useFormErrors({
    schema: PhoneSchema,
    ref: formRef,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleFormSubmit = async (data) => {
    try {
      setIsSubmitted(true)
      await PhoneSchema.validate(data)
      if ((data.cityCode == null || data.cityCode == '') && data.telephone !== null && data.telephone.trim().length !== 0) {

        toast('Seleccionar código de ciudad.', {
          type: 'warning',
        });

        setIsSubmitted(false)
        return false;
      }

      /*if (data.cityCode !== null && data.cityCode !== '' && (data.telephone === null || data.telephone.trim().length === 0)) {

        toast('Ingresar número telefónico válido.', {
          type: 'warning',
        });

        setIsSubmitted(false)
        return false;
      }*/

      var isEdit = false;
      var tel = data.telephone === null || data.telephone.trim().length === 0 ? undefined:data.telephone;
      var authTel =  userAuth.telephone === undefined ? undefined: userAuth.telephone.trim().length === 0? undefined : userAuth.telephone;
      if (userAuth.phone !== data.phone) {
        var pathService = '/profile/phone'
        var body = {
          cityCode: data.cityCode,
          telephone: data.telephone,
          phone: data.phone,
        }
        isEdit = true;
      } else if (authTel !== tel || (data.cityCode !==userAuth.cityCode && tel !== undefined)) {
        var pathService = '/profile/telephone'
        var body = {
          cityCode: data.cityCode,
          telephone: data.telephone,
        }
        isEdit = true;
      }
      if (isEdit) {
        const headers = { Authorization: `Bearer ${userAuth.token}` }
        const { data: response } = await axios.post(
          `${process.env.REACT_APP_BASE_URL_CITIZEN}${pathService}`,
          body,
          {
            headers,
          },
        )

        if (pathService === '/profile/phone') {
          if (!response.success) {
            throw new Error(response.error?.message)
          }
          props.onSuccess(data)
        } else {
          if (!response) {
            throw new Error('Lo sentimos pero no pudimos actualizar su teléfono')
          }
          props.onSuccess(data)
        }
      }else{
        props.onClose()
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setUserAuth(null, 'expired')
        return
      }
      if (err instanceof Schema.ValidationError) {
        setErrors(err.inner)
      } else {
        toast(err.message, {
          type: 'error',
        })
      }
      setIsSubmitted(false)
    }
  }

  const [valuePhone, setValuePhone] = useState('')
  const [valueTelephone, setValueTelephone] = useState('')

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    if (name === 'phone') {
      value.match(/^(^$)|[0-9]+$/) ? setValuePhone(value) : null
    }
    if (name === 'telephone') {
      value.match(/^(^$)|[0-9]+$/) ? setValueTelephone(value) : null
    }
  }

  useEffect(() => {
    setValuePhone(userAuth?.phone || '')
    setValueTelephone(userAuth.telephone || '')
  }, [setValuePhone, setValueTelephone, userAuth])

  return (
    <Box>
      <Form ref={formRef} onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="cityCode"
              label="Código"
              select
              fullWidth
              error={!!errors.cityCode}
              helperText={errors.cityCode}
              value={phoneCode}
              defaultValue={userAuth.city_code || ''}
              onChange={handlePhoneCodeChange}>
              {codes.map(({ code, name }) => (
                <MenuItem
                  key={code}
                  value={code}
                  style={{
                    display: 'block',
                    padding: '6px 16px 6px 16px',
                  }}>
                  {`${code} - ${name}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              inputProps={{
                maxLength: 7,
              }}
              name="telephone"
              label="Teléfono fijo"
              fullWidth
              error={!!errors.telephone}
              helperText={errors.telephone}
              defaultValue={userAuth.telephone || ''}
              value={valueTelephone}
              onChange={(event) => {
                handleChangeInput(event)
                setFieldError('telephone', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputProps={{
                maxLength: 9,
              }}
              name="phone"
              label="Número de celular"
              fullWidth
              error={!!errors.phone}
              defaultValue={userAuth.phone || ''}
              value={valuePhone}
              helperText={errors.phone}
              onChange={(event) => {
                handleChangeInput(event)
                setFieldError('phone', event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Button
                variant="contained"
                onClick={(event) => {
                  event.preventDefault()
                  props.onClose?.()
                }}
                className={classes.button}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitted}
                endIcon={
                  isSubmitted ? (
                    <CircularProgress size={24} color="primary" />
                  ) : null
                }
                className={classes.button}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  )
}

export default Phone
