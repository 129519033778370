import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import clsx from 'clsx'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import Box from '../../components/box'
import DashboardRoutes from './routes'
// import Logotipo from '../../assets/img/logo_blanco.png'
import Logotipo from '../../assets/img/dashboard/Vacuna-para-todos-blanco.png'
import LogotipoColor from '../../assets/img/logo.png'
import { modalInstallRef } from '../../utils/refs'
import Context from '../../Context'
import ImageMembership from '../../assets/img/dashboard/img_modal_membership.png'
import jwt_decode from 'jwt-decode'
const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerInner: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerPaper: {
    color: '#FFFFFF',
    width: drawerWidth,
    backgroundColor: theme.palette.secondary.main,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    '& h2.title-head': {
      color: '#707070',
    },
  },
  listitem: {
    marginBottom: theme.spacing(1),
    '&.Mui-selected': {
      backgroundColor: '#f5f5f526',
      fontWeight: 600,
      borderLeft: '3px solid #ffffff',
    },
  },
  listicon: {
    minWidth: 'auto',
    width: 40,
    color: 'inherit',
  },
  modalContainer: {
    textAlign: 'center',
  },
}))

const Container = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const [open, setOpen] = useState(true)
  const [openMobile, setOpenMobile] = useState(false)

  const handleDrawer = () => {
    if (matches) {
      setOpen((prevOpen) => !prevOpen)
    } else {
      setOpenMobile((prevOpen) => !prevOpen)
    }
  }

  const {
    activeMenu,
    setActiveMenu,
    userAuth,
    setUserAuth,
    dashboardConfig,
    setDashboardConfig,
    setDashboardSession,
  } = useContext(Context)

  const [modalMembership, setModalMembership] = useState(userAuth?.modal_membership)
  const [modalInstall, setModalInstall] = useState(false)
  const [count, setCount] = useState(0)
  const [isInstalled, setIsInstalled] = useState(false)
  const [userName, setUserName] = useState('');



  const getDashboardConfig = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ADMIN}/cache-dashboard`,
      )
      const { data } = response.data
      setDashboardConfig(data)
      localStorage.setItem('dashboard_config', JSON.stringify(data))
    } catch (err) {
      console.error(err)
    }
  }, [setDashboardConfig])

  const findSurvey = useCallback(async () => {
    try {

      const decoded = jwt_decode(userAuth.token)
      setUserName(decoded.name)

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_CITIZEN}/find-survey`,
        {
          headers: {
            Authorization: `Bearer ${userAuth.token}`,
          },
        },
      )
      if (data.status === 'pending') {
        setModalMembership(false)
      } else {
        // setModalInstall(!!modalInstallRef.current)
        // setModalInstall(!isInstalled)
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err)
        //setUserAuth(null, 'expired')
      }
    }
  }, [userAuth, setUserName])


  const appInstalled = useCallback(async () => {

    const fun = navigator['getInstalledRelatedApps'];
    const installedApps = await fun.call(navigator);

    // const installedApps = await navigator.getInstalledRelatedApps()
    const nativeApp = installedApps.find(app => app.id === 'vacuna.paratodos')


    // console.log(installedApps)
    // console.log(nativeApp)
    if (nativeApp) {
      setIsInstalled(true)
    }
  }, [setIsInstalled])

  useEffect(() => {
    if (!dashboardConfig) {

      getDashboardConfig()
    }
  }, [dashboardConfig, getDashboardConfig])

  useEffect(() => {
    appInstalled()
    setActiveMenu(activeMenu)
  }, [setActiveMenu, activeMenu, appInstalled])

  useEffect(() => {
    if (modalMembership !== false) {
      findSurvey()
    }
  }, [findSurvey, modalMembership, isInstalled])

  useEffect(() => {
    if (location.state?.isSuccess) {
      setModalInstall(!isInstalled)
    }
  }, [setIsInstalled, isInstalled, location])

  const handleLink = useCallback(
    (pathname) => {
      setCount(count + 1)
      if (count === 0) {
        setModalInstall(!!modalInstallRef.current)
      }
      setActiveMenu(pathname)
      setOpenMobile(false)
      history.push({ pathname })
    },
    [count, history, setActiveMenu, setCount],
  )


  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);


  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      // alert("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler, false);

    return () => window.removeEventListener("transitionend", handler);
  }, []);




  const handleInstall = useCallback(async () => {
    setModalInstall(false)
    modalInstallRef.current?.prompt()
    // const { outcome } = await modalInstallRef.current.userChoice
    // modalInstallRef.current = null

  }, [])


  const handleLogout = useCallback(async () => {
    const { data: response } = await axios.get(
      `${process.env.REACT_APP_BASE_URL_CITIZEN}/logout`,
      {
        headers: {
          Authorization: `Bearer ${userAuth.token}`,
        },
      },
    )
    setAnchorEl(null)
    setUserAuth(null)
    setDashboardSession(null)
    setActiveMenu('/')
    // history.push({ pathname: '/' })
  }, [setDashboardSession, setUserAuth, setActiveMenu])

  const handleRedirect = () => {
    window.open(
      `tel:${process.env.REACT_APP_NUMBER_CALLPHONE}`,
      '_blank',
    )
  }

  const handleCloseModalMembership = () => {
    setCount(1)
    setModalMembership(false)
    userAuth.modal_membership = false
    setModalInstall(!!modalInstallRef.current)
    setUserAuth(userAuth)
  }

  const drawer = useMemo(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true)
    }
    return (
      <div className={classes.drawerInner}>
        <div className={classes.drawerHeader}>
          <img src={Logotipo} alt="Vacuna para todos" width={140} />
        </div>
        <List style={{ flex: '1 1' }}>
          {DashboardRoutes.filter(({ submenu = true }) => submenu).map(
            (route) => (
              <ListItem
                button
                className={classes.listitem}
                key={route.path}
                selected={activeMenu === route.path ? true : false}
                onClick={() => {
                  handleLink(route.path)
                }}>
                <ListItemIcon className={classes.listicon}>
                  <Icon>{route.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItem>
            ),
          )}

          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              handleRedirect()
            }}>
            <ListItemIcon className={classes.listicon}>
              <Icon>phone_android</Icon>
            </ListItemIcon>
            <ListItemText primary="Contáctanos" />
          </ListItem>
        </List>
        {
          isMobile && isInstalled === false ?
            <ListItem button className={classes.listitem} onClick={handleInstall}>
              <ListItemIcon className={classes.listicon}>
                <Icon>get_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Instalar App" />
            </ListItem>
            : null
        }
        <ListItem button className={classes.listitem} onClick={handleLogout}>
          <ListItemIcon className={classes.listicon}>
            <Icon>logout</Icon>
          </ListItemIcon>
          <ListItemText primary="Salir" />
        </ListItem>
      </div>
    )
  }, [activeMenu, classes, isInstalled, handleLink, handleLogout, handleInstall])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const openProfileMenu = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMembership = () => {
    setModalMembership(false)
    userAuth.modal_membership = false
    setUserAuth(userAuth)
    history.push({ pathname: '/afiliacion' })
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton)}>
            <Icon>menu</Icon>
          </IconButton>
          <div style={{ flex: 1 }} />
          <div>
            {userName}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit">
              <Icon>account_circle</Icon>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openProfileMenu}
              onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  history.push({ pathname: '/perfil' })
                  handleClose()
                }}>

                <ListItemIcon className={classes.listicon}>
                  <Icon fontSize="small">face</Icon>
                </ListItemIcon>
                <ListItemText primary="Perfil" />
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon className={classes.listicon}>
                  <Icon fontSize="small">logout</Icon>
                </ListItemIcon>
                <ListItemText primary="Salir" />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={openMobile}
            onClose={handleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
      {isMobile ? (
        // modalInstall
        <Dialog open={modalInstall} onClose={() => { }} fullWidth maxWidth="sm">
          <DialogContent>
            <Box p={1} className={classes.modalContainer}>
              <Box>
                <img src={LogotipoColor} alt="Vacuna para todos" width={230} />
              </Box>
              <Typography variant="h5" gutterBottom>
                <b>Instala la app</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Instala la aplicación Vacuna Para Todos en tu celular y accede
                con solo un clic
              </Typography>
              <Box my={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleInstall}>
                  Instalar
                </Button>
              </Box>
              <Button
                variant="text"
                fullWidth
                onClick={() => {
                  setModalInstall(false)
                }}>
                Tal vez después
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      ) : null}

      <Dialog open={modalMembership} onClose={handleCloseModalMembership} fullWidth maxWidth="xs">
        <DialogContent style={{ padding: '5px' }}>
          <img src={ImageMembership} alt="Vacuna para todos" style={{ width: '100%' }} />
          <Box p={1} className={classes.modalContainer}>
            <Box my={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleMembership}>
                INGRESA AQUÍ
              </Button>
            </Box>
            {/*<Button
              variant="text"
              fullWidth
              onClick={() => {
                setModalInstall(false)
              }}>
              Tal vez después
            </Button>*/}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Container
